<script>
import GripoDialog from '@components/common/Dialog'
import ClientName from '@components/common/ClientName'
import ClientComponent from '@components/common/ClientComponent'
import ScheduleInviteStatus from '../labels/InviteStatus'
import ScheduleNotify from '../ScheduleNotify'
import ScheduleShowType from './ScheduleType'
import SchedulePayment from '../SchedulePayment'

import {defaultSchedule, releaseScheduleFixed, saveSchedule} from '../../service'

import * as restfulService from '../../../../../services/restfulService'
import * as notifyService from '../../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {currencyConfig} from '@utils/money'

import {cloneDeep, debounce} from 'lodash'
import {required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
import {confirmDialog} from '@utils/flash'

export default {
  name: 'schedule-show',
  components: {
    ScheduleShowType,
    GripoDialog,
    ClientName,
    ClientComponent,
    ScheduleInviteStatus,
    ScheduleNotify,
    SchedulePayment
  },
  mixins: [helpers],
  props: {
    scheduleProp: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    },
    courts: {
      type: Array
    },
    selectionList: {
      type: Array
    },
    dependencies: {
      type: Object
    },
    isCoach: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoadingData: false,
      isSubmitted: false,
      isScheduleModalVisible: false,
      schedule: cloneDeep(defaultSchedule),
      groupSelected: '',
      playerSelected: '',
      isNotifyUserModalVisible: false,
      closeScheduleModal: false,
      showQueueOptions: false,
      schedulePrice: {
        edit: false,
        price: 0
      },
      notifyUser: {
        type: 'save',
        players: [],
        value: false,
        data: {}
      },
      scheduleQueue: {
        client_id: '',
        fixed_court: true,
        fixed_date: true
      },
      editCoachEnabled: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'tenant']),
    moneyMask() {
      return currencyConfig()
    },
    scheduleLessonType() {
      return ['lesson', 'lesson-fixed'].includes(this.schedule.type)
    },
    fixedConfirmLabel() {
      if (this.schedule.coach_id) {
        return 'Confirmar Aula Fixa'
      } else {
        return 'Confirmar Horário Fixo'
      }
    },
    fixedReleaseLabel() {
      if (this.schedule.coach_id) {
        return 'Liberar Aula Fixa'
      } else {
        return 'Liberar Horário Fixo'
      }
    },
    amountTotalPlayers: function () {
      return this.schedule.players.reduce((total, player) => total += (player.amount ? this.currencyParsed(player.amount) : 0), 0)
    },
    isValidSchedule() {
      if (this.schedule) {
        if (!this.schedule.court_id || !this.schedule.date || !this.schedule.start_hour) {
          return false
        }
        if (['normal', 'fixed'].includes(this.schedule.type) && !this.schedule.players.length) {
          return false
        }
        if (['lesson', 'lesson-fixed'].includes(this.schedule.type) && !this.schedule.coach_id) {
          return false
        }
      }
      return true
    },
    hoursAvailableToSchedule() {
      if (this.schedule.start_hour) {
        const court = this.courts.find(court => court.id === this.schedule.court_id)
        if (court && court.hours && court.hours.length) {
          const nextHourScheduled = court.hours.filter(hour => !hour.available && hour.start_hour >= this.schedule.end_hour)
          return [
            this.schedule.end_hour,
            ...court.hours.filter(hour => {
              if (nextHourScheduled.length > 0) {
                return hour.available && hour.start_hour >= this.schedule.end_hour && hour.end_hour < nextHourScheduled[0].end_hour
              } else {
                return hour.available && hour.start_hour >= this.schedule.end_hour
              }
            }).map(hour => hour.end_hour)
          ]
        }
      }
      return []
    },
    filteredDependenciesPaymentMethods() {
      return this.dependencies.payment_methods
    },
    scheduleAmount() {
      const court = this.courts.find(item => item.id === this.schedule.court_id)
      if (court) {
        const hours = court.hours.filter((item) => {
          let scheduleEndHour = this.schedule.end_hour
          if (scheduleEndHour === '00:00:00') {
            scheduleEndHour = '23:59:59'
          }
          let itemEndHour = item.end_hour
          if (itemEndHour === '00:00:00') {
            itemEndHour = '23:59:59'
          }
          return item.start_hour >= this.schedule.start_hour
              && itemEndHour <= scheduleEndHour
        })
        return hours.reduce((total, item) => total += item.price, 0)
      }
      return 0
    },
    filteredDependenciesPlans() {
      return this.dependencies.plans.filter(item => {
        if (item.type === 'lesson_fixed' && this.schedule.type === 'lesson-fixed' && item.coach_id === this.schedule.coach_id) {
          return true
        }
        if (item.type === 'schedule_fixed' && this.schedule.type === 'fixed') {
          return true
        }
        return false
      })
    },
    hasOnlinePayment() {
      return Array.isArray(this.schedule.transactions)
          && this.schedule.transactions.some(item => item.status === 'paid')
    }
  },
  watch: {
    show: debounce(function (value) {
      this.isScheduleModalVisible = value
      if (value) {
        this.schedulePrice.price = 0
        this.schedulePrice.edit = false
        if (this.scheduleProp.id) {
          this.showSchedule()
        } else {
          this.schedule = {...this.schedule, ...cloneDeep(this.scheduleProp)}
          if (this.schedule.create && this.isCoach) {
            this.schedule.type = 'lesson'
            this.schedule.coach_id = this.currentUser.id
            this.schedule.coach = {
              ...this.schedule.coach,
              id: this.currentUser.id,
              name: this.currentUser.name,
              email: this.currentUser.email,
              phone: this.currentUser.phone,
              coach_config: this.currentUser.coach_config,
            }
          }
          this.handleSchedulePrice()
        }
      }
      bus.$emit('hide-loader')
    }, 100),
    'searchClient': debounce(function (value) {
      value && value !== this.schedule.client_id && this.onSearchClient(value)
    }, 300),
    'searchInviteClient': debounce(function (value) {
      value && value !== this.playerSelected && this.onSearchClient(value)
    }, 300),
    'scheduleQueue.client_id': function (value) {
      if (value) {
        this.addClientToScheduleQueue(value)
      }
    },
    'schedule.type': function (value) {
      if (!this.schedule.id) {
        this.schedule.price = 0
      }
      this.schedule.fixed = (value === 'fixed' || value === 'lesson-fixed' || this.schedule.schedule_fixed)
      if (this.schedule.fixed) {
        this.schedule.plan_due_date = this.schedule.date
      }
      setTimeout(() => {
        this.handleSchedulePrice()
      }, 50)
    },
    'schedule.coach_id': function (value) {
      if (!value && !this.schedule.id) {
        this.schedule.price = 0
      }
    },
  },
  validations: {
    schedule: {
      court_id: {required},
      date: {required},
      start_hour: {required},
      client_id: {
        required: requiredIf(function () {
          return this.create
        })
      },
      coach_id: {
        required: requiredIf(function () {
          return this.create && this.scheduleLessonType
        })
      }
    }
  },
  methods: {
    toggleScheduleVisible() {
      this.$emit('update:show', !this.show)
    },
    async handleScheduleVisible() {
      if (this.isScheduleModalVisible) {
        this.groupSelected = ''
        this.playerSelected = ''
        this.$refs.clientComponentPlayerInvite?.reset()
      }
      this.$emit('update:show', this.isScheduleModalVisible)
    },
    handleSchedulePrice() {
      let price = this.schedule.price
      if (price === 0) {
        if (this.scheduleLessonType) {
          if (this.schedule.coach_id) {
            price = this.schedule.coach.coach_config?.amount_class || 0
          }
        } else {
          const court = this.courts.find(item => item.id === this.schedule.court_id)
          if (court) {
            const hours = court.hours.filter((item) => {
              let scheduleEndHour = this.schedule.end_hour
              if (scheduleEndHour === '00:00:00') {
                scheduleEndHour = '23:59:59'
              }
              let itemEndHour = item.end_hour
              if (itemEndHour === '00:00:00') {
                itemEndHour = '23:59:59'
              }
              return item.start_hour >= this.schedule.start_hour
                  && itemEndHour <= scheduleEndHour
            })
            price = hours.reduce((total, item) => total += item.price, 0)
          }
        }
      }
      this.schedule.price = price
    },
    showSchedule(id, clientId, courtId, startHour, startDatetime) {
      id = id || (this.scheduleProp.id ? parseInt(this.scheduleProp.id) : '')
      clientId = clientId || (this.scheduleProp.client_id || '')
      courtId = courtId || (this.scheduleProp.court_id || '')
      startHour = startHour || (this.scheduleProp.start_hour || '')
      startDatetime = startDatetime || `${this.scheduleProp.date} ${this.scheduleProp.start_hour}`
      return new Promise(resolve => {
        this.isLoadingData = true
        bus.$emit('show-loader')
        let data = {
          id,
          client_id: clientId,
          court_id: courtId,
          start_hour: startHour,
          start_datetime: startDatetime,
        }
        if (this.scheduleProp.weekday !== 99) {
          data.weekday = this.scheduleProp.weekday
        }
        restfulService.post('schedule', 'show', null, data)
            .then((response) => {
              response.date = this.dateTimeFormatDate(response.start_datetime)
              response.start_hour = this.dateTimeFormatTime(response.start_datetime)
              response.end_hour = this.dateTimeFormatTime(response.end_datetime)
              this.schedule = {...cloneDeep(defaultSchedule), ...cloneDeep(response)}
              this.schedule.unavailable = response.unavailable || false
              this.schedule.unavailable_details = response.unavailable_details || {notes: ''}
              this.schedule.players.map(item => {
                item.amount = this.currencyFormatter(item.amount)
              })
              this.isLoadingData = false
              this.closeScheduleModal = false
              bus.$emit('hide-loader')
              resolve()
            })
            .catch(e => {
              console.log(e)
              this.isLoadingData = false
              bus.$emit('hide-loader')
              resolve()
            })
      })
    },
    confirmSchedule(schedule) {
      let editSchedule = true
      if (schedule) {
        editSchedule = false
        this.schedule = {...cloneDeep(defaultSchedule), ...cloneDeep(schedule)}
      }
      const data = {
        is_multiple: this.selectionEnabled && this.selectionList.length > 0,
        selection: this.selectionList
      }
      bus.$emit('show-loader')
      restfulService.put('schedule', 'confirm', this.schedule.id, data)
          .then(() => {
            notifyService.success({message: 'Reserva confirmada com sucesso.'})
            this.$emit('updateListForced')
            if (editSchedule) {
              const {id, client_id, court_id, start_hour, start_datetime} = this.schedule
              this.showSchedule(id, client_id, court_id, start_hour, start_datetime)
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    onSelectClient(client) {
      this.schedule.client = {...client}
    },
    onSelectCoach(client) {
      this.schedule.coach = cloneDeep({...this.schedule.coach, ...client})
      this.handleSchedulePrice()
    },
    async onSelectClientPlayers(client) {
      if (!this.schedule.players.some(item => item.client.id === client.id)) {
        let coupons = []
        const result = await restfulService.post('client', 'get-schedule-credits', null, {client_id: client.id})
        if (Array.isArray(result)) {
          coupons = [...result]
        }
        this.schedule.players.push({
          client: {
            id: client.id,
            name: client.name,
            phone: client.phone,
            active_plans: client.active_plans ?? [],
          },
          side: client.user ? client.user.side : 'both',
          amount: 0,
          payment_method_id: '',
          generate_entry: false,
          paid: false,
          notify: false,
          type: 'player',
          coupons: [...coupons]
        })
        bus.$emit('hide-loader')
        this.$refs.clientComponentPlayerSchedule.reset()
      }
    },
    changePlayerSide(side) {
      if (this.schedule.players_limit === 4) {
        let hasTwoLeftPlayers = side === 'left' && this.schedule.players.filter(item => item.side === 'left').length > 2
        let hasTwoRightPlayers = side === 'right' && this.schedule.players.filter(item => item.side === 'right').length > 2
        if (hasTwoLeftPlayers || hasTwoRightPlayers) {
          notifyService.warning({
            hideLoader: true,
            message: `Já existem dois atletas de: ${hasTwoLeftPlayers ? 'Esquerda' : 'Direita'} nesse jogo`
          })
        }
      }
    },
    removePlayer(player) {
      this.schedule.players = [...this.schedule.players.filter(item => item.client.id !== player.client.id)]
    },
    removePayment(item) {
      item.payment_method_id = ''
      item.coupon_id = ''
      item.amount = '0,00'
      item.paid = false
    },
    resetClientScheduleQueue() {
      this.scheduleQueue.client_id = ''
      this.scheduleQueue.fixed_court = true
      this.scheduleQueue.fixed_date = true
    },
    handleShowQueueOptions() {
      this.showQueueOptions = !this.showQueueOptions
    },
    async addClientToScheduleQueue(clientId) {
      bus.$emit('show-loader')
      const data = {
        court_id: this.schedule.court_id,
        start_datetime: this.schedule.start_datetime,
        client_id: clientId,
        fixed_court: this.scheduleQueue.fixed_court,
        fixed_date: this.scheduleQueue.fixed_date
      }
      restfulService.post('schedule', 'queue-add', null, data)
          .then(response => {
            this.resetClientScheduleQueue()
            this.$refs.clientScheduleQueue.reset()
            this.schedule.queue = [...response]
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    actionSetScheduleQueue(item) {
      const data = {
        queue_id: item.id,
        client_id: item.client_id,
        court_id: this.schedule.court_id,
        start_datetime: this.schedule.start_datetime,
        weekday: this.schedule.weekday
      }
      if (this.schedule.id) {
        data.schedule_id = this.schedule.id
      }
      this.notifyUser.type = 'queue_set'
      this.notifyUser.data = {...data}
      this.notifyUser.players.splice(0)
      this.notifyUser.players.push({
        notify: false,
        type: 'player',
        client: {
          id: item.client_id,
          name: item.client.name
        }
      })
      this.isNotifyUserModalVisible = true
    },
    setScheduleQueue() {
      bus.$emit('show-loader')
      this.isNotifyUserModalVisible = false
      const data = {...this.notifyUser.data}
      data.notify = this.notifyUser.value
      data.notify_users = this.notifyUser.players.filter(item => item.notify)
      restfulService.post('schedule', 'queue-set', null, data)
          .then(response => {
            notifyService.success({message: 'Reserva alterada com sucesso.'})
            this.schedule = {...response}
            this.$emit('updateListForced')
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    removeScheduleQueue(item) {
      bus.$emit('show-loader')
      const data = {queue_id: item.id}
      restfulService.post('schedule', 'queue-remove', null, data)
          .then(() => {
            this.showSchedule(this.schedule.id, this.schedule.client_id, this.schedule.court_id, this.schedule.start_hour, this.schedule.start_datetime)
            this.$emit('updateListForced')
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    inviteGroup() {
      bus.$emit('show-loader')
      let data = cloneDeep(this.schedule)
      data.start_datetime = this.schedule.date + ' ' + data.start_hour
      data.end_datetime = this.schedule.date + ' ' + data.end_hour
      data.group_id = this.groupSelected
      delete data.date
      delete data.start_hour
      delete data.end_hour
      restfulService.post('schedule', 'invite-group', null, data)
          .then(response => {
            bus.$emit('show-loader')
            this.$emit('updateListForced')
            let schedule = response.schedule
            schedule.date = this.dateTimeFormatDate(schedule.start_datetime)
            schedule.start_hour = this.dateTimeFormatTime(schedule.start_datetime)
            const {id, client_id, court_id, start_hour, start_datetime} = schedule
            this.showSchedule(id, client_id, court_id, start_hour, start_datetime)
            this.groupSelected = ''
            notifyService.success({message: 'Grupo convidado com sucesso.'})
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    invitePlayer() {
      bus.$emit('show-loader')
      let data = cloneDeep(this.schedule)
      data.start_datetime = this.schedule.date + ' ' + data.start_hour
      data.end_datetime = this.schedule.date + ' ' + data.end_hour
      data.invited_client_id = this.playerSelected
      delete data.date
      delete data.start_hour
      delete data.end_hour
      restfulService.post('schedule', 'invite-player', null, data)
          .then(response => {
            bus.$emit('show-loader')
            this.$emit('updateListForced')
            let schedule = response.schedule
            schedule.date = this.dateTimeFormatDate(schedule.start_datetime)
            schedule.start_hour = this.dateTimeFormatTime(schedule.start_datetime)
            const {id, client_id, court_id, start_hour, start_datetime} = schedule
            this.showSchedule(id, client_id, court_id, start_hour, start_datetime)
            this.playerSelected = ''
            this.$refs.clientComponentPlayerInvite?.reset()
            notifyService.success({message: 'Atleta convidado com sucesso.'})
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    cancelInvite(item) {
      bus.$emit('show-loader')
      const data = {invite_id: item.id}
      restfulService.post('schedule', 'cancel-invite', this.schedule.id, data)
          .then(response => {
            this.schedule.invites = [...response.invites]
            notifyService.success({message: 'Convite removido com sucesso.'})
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    confirmInvite(item) {
      bus.$emit('show-loader')
      const data = {invite_id: item.id}
      restfulService.post('schedule', 'confirm-invite', this.schedule.id, data)
          .then(response => {
            this.$emit('updateListForced')
            this.schedule.invites = [...response.invites]
            this.schedule.players = [...response.players]
            notifyService.success({message: 'Atleta confirmado no jogo.'})
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    saveScheduleType($event, updateFixed, closeScheduleModal) {
      updateFixed = updateFixed ?? false
      closeScheduleModal = closeScheduleModal ?? false
      this.closeScheduleModal = closeScheduleModal
      this.schedule.update_fixed = updateFixed
      this.notifyUser.type = 'save'
      this.notifyUser.players.splice(0)
      this.schedule.players.forEach(item => {
        this.notifyUser.players.push({...item, notify: false, type: 'player'})
      })
      if (this.notifyUser.players.length === 0 && this.schedule.fixed) {
        this.notifyUser.players.push({...{client: this.schedule.client}, notify: false, type: 'player'})
      }
      if (this.scheduleLessonType) {
        this.notifyUser.players.push({...{client: this.schedule.coach}, notify: false, type: 'coach'})
      }
      this.isNotifyUserModalVisible = true
    },
    onSaveSchedule(forced) {
      forced = forced || false
      bus.$emit('show-loader')
      let data = {...cloneDeep(this.schedule)}
      data.forced = forced
      saveSchedule(data, {...this.notifyUser}, [...this.selectionList])
          .then(async ({id, client_id, court_id, start_hour, start_datetime, code, data}) => {
            if (code && code === 409) {
              bus.$emit('hide-loader')
              const {value: confirm} = await confirmDialog({
                title: `Existe um conflito com outro horário/aula fixo do cliente: ${data.client}`,
                text: 'Deseja cancelar esse horário em conflito?',
              })
              if (confirm) {
                bus.$emit('show-loader')
                const deleteData = {
                  id: data.id,
                  client_id: this.schedule.client_id,
                  court_id: this.schedule.court_id,
                  weekday: this.schedule.weekday,
                  date: this.schedule.date,
                  start_time: this.schedule.start_hour,
                  end_time: this.schedule.end_hour
                }
                await restfulService.post('schedule', 'delete-fixed', null, deleteData)
                this.onSaveSchedule(true)
              }
            } else {
              this.$emit('updateListForced')
              notifyService.success({message: 'Horário salvo com sucesso.'})
              this.isNotifyUserModalVisible = false
              if (this.closeScheduleModal) {
                this.toggleScheduleVisible()
              } else {
                this.showSchedule(id, client_id, court_id, start_hour, start_datetime)
              }
            }
          })
          .catch(async (e) => {
            console.log(e)
            this.isNotifyUserModalVisible = false
            bus.$emit('hide-loader')
          })
    },
    async removeSchedule(schedule, type, confirmation) {
      type = type || null
      confirmation = confirmation || false
      if (schedule) {
        this.schedule = {...cloneDeep(defaultSchedule), ...cloneDeep(schedule)}
        this.schedule.grid_remove = true
      }
      if (type && type === 'releaseFixed') {
        bus.$emit('show-loader')
        const data = {
          id: this.schedule?.id || '',
          court_id: this.schedule.court.id,
          date: this.schedule.date,
          start_hour: this.schedule.start_hour,
          end_hour: this.schedule.end_hour,
          is_multiple: false,
          selection: []
        }
        releaseScheduleFixed(data)
            .then(() => {
              notifyService.success({message: 'Horário fixo liberado com sucesso.'})
              this.$emit('updateListForced')
              this.toggleScheduleVisible()
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      } else {
        if (confirmation) {
          let title = 'Atenção!'
          let text = 'Deseja realmente cancelar essa reserva?'
          if (this.schedule.fixed && !this.schedule.schedule_fixed) {
            let fixedLabel = 'esse HORÁRIO FIXO'
            if (this.scheduleLessonType) {
              fixedLabel = 'essa AULA FIXA'
            }
            title = `Atenção! Deseja realmente cancelar ${fixedLabel}?`
            text = `Essa ação irá cancelar ${fixedLabel} e não estará mais na grade de horários`
          }
          const {value: confirm} = await confirmDialog({
            title,
            text,
          })
          if (!confirm) {
            return
          }
        }
        this.notifyUser.type = 'remove'
        this.notifyUser.players.splice(0)
        this.schedule.players.map(item => {
          this.notifyUser.players.push({...item, notify: false, type: 'player'})
        })
        if (this.notifyUser.players.length === 0 && this.schedule.fixed) {
          this.notifyUser.players.push({...{client: this.schedule.client}, notify: false, type: 'player'})
        }
        if (this.scheduleLessonType) {
          this.notifyUser.players.push({...{client: this.schedule.coach}, notify: false, type: 'coach'})
        }
        this.isNotifyUserModalVisible = true
      }
    },
    confirmNotifySchedule() {
      if (this.notifyUser.type === 'remove' && this.schedule.fixed && !this.schedule.schedule_fixed) {
        this.confirmRemoveScheduleFixed()
      } else if (this.notifyUser.type === 'remove') {
        this.confirmRemoveSchedule()
      } else if (this.notifyUser.type === 'save') {
        this.onSaveSchedule()
      } else if (this.notifyUser.type === 'queue_set') {
        this.setScheduleQueue()
      }
    },
    confirmRemoveSchedule() {
      const data = {
        notify_users: [...this.notifyUser.players],
        is_multiple: this.selectionEnabled && this.selectionList.length > 0,
        selection: this.selectionList
      }
      let scheduleId = this.schedule.id
      restfulService.post('schedule', 'delete', scheduleId, data)
          .then(() => {
            this.$emit('updateListForced')
            if (!this.schedule.grid_remove) {
              this.toggleScheduleVisible()
            }
            this.isNotifyUserModalVisible = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async confirmRemoveScheduleFixed() {
      bus.$emit('show-loader')
      const data = {
        client_id: this.schedule.client_id,
        court_id: this.schedule.court_id,
        weekday: this.schedule.weekday,
        date: this.schedule.date,
        start_time: this.schedule.start_hour,
        end_time: this.schedule.end_hour
      }
      restfulService.post('schedule', 'delete-fixed', null, data)
          .then(() => {
            this.$emit('updateListForced')
            this.toggleScheduleVisible()
            if (this.isNotifyUserModalVisible) {
              this.isNotifyUserModalVisible = false
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    playerPaymentMethods(player) {
      return this.filteredDependenciesPaymentMethods.filter(item => {
        if (item.key === 'saldo') {
          return player.coupons && player.coupons.length > 0
        }
        return true
      })
    },
    playerHasPlanCreditActive(player) {
      return Array.isArray(player.client.active_plans) && player.client.active_plans.some(clientPlan =>
          clientPlan && Array.isArray(clientPlan.rules) && clientPlan.rules.some(planRule =>
              planRule.rule_data.type === 'credit'))
    },
    playerPaymentMethod(player) {
      return this.filteredDependenciesPaymentMethods.find(item => item.id === player.payment_method_id)
    },
    playerPaymentMethodDescription(player) {
      if (player.coupon_uses) {
        return 'Créditos'
      }
      return this.playerPaymentMethod(player)?.description ?? ''
    },
    onSelectedEditCoach(client) {
      this.schedule.coach = {
        ...{
          id: client.id,
          name: client.name,
          email: client.email,
          phone: client.phone,
        }
      }
      this.editCoachEnabled = false
    },
    convertTo(type) {
      this.editCoachEnabled = true
      this.schedule.type = type
    },
    onEditSchedulePrice() {
      this.schedulePrice.price = this.currencyFormatter(this.schedule.price)
      this.schedulePrice.edit = true
    },
    onSaveSchedulePrice() {
      this.schedule.price = this.currencyParsed(this.schedulePrice.price)
      this.schedulePrice.edit = false
    }
  }
}
</script>

<template>
  <div v-if="show">
    <gripo-dialog :model.sync="isScheduleModalVisible"
                  width="90vw"
                  top="1vh"
                  :actions-close-btn="false"
                  custom-dialog-class="schedule-show"
                  :on-close="handleScheduleVisible">
      <template v-slot:header>Agendamento de Horário</template>
      <template v-slot:content>

        <el-form ref="schedule"
                 v-if="!isLoadingData"
                 :model="schedule"
                 label-position="top">
          <v-container fluid class="pl-0 pr-0 pt-0 mb-2 pb-0 schedule-grid-layout" grid-list-lg text-xs-center>
            <v-layout row wrap>
              <v-flex :md3="!schedule.create || isCoach" :md6="schedule.create && !isCoach" xs6>
                <template v-if="!schedule.create">
                  <schedule-show-type :schedule="schedule"
                                      @convertToLesson="convertTo"
                                      v-if="schedule.type === 'fixed' || schedule.type === 'lesson-fixed'"/>
                  <schedule-show-type :schedule="schedule"
                                      v-if="schedule.local === 'app'">
                    <div class="pb-1 white--text fs-12"><strong>Criada em:</strong>
                      {{ schedule.created_at | dateTimeEnToBrFull }}
                    </div>
                  </schedule-show-type>
                  <schedule-show-type :schedule="schedule"
                                      @convertToLesson="convertTo"
                                      v-if="schedule.type === 'system' || schedule.type === 'lesson'"/>
                </template>
                <template v-else>
                  <div class="schedule-info-box text-center -fill-height">
                    <div>
                      <h2 class="mb-0 pb-2">Tipo da reserva</h2>
                      <div class="pt-1">
                        <el-radio-group v-model="schedule.type"
                                        class="schedule-type-create">
                          <el-radio label="normal"
                                    border
                                    v-if="!isCoach"
                                    size="mini"
                                    class="mb-0 -system">
                            Horário Avulso
                          </el-radio>
                          <el-radio label="fixed"
                                    border
                                    v-if="!isCoach"
                                    size="mini"
                                    class="mb-0 ml-0 -fixed">
                            Horário Fixo
                          </el-radio>
                          <el-radio label="lesson"
                                    border
                                    size="mini"
                                    class="mb-0 ml-0 -lesson">
                            Aula Avulsa
                          </el-radio>
                          <el-radio label="lesson-fixed"
                                    border
                                    size="mini"
                                    class="mb-0 ml-0 -lesson-fixed">
                            Aula Fixa
                          </el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                  </div>
                </template>
              </v-flex>
              <v-flex md3 xs6
                      v-if="!schedule.create && (schedule.client && schedule.client.id || (schedule.coach && schedule.coach.id))">
                <div class="schedule-info-box -fill-height -two-tiles">
                  <div>
                    <h5>{{ scheduleLessonType ? 'Professor(a)' : 'Cliente' }}</h5>
                    <h4>
                      <template v-if="scheduleLessonType">
                        <span class="mr-1" v-if="schedule?.coach">{{ schedule?.coach?.name || '' }}</span>
                        <template v-if="!isCoach">
                          <el-button circle
                                     size="mini"
                                     class="el-button--extra-small-circle"
                                     v-if="!editCoachEnabled"
                                     title="Alterar professor(a)"
                                     @click="editCoachEnabled = true">
                            <i class="fas fa-pencil-alt"></i>
                          </el-button>
                          <el-button circle
                                     size="mini"
                                     class="el-button--extra-small-circle"
                                     v-else-if="schedule?.coach"
                                     title="Cancelar edição"
                                     @click="editCoachEnabled = false">
                            <i class="fas fa-times"></i>
                          </el-button>
                          <div v-if="editCoachEnabled" class="mt-1">
                            <client-component :client-id.sync="schedule.coach_id"
                                              @selectClient="onSelectedEditCoach"
                                              ref="editLessonCoach"
                                              :hide-details="true"
                                              placeholder="Pesquisar professor(a)"
                                              empty-list-message="Professores não encontrados"
                                              :search-data="{is_coach: true}"
                                              :enable-create="false"/>
                          </div>
                        </template>
                      </template>
                      <span v-else><client-name :client="schedule.client"/></span>
                    </h4>
                  </div>
                </div>
              </v-flex>
              <v-flex md3 xs6>
                <div class="schedule-info-box -fill-height -two-tiles">
                  <div>
                    <h5>Quadra</h5>
                    <h4>
                      <span>{{ schedule.court.name }}</span>
                      <span v-if="schedule.sport">
                        | {{ schedule.sport.label }}
                      </span>
                    </h4>
                    <div class="subheading">
                      <div v-if="!schedulePrice.edit" class="flex-c -jc-ce gap-5">
                        <span v-if="schedule.price > 0">{{ schedule.price | currencyFormatter }}</span>
                        <el-button circle
                                   size="mini"
                                   class="el-button--extra-small-circle"
                                   v-if="!schedulePrice.edit"
                                   title="Alterar valor"
                                   @click="onEditSchedulePrice">
                          <i class="fas fa-pencil-alt"></i>
                        </el-button>
                      </div>
                      <div v-else class="flex-c -jc-ce gap-5">
                        <el-input v-model="schedulePrice.price"
                                  size="mini"
                                  v-money="moneyMask"
                                  class="currency"/>
                        <el-button circle
                                   type="success"
                                   size="mini"
                                   class="el-button--extra-small-circle"
                                   title="Salvar valor"
                                   @click="onSaveSchedulePrice">
                          <i class="fas fa-check"></i>
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-flex md3 xs12>
                <div class="schedule-info-box -fill-height -two-tiles">
                  <div>
                    <h5>Data/Horário</h5>
                    <h4><span>{{ schedule.date | dateEnToBr }}</span></h4>
                    <div class="subheading">
                      <div class="flex-c -jc-ce gap-10">
                        <div class="fs-14">{{ schedule.start_hour | hourShort }}h</div>
                        <div>até</div>
                        <div class="fs-14" v-if="!schedule.create">{{ schedule.end_hour | hourShort }}h</div>
                        <div style="width: 120px;" v-else>
                          <el-select v-model="schedule.end_hour"
                                     size="small"
                                     placeholder="Selecionar a hora final..."
                                     empty-text="Nenhum registro encontrado">
                            <el-option v-for="item in hoursAvailableToSchedule"
                                       :key="item"
                                       :label="item"
                                       :value="item">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <el-alert type="error"
                    class="mb-3"
                    show-icon
                    :closable="false"
                    v-if="!schedule.has_schedule_grid && !schedule.create">
            <div slot="title">
              Atenção! Horário fora da grade de horários
            </div>
            <div>
              Verifique o horário de início e fim da reserva e também a configuração dos períodos da quadra
            </div>
          </el-alert>
          <v-container fluid grid-list-lg class="p-0 schedule-grid-layout">
            <v-layout wrap>
              <template
                  v-if="schedule.id && schedule.status === 'created' && !schedule.payment_enabled">
                <v-flex xs12 class="mb-2">
                  <v-alert
                      :value="true"
                      color="primary"
                      outline
                      class="mb-4">
                    <div class="flex-c">
                      <div class="flex-c -jc-fs">
                        <div class="fs-18 mr-2">
                          <i class="fas fa-exclamation-circle"></i>
                        </div>
                        <div class="text-center">
                          <div class="subheading">
                            Reserva aguardando confirmação
                          </div>
                        </div>
                      </div>
                      <div>
                        <el-button type="primary"
                                   @click="confirmSchedule(null)"
                                   :disabled="isSubmitted"
                                   :loading="isSubmitted">
                          <i class="fas fa-check"></i>
                          <span>Confirmar Reserva</span>
                        </el-button>
                      </div>
                    </div>
                  </v-alert>
                </v-flex>
              </template>
              <template v-if="scheduleLessonType && schedule.create && !isCoach">
                <v-flex xs12>
                  <div class="schedule-box-content">
                    <h4>
                      <i class="fas fa-search"></i>
                      <span class="ml-2">Professor(a)</span>
                    </h4>
                    <el-form-item class="-no-validation is-required" label="Campo obrigatório">
                      <client-component :client-id.sync="schedule.coach_id"
                                        ref="lessonScheduleFixed"
                                        :hide-details="true"
                                        placeholder="Pesquisar professor(a)"
                                        empty-list-message="Professores não encontrados"
                                        :search-data="{is_coach: true}"
                                        :enable-create="false"
                                        @selectClient="onSelectCoach"
                                        :validate="$v.schedule.coach_id"/>
                    </el-form-item>
                  </div>
                </v-flex>
              </template>
              <template>
                <v-flex xs12 :xs8="schedule.create">
                  <div class="schedule-box-content">
                    <el-row :gutter="10">
                      <el-col :span="$vuetify.breakpoint.xsOnly ? 24 : 8">
                        <h4>
                          <i class="fas fa-search"></i>
                          <span class="ml-2">Adicionar Atletas ({{ schedule.players.length }})</span>
                        </h4>
                        <el-form-item class="-no-validation">
                          <client-component :client-id.sync="schedule.player_id"
                                            ref="clientComponentPlayerSchedule"
                                            @selectClient="onSelectClientPlayers"
                                            :disabled="schedule.status === 'created'"/>
                        </el-form-item>
                      </el-col>
                      <el-col :span="$vuetify.breakpoint.xsOnly ? 24 : 16">
                        <h4>
                          <i class="fas fa-users"></i>
                          <span class="ml-2">Atletas Confirmados</span>
                        </h4>
                        <template v-if="schedule.players.length">
                          <table class="table-show-data -dense">
                            <thead>
                            <th>Atleta</th>
                            <th width="100">Lado</th>
                            <th width="170" class="text-right"
                                v-if="!schedule.fixed || ( schedule.fixed && schedule.schedule_fixed)">
                              Valor Individual
                            </th>
                            <th width="220" v-if="!schedule.fixed || ( schedule.fixed && schedule.schedule_fixed)">
                              Forma de Pgto.
                            </th>
                            <th v-if="schedule.payment_enabled" class="text-center">Pago?</th>
                            <th class="text-center">Ações</th>
                            </thead>
                            <tbody>
                            <tr v-for="(player, index) in schedule.players" :key="index">
                              <td style="width: 25%;">
                                <span class="fs-14"></span>
                                <client-name :client="player.client"/>
                              </td>
                              <td class="list-table-nowrap">
                                <el-select v-model="player.side"
                                           @change="changePlayerSide(player.side)"
                                           size="mini"
                                           class="el-select-full"
                                           :disabled="schedule.status === 'created'">
                                  <el-option key="left" value="left" label="Esquerda"/>
                                  <el-option key="right" value="right" label="Direita"/>
                                  <el-option key="both" value="both" label="Ambos"/>
                                </el-select>
                              </td>
                              <td v-if="!schedule.fixed || ( schedule.fixed && schedule.schedule_fixed)">
                                <div
                                    v-if="index > 0 || !hasOnlinePayment"
                                    :class="[{'readonly': player.paid || (player.payment_method_id && playerPaymentMethod(player).key === 'saldo')}]">
                                  <el-input v-model="player.amount"
                                            v-if="!player.paid"
                                            size="mini"
                                            v-money="moneyMask"
                                            class="currency"/>
                                  <div v-else class="text-right">
                                    {{ player.amount }}
                                  </div>
                                </div>
                              </td>
                              <td v-if="!schedule.fixed || ( schedule.fixed && schedule.schedule_fixed)">
                                <div
                                    v-if="index > 0 || !hasOnlinePayment"
                                    :class="[{'readonly': player.paid}]">
                                  <template v-if="!player.paid">
                                    <el-select v-model="player.payment_method_id"
                                               size="mini"
                                               clearable
                                               class="el-select-full"
                                               placeholder="Selecionar..."
                                               empty-text="Nenhum registro encontrado">
                                      <el-option v-for="item in playerPaymentMethods(player)"
                                                 :key="item.id"
                                                 :label="item.description"
                                                 :value="item.id">
                                      </el-option>
                                    </el-select>
                                    <el-select v-model="player.coupon_id"
                                               v-if="player.payment_method_id && playerPaymentMethod(player).key === 'saldo' && player.coupons.length > 0"
                                               size="mini"
                                               clearable
                                               class="el-select-full mt-1"
                                               popper-class="el-select-custom-item"
                                               placeholder="Selecione o pacote"
                                               empty-text="Nenhum registro encontrado">
                                      <el-option v-for="item in player.coupons"
                                                 :key="item.id"
                                                 :label="`Créditos: ${item.uses_limit - item.uses_current_count}`"
                                                 :value="item.id">
                                        <div class="fs-12">
                                          <template v-if="item.description">{{ item.description }}</template>
                                          <template v-else>{{ item.start_datetime | dateTimeEnToBr }}h</template>
                                        </div>
                                        <div class="fs-14 pt-1">
                                          <strong>Créditos Disponíveis:</strong>
                                          {{ item.uses_limit - item.uses_current_count }}
                                        </div>
                                      </el-option>
                                    </el-select>
                                  </template>
                                  <div v-else>
                                    {{ playerPaymentMethodDescription(player) }}
                                  </div>
                                </div>
                              </td>
                              <td v-if="schedule.payment_enabled" class="text-center">
                                <span v-if="player.paid && (index > 0 || !hasOnlinePayment)"
                                      class="fs-12">
                                  Pago em: <strong>{{ player.pay_datetime | dateTimeEnToBr }}h</strong>
                                </span>
                                <span v-else>-</span>
                              </td>
                              <td class="text-center list-table-nowrap">
                                <div class="btn-actions btn-actions-big">
                                  <el-button type="warning"
                                             size="mini"
                                             title="Remover atleta"
                                             @click="removePlayer(player)"
                                             v-if="!player.paid && (index > 0 || !hasOnlinePayment)">
                                    <i class="fas fa-times"></i>
                                    <span v-if="!$vuetify.breakpoint.xsOnly">Remover atleta</span>
                                  </el-button>
                                  <el-button type="danger"
                                             size="mini"
                                             title="Remover Pagamento"
                                             @click="removePayment(player)"
                                             v-if="player.paid && (index > 0 || !hasOnlinePayment)">
                                    <i class="fas fa-dollar-sign"></i>
                                    <span v-if="!$vuetify.breakpoint.xsOnly">Remover Pgto.</span>
                                  </el-button>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                            <tfoot v-if="schedule.fixed === false">
                            <tr>
                              <th align="right" colspan="2">Total:</th>
                              <th align="right" class="pr-4">
                                {{ amountTotalPlayers | currencyFormatter }}
                              </th>
                              <th colspan="3"></th>
                            </tr>
                            </tfoot>
                          </table>
                        </template>
                        <template v-else>
                          <p>Nenhum atleta confirmado!</p>
                        </template>
                      </el-col>
                    </el-row>
                  </div>
                </v-flex>
              </template>
              <template v-if="schedule.create && schedule.fixed">
                <v-flex v-if="schedule.fixed">
                  <el-alert
                      v-if="!schedule.players.length"
                      type="warning"
                      show-icon
                      :closable="false"
                      class="mb-2">
                    <div class="fs-14">
                      Adicione pelo menos 1 atleta para realizar o vínculo com um plano/pacote
                    </div>
                  </el-alert>
                  <div :class="['schedule-box-content', {'disabled': !schedule.players.length}]">
                    <h4>
                      <i class="fas fa-user-tie"></i>
                      <span class="ml-2">Plano/Pacote</span>
                    </h4>
                    <el-form-item class="-no-validation"
                                  :label="`Vincular um Plano de ${scheduleLessonType ? 'Aula Fixa' : 'Horário Fixo'}`">
                      <v-select :items="filteredDependenciesPlans"
                                v-model="schedule.plan_id"
                                single-line
                                outline
                                clearable
                                hide-details
                                placeholder="Selecione um Plano/Pacote"
                                no-data-text="Planos ou Pacotes não encontrados"
                                item-text="name"
                                item-value="id"/>
                    </el-form-item>
                    <el-form-item class="-no-validation"
                                  label="Data de Vencimento da Cobrança">
                      <el-date-picker
                          v-model="schedule.plan_due_date"
                          type="date"
                          format="dd/MM/yyyy"
                          value-format="yyyy-MM-dd"
                          v-mask="'##/##/####'"
                          placeholder="Data de Vencimento da Cobrança">
                      </el-date-picker>
                    </el-form-item>
                  </div>
                </v-flex>
              </template>
              <v-flex md6 xs12>
                <div class="schedule-box-content">
                  <template v-if="!scheduleLessonType">
                    <h4>
                      <i class="fas fa-user-lock"></i>
                      <span class="ml-2">Limite de Atletas na Reserva</span>
                    </h4>
                    <el-form-item class="-no-validation">
                      <el-input-number :min="0"
                                       v-model="schedule.players_limit"/>
                    </el-form-item>
                  </template>
                  <el-form-item label="Observações">
                    <el-input v-model="schedule.notes" type="textarea" :rows="4"/>
                  </el-form-item>
                </div>
                <div class="schedule-box-content schedule-queue mt-4"
                     v-if="schedule.id || (!schedule.create && schedule.fixed)">
                  <div class="flex-c">
                    <h4 class="mb-0">
                      <i class="fas fa-list"></i>
                      <span class="ml-2">Adicionar Cliente na Fila de Espera</span>
                    </h4>
                    <el-button size="mini"
                               circle
                               class="el-button--extra-small-circle"
                               @click="handleShowQueueOptions">
                      <span key="showQueueOptions" v-if="!showQueueOptions"><i class="fas fa-arrow-down"></i></span>
                      <span key="hideQueueOptions" v-else><i class="fas fa-arrow-up"></i></span>
                    </el-button>
                  </div>
                  <div v-if="showQueueOptions" class="mt-2">
                    <el-form-item class="-no-validation">
                      <el-row :gutter="10" class="mb-3">
                        <el-col :span="12">
                          <el-form-item>
                            <template v-slot:label>
                                <span class="mr-2">
                                    Fixar Quadra
                                </span>
                              <el-popover placement="bottom"
                                          width="200"
                                          trigger="hover"
                                          content="Essa opção faz com que o registro da fila fique vinculado a quadra atual">
                                  <span class="el-item-label-icon-info"
                                        slot="reference">
                                      <i class="fas fa-exclamation-circle"></i>
                                  </span>
                              </el-popover>
                            </template>
                            <el-switch v-model="scheduleQueue.fixed_court"
                                       :active-value="true"
                                       :inactive-value="false"
                                       active-text="SIM"
                                       inactive-text="NÃO">
                            </el-switch>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item>
                            <template v-slot:label>
                                <span class="mr-2">
                                    Fixar na Data
                                </span>
                              <el-popover placement="bottom"
                                          width="200"
                                          trigger="hover"
                                          content="Essa opção faz com que o registro da fila fique vinculado a data atual">
                                  <span class="el-item-label-icon-info"
                                        slot="reference">
                                      <i class="fas fa-exclamation-circle"></i>
                                  </span>
                              </el-popover>
                            </template>
                            <el-switch v-model="scheduleQueue.fixed_date"
                                       :active-value="true"
                                       :inactive-value="false"
                                       active-text="SIM"
                                       inactive-text="NÃO">
                            </el-switch>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <client-component :client-id.sync="scheduleQueue.client_id"
                                        ref="clientScheduleQueue"/>
                    </el-form-item>
                    <v-list dense v-if="schedule.queue.length">
                      <v-list-tile>
                        <el-row :gutter="10">
                          <el-col :span="1"><strong>#</strong></el-col>
                          <el-col :span="12"><strong>Cliente</strong></el-col>
                          <el-col :span="3"><strong>Quadra</strong></el-col>
                          <el-col :span="3"><strong>Data</strong></el-col>
                          <el-col :span="5" class="text-center"><strong>Ações</strong>
                          </el-col>
                        </el-row>
                      </v-list-tile>
                      <template v-for="(item, index) in schedule.queue">
                        <v-divider :key="index"/>
                        <v-list-tile :key="item.title">
                          <v-list-tile-content>
                            <el-row :gutter="10">
                              <el-col :span="1">{{ item.position }}</el-col>
                              <el-col :span="12">
                                {{ item.client.name }}
                              </el-col>
                              <el-col :span="3">
                                {{ item.court_id !== null ? 'Sim' : 'Não' }}
                              </el-col>
                              <el-col :span="3">
                                {{ item.date !== null ? 'Sim' : 'Não' }}
                              </el-col>
                              <el-col :span="5" class="text-center">
                                <el-button type="success"
                                           size="mini"
                                           class="v-btn__mini"
                                           circle
                                           title="Atribuir cliente na reserva"
                                           @click="actionSetScheduleQueue(item)"><i
                                    class="fas fa-check"></i>
                                </el-button>
                                <el-button type="danger"
                                           size="mini"
                                           class="v-btn__mini"
                                           circle
                                           title="Remover da lista de espera"
                                           @click="removeScheduleQueue(item)"><i
                                    class="fas fa-times"></i>
                                </el-button>
                              </el-col>
                            </el-row>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>
                    </v-list>
                    <div v-else>Ainda não existem clientes na fila de espera</div>
                  </div>
                </div>
              </v-flex>
              <template v-if="schedule.payment_enabled">
                <v-flex md6 xs12 class="mt-2">
                  <schedule-payment
                      @update-schedule="showSchedule(schedule.id, schedule.client_id, schedule.court_id, schedule.start_hour, schedule.start_datetime)"
                      :schedule="schedule"/>
                </v-flex>
              </template>
              <template
                  v-if="!schedule.create && ['system', 'fixed', 'app'].includes(schedule.type)">
                <v-flex md6 xs12>
                  <div class="schedule-box-content">
                    <el-alert type="warning"
                              class="mb-3"
                              show-icon
                              :closable="false">
                      <div slot="title">
                        O convite será enviado para o <strong>whatsapp</strong> do atleta caso ela possua um <strong>número
                        de telefone</strong> cadastrado!
                      </div>
                    </el-alert>
                    <h4>
                      <i class="fas fa-user-plus"></i>
                      <span class="ml-2">Convidar Atleta</span>
                    </h4>
                    <el-row :gutter="20">
                      <el-col :span="14">
                        <client-component :client-id.sync="playerSelected"
                                          ref="clientComponentPlayerInvite"
                                          :hide-details="true"
                                          :disabled="schedule.status === 'created'"/>
                      </el-col>
                      <el-col :span="10">
                        <div>
                          <el-button @click="invitePlayer"
                                     :disabled="!playerSelected || schedule.status === 'created'">
                            <i class="fas fa-plus"></i>
                            <span>Convidar Atleta</span>
                          </el-button>
                        </div>
                      </el-col>
                    </el-row>
                    <div class="mt-3 mb-3">
                      <h4>
                        <i class="fas fa-users"></i>
                        <span class="ml-2">Convidar Grupo de Atletas</span>
                      </h4>
                      <el-row :gutter="20">
                        <el-col :span="14">
                          <v-select :items="dependencies.players_group"
                                    v-model="groupSelected"
                                    single-line
                                    outline
                                    clearable
                                    hide-details
                                    placeholder="Selecione um Grupo"
                                    no-data-text="Grupos não encontrados"
                                    item-text="name"
                                    item-value="id"
                                    :disabled="schedule.status === 'created'"/>
                        </el-col>
                        <el-col :span="10">
                          <el-button @click="inviteGroup"
                                     :disabled="!groupSelected || schedule.status === 'created'">
                            <i class="fas fa-plus"></i>
                            <span>Convidar Grupo</span>
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                    <v-divider class="mb-3"></v-divider>
                    <h4>
                      <i color="blue" class="fas fa-plus"></i>
                      <span class="ml-2">Atletas Convidados</span>
                    </h4>
                    <v-list dense v-if="schedule.invites.length">
                      <v-list-tile>
                        <el-row :gutter="10">
                          <el-col :span="12"><strong>Atleta</strong></el-col>
                          <el-col :span="7"><strong>Convidado em:</strong></el-col>
                          <el-col :span="5" class="text-center"><strong>Ações</strong>
                          </el-col>
                        </el-row>
                      </v-list-tile>
                      <template v-for="(item, index) in schedule.invites">
                        <v-divider :key="index"/>
                        <v-list-tile :key="item.title">
                          <v-list-tile-content>
                            <el-row :gutter="10">
                              <el-col :span="12">
                                <client-name :client="item.client"/>
                              </el-col>
                              <el-col :span="7">
                                {{ item.created_at | dateTimeEnToBr }}h
                              </el-col>
                              <el-col :span="5">
                                <div v-if="item.status === 'created'"
                                     class="text-center">
                                  <el-button type="danger"
                                             size="mini"
                                             class="v-btn__mini"
                                             circle
                                             title="Remover convite"
                                             :disabled="schedule.status === 'created'"
                                             @click="cancelInvite(item)"><i
                                      class="fas fa-times"></i>
                                  </el-button>
                                  <el-button type="success"
                                             size="mini"
                                             class="v-btn__mini"
                                             circle
                                             title="Confirmar atleta no jogo"
                                             :disabled="schedule.status === 'created'"
                                             @click="confirmInvite(item)"><i
                                      class="fas fa-check"></i>
                                  </el-button>
                                </div>
                                <div v-else>
                                  <schedule-invite-status
                                      :status="item.status"/>
                                </div>
                              </el-col>
                            </el-row>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>
                    </v-list>
                    <div v-else>Nenhum atleta foi convidado para esse horário</div>
                  </div>
                </v-flex>
              </template>
            </v-layout>
          </v-container>
        </el-form>
      </template>
      <template v-slot:actions>
        <template v-if="!isLoadingData">
          <el-button @click="removeSchedule(null, null, schedule.fixed)"
                     type="danger"
                     v-if="!schedule.create"
                     :disabled="isSubmitted"
                     :loading="isSubmitted">
            <i class="fas fa-times"></i>
            <span v-if="scheduleLessonType">Cancelar {{ schedule.fixed ? 'Aula Fixa' : 'Aula Avulsa' }}</span>
            <span v-else>Cancelar {{ schedule.fixed ? 'Horário Fixo' : 'Horário Avulso' }}</span>
          </el-button>
          <v-spacer></v-spacer>
          <el-popconfirm
              :title="`Confirme a liberação do horário!`"
              confirm-button-text="Sim, liberar!"
              cancel-button-text="Não"
              icon=""
              v-if="schedule.fixed && !schedule.create && !schedule.schedule_fixed"
              @confirm="removeSchedule(schedule, 'releaseFixed')">
            <el-button type="warning"
                       slot="reference"
                       key="releaseRemoveSchedule"
                       :disabled="isSubmitted"
                       :loading="isSubmitted">
              <i class="fas fa-share-square"></i>
              <span>{{fixedReleaseLabel}}</span>
            </el-button>
          </el-popconfirm>
          <el-button type="info"
                     key="saveScheduleFixed"
                     @click="saveScheduleType($event)"
                     v-if="schedule.fixed && !schedule.schedule_fixed && !schedule.create"
                     :disabled="isSubmitted || !isValidSchedule"
                     :loading="isSubmitted">
            <i class="fas fa-check"></i>
            <span>{{fixedConfirmLabel}}</span>
          </el-button>
          <el-button type="success"
                     key="saveSchedule"
                     @click="saveScheduleType($event, true)"
                     :disabled="isSubmitted || !isValidSchedule"
                     :loading="isSubmitted">
            <i class="fas fa-check"></i>
            <span>Salvar</span>
          </el-button>
          <el-button type="primary"
                     key="saveScheduleCloseModal"
                     @click="saveScheduleType($event, true, true)"
                     :disabled="isSubmitted || !isValidSchedule"
                     :loading="isSubmitted">
            <i class="fas fa-check"></i>
            <span>Salvar e Minimizar Reserva</span>
          </el-button>
        </template>
      </template>

    </gripo-dialog>

    <schedule-notify :show="isNotifyUserModalVisible"
                     :notify-user="notifyUser"
                     :schedule-fixed="schedule.fixed && !schedule.schedule_fixed"
                     @confirmRemoveScheduleFixed="confirmRemoveScheduleFixed"
                     @confirmRemoveSchedule="confirmRemoveSchedule"
                     @saveSchedule="onSaveSchedule"
                     @setScheduleQueue="setScheduleQueue"/>
  </div>
</template>