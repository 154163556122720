import * as restfulService from '@app/services/restfulService'

export const defaultConfigData = {
    id: '',
    country_id: '',
    city_id: '',
    cpf_cnpj: '',
    name: '',
    legal_name: '',
    club_name: '',
    modules: {
        basic: false,
        professional: false,
        tournament: false,
    },
    contact_email: '',
    contact_phone: '',
    financial_email: '',
    financial_phone: '',
    address: {
        cep: '',
        street: '',
        number: '',
        neighborhood: '',
        complement: '',
        codigo_ibge: '',
        city_id: '',
        city: {
            id: '',
            state_id: '',
            name: '',
            name_with_state: '',
            state: {
                id: '',
                name: '',
                initials: '',
            }
        },
    },
    tenant_nf: {
        focus_nfe_id: '',
        focus_nfe_token_homologacao: '',
        focus_nfe_token_producao: '',
        nfse_city_enabled: true,
        certificate: {
            exists: false,
            created_at: '',
            expire_datetime: '',
            expired: false,
            file: '',
            base64: '',
            password: ''
        },
        nfce: {
            status: 'inactive',
            enabled: false,
            staging: false,
            inscricao_estadual: '',
            regime_tributario: '',
            id_token_nfce: '1',
            serie_nfce: '1',
            token_csc: '',
            proximo_numero: 1
        },
        nfce_staging: {
            enabled: false,
            id_token_nfce: '1',
            serie_nfce: '1',
            token_csc: '',
            proximo_numero: 1
        },
        nfse: {
            city_config: {},
            status: 'inactive',
            enabled: false,
            staging: false,
            inscricao_municipal: '',
            regime_especial_tributacao: '',
            proximo_numero: '1',
            serie_nfse: '1',
            login: '',
            password: '',
            codigo_cnae: '',
            item_lista_servico: '',
            codigo_tributario_municipio: '',
            iss_retido: false,
            aliquota: 0,
        }
    },
    tenant_payment: {
        teller_control_enabled: false,
        entry_portion_notify_custom_message: '',
        order_online_payment: {
            enabled: false,
            generate_transaction_on_sent_order: false
        },
        order_whatsapp_message: {
            unpaid: false,
            paid: false
        }
    },
    tenant_stone_payment: {
        active: false,
        requested: false,
        stoneCode: '',
        enabled: false,
        auto_generate_invoice: false,
        devices: []
    }
}

export const getTenantData = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await restfulService.get('tenant', 'data', null, null, null)
            resolve(data)
        } catch (e) {
            reject(e)
        }
    })
}
