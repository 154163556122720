<script>
import GripoDialog from '../../../../../components/common/Dialog'
import ClientComponent from '../../../../../components/common/ClientComponent'
import ClientName from '../../../../../components/common/ClientName'
import FinishOrderGenerateNfce from './GenerateNfce'
import FinishOrderGenerateNfse from './GenerateNfse'
import FinishOrderSendWhatsapp from './SendWhatsapp'
import FinishOrderProductItem from './ProductItem'

import * as restfulService from '../../../../../services/restfulService'
import * as notifyService from '../../../../../services/notifyService'
import DataService from '../../../../../services/dataService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {currencyConfig} from '@utils/money'
import {mapGetters} from 'vuex'

import {cloneDeep} from 'lodash'
import {defaultOrder} from '../ListDaily'
import {confirmDialog, warningDialog} from '@utils/flash'

const defaultPosStonePayment = {
  used: false,
  id: '',
  gateway_order_id: '',
  device_sn: '',
  generating: false,
  pending_payment: false,
  print_invoice: false,
  invoice_include_client: false,
}

export default {
  name: 'finish-order',
  components: {
    FinishOrderSendWhatsapp,
    FinishOrderGenerateNfce,
    FinishOrderGenerateNfse,
    ClientComponent,
    ClientName,
    GripoDialog,
    FinishOrderProductItem,
  },
  mixins: [helpers],
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => {
      }
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dependencies: {
        payment_methods: [],
        bank_accounts: []
      },
      localOrder: {...cloneDeep(defaultOrder)},
      handleDialog: true,
      handleDialogVisible: true,
      isLoadingData: true,
      isSubmitted: false,
      isFinishOrderModalVisible: false,
      useBalance: false,
      settledEntries: false,
      sendToWhats: {
        enabled: false,
        message: ''
      },
      generateNfce: false,
      generateNfse: false,
      includeCpfNfce: false,
      includeCpfNfse: false,
      updateAmount: false,
      editClientModal: false,
      updateOptions: false,
      paymentMethods: [],
      loadingPaymentMethod: false,
      coupons: [],
      showPaymentMethodDialog: false,
      paymentMethodData: {
        payment_method_id: '',
        bank_account_id: '',
        amount: 0,
        disabled: false,
        authorization_code: '',
        data: {
          ...defaultPosStonePayment
        },
        status: 'paid',
      },
      tenantPaymentConfig: {},
      customMessage: '',
      partialSaving: false,
      posStonePayment: {...defaultPosStonePayment},
      loadingClientPlans: false,
      loadingClientBalance: false,
      loadingOrderPayments: false,
      posOrderInternal: null
    }
  },
  computed: {
    ...mapGetters(['tenant', 'permissions', 'hasCommercialReceiptModule', 'hasCommercialInvoiceModule', 'hasStonePayment', 'isLoading']),
    moneyMask() {
      return currencyConfig()
    },
    wsStonePayment() {
      const key = 'stonePayment'
      return {
        channel: `${key}.${this.tenant?.id || ''}`,
        listen: `.${key}`
      }
    },
    paymentMethodsOptions() {
      return this.dependencies.payment_methods.filter(item => !['dinheiro', 'pix', 'cartao_de_debito', 'cartao_de_credito', 'saldo'].includes(item.key))
    },
    paymentMethodsFiltered() {
      return this.dependencies.payment_methods.filter(item => !['saldo'].includes(item.key))
    },
    localOrderProducts() {
      return this.localOrder.products
    },
    enableFinishOrder() {
      return !this.hasPendingPayments && this.totalAmount > 0
    },
    localProductsList() {
      return this.localOrderProducts.filter(item => item.product.type === 'product')
    },
    localServicesList() {
      return this.localOrderProducts.filter(item => item.product.type === 'service')
    },
    localProductsListNotPaid() {
      return this.localOrderProducts.filter(item => !item.payment)
    },
    totalAmount() {
      let total = 0
      if (this.localOrderProducts.length > 0) {
        total += this.localProductsListNotPaid.reduce((total, product) => total + (this.currencyParsed(product.final_price) - this.currencyParsed(product.discount)), 0)
      }
      if (this.settledEntries) {
        total += this.localOrder.client.debit
      }
      return total
    },
    totalAmountWithoutDiscount() {
      let total = this.totalAmount
      if (this.localOrderProducts.length > 0) {
        total += this.localProductsListNotPaid.reduce((total, product) => total + (this.currencyParsed(product.discount)), 0)
      }
      return total
    },
    totalAmountPaid() {
      if (Array.isArray(this.paymentMethods) && this.paymentMethods.length > 0) {
        return this.paymentMethods.reduce((total, item) => total + this.currencyParsed(item.amount), 0)
      }
      return 0
    },
    discountAmount() {
      return this.localOrderProducts.reduce((total, product) => {
        return total + this.currencyParsed(this.currencyParsed(product.discount) > this.currencyParsed(product.final_price)
            ? product.final_price
            : product.discount)
      }, 0)
    },
    balanceToUse() {
      let amountToPay = this.totalAmount - this.discountAmount
      if (this.useBalance) {
        let amountMinusBalance = amountToPay - this.localOrder.client.balance
        if (this.localOrder.client.balance > amountToPay) {
          return amountToPay
        } else if (amountMinusBalance < 0) {
          return amountMinusBalance
        } else {
          return this.localOrder.client.balance
        }
      }
      return 0
    },
    amountTotalPay() {
      return this.totalAmount - this.balanceToUse
    },
    totalDebit() {
      return this.amountTotalPay > 0
          ? this.totalAmount - this.totalAmountPaid
          : 0
    },
    totalCredit() {
      return (this.amountTotalPay + this.balanceToUse > 0)
          ? this.totalAmountPaid - (this.amountTotalPay + this.balanceToUse) - this.discountAmount
          : 0
    },
    canStoreInvoice() {
      return (this.hasCommercialReceiptModule || this.hasCommercialInvoiceModule)
          && this.permissions.some(item => item.name === 'store.invoice')
    },
    isPaid() {
      return this.totalDebit <= 0
    },
    clientPhone() {
      return this.localOrder.client.phone ?? this.localOrder.client?.user?.phone ?? ''
    },
    canConfirmAddPaymentMethod() {
      const amountPaid = this.currencyParsed(this.paymentMethodData.amount)
      const hasPaymentMethod = this.paymentMethodData.payment_method_id && amountPaid > 0
      if (this.posStonePayment.used && (!hasPaymentMethod || this.hasStonePayment && (!this.posStonePayment.device_sn || this.posStonePayment.pending_payment))) {
        return false
      }
      return !!this.paymentMethodData.payment_method_id && amountPaid > 0
    },
    showAuthorizationCode() {
      if (this.paymentMethodData.payment_method_id) {
        const paymentMethod = this.dependencies.payment_methods.find(item => item.id === this.paymentMethodData.payment_method_id)
        let allowedPaymentMethods = ['cartao_de_credito', 'cartao_de_debito', 'pix']
        return allowedPaymentMethods.includes(paymentMethod.key)
      }
      return false
    },
    orderHasClient() {
      return !!(this.localOrder?.client?.id || false)
    },
    posStonePaymentSelectedPaymentEnabled() {
      if (this.paymentMethodData.payment_method_id) {
        const paymentMethod = this.dependencies.payment_methods.find(item => item.id === this.paymentMethodData.payment_method_id)
        return ['cartao_de_credito', 'cartao_de_debito', 'pix'].includes(paymentMethod.key)
      }
      return false
    },
    posStonePaymentDevices() {
      return this.tenant?.settings?.tenant_stone_payment?.devices || []
    },
    posStoneAutoGenerateInvoice() {
      return this.tenant?.settings?.tenant_stone_payment?.auto_generate_invoice || false
    },
    posStonePaymentPaymentType() {
      const paymentMethod = this.dependencies.payment_methods.find(item => item.id === this.paymentMethodData.payment_method_id)
      return paymentMethod.key === 'cartao_de_credito' ? 'credit' : (paymentMethod.key === 'cartao_de_debito' ? 'debit' : 'pix')
    },
    posStoneAmountInvoiceDisabled() {
      return this.posStoneAutoGenerateInvoice && this.posStonePayment.used
    },
    hasPendingPayments() {
      return this.paymentMethods.some(item => item.status === 'created')
    },
    clientCpf() {
      return this.localOrder.client?.cpf_cnpj || this.localOrder.client?.user?.cpf || null
    }
  },
  watch: {
    async showDialog(value) {
      this.isFinishOrderModalVisible = false
      if (value) {
        this.startWebsocket()
        bus.$emit('show-loader')
        this.partialSaving = false
        this.updateAmount = true
        this.paymentMethods.splice(0)
        this.useBalance = false
        this.settledEntries = false
        this.localOrder = {...cloneDeep(this.order)}
        this.localOrderProducts.map(product => {
          const productPrice = !product.split ? (product.price * product.quantity) : product.split_price
          product.discount = this.currencyFormatter(product.discount)
          product.final_price = this.currencyFormatter(productPrice)
          return product
        })
        if (this.localOrder.temp_payment_methods) {
          this.paymentMethods = [...this.localOrder.temp_payment_methods]
          this.startOrderIntervalCheck()
        }
        this.generateNfce = false
        this.generateNfse = false
        this.getDependencies()
        if (this.localOrder.client.id) {
          this.loadOrderPayments()
          this.loadClientBalance()
          this.loadClientPlans()
        }
        setTimeout(() => {
          this.updateAmount = false
          this.sendToWhats.enabled = false
          this.sendToWhats.message = ''
          this.isFinishOrderModalVisible = true
          bus.$emit('hide-loader')
        }, 150)
      }
    },
    isFinishOrderModalVisible(value) {
      if (!value) {
        this.closeWebsocket()
        if (this.posOrderInternal) {
          clearInterval(this.posOrderInternal)
          this.posOrderInternal = null;
        }
        this.$emit('update:showDialog', false)
      }
    },
    'paymentMethodData.payment_method_id'() {
      this.isSubmitted = false
      if (this.showAuthorizationCode === false) {
        this.paymentMethodData.authorization_code = ''
      }
      if (!this.posStonePaymentSelectedPaymentEnabled) {
        this.posStonePayment.used = false
      }
    },
    'posStonePayment.used'(value) {
      this.posStonePayment.print_invoice = false
      if (value) {
        this.posStonePayment.print_invoice = this.posStoneAutoGenerateInvoice
      }
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'payment_method'}, {domain: 'bank_account'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    startWebsocket() {
      window.Echo.channel(this.wsStonePayment.channel)
          .listen(this.wsStonePayment.listen, ({status, deviceOrderId}) => {
            const paymentMethodIndex = this.paymentMethods.findIndex(item => (item?.data?.device_order_id || '') === deviceOrderId)
            if (paymentMethodIndex !== -1) {
              if (status === 'paid') {
                this.paymentMethods[paymentMethodIndex].status = status
                notifyService.success({
                  message: 'Pagamento confirmado!'
                })
              } else if (status === 'canceled') {
                this.removePaymentMethod(paymentMethodIndex)
                notifyService.warning({
                  message: 'O pagamento foi cancelado na máquina ou expirou'
                })
              }
            }
          })
    },
    closeWebsocket() {
      window.Echo.leaveChannel(this.wsStonePayment.channel)
    },
    async loadOrderPayments() {
      this.loadingOrderPayments = true
      try {
        const order = await restfulService.get('order', 'temp-payment-methods', this.localOrder.id)
        if (Array.isArray(order.temp_payment_methods)) {
          this.paymentMethods = [...order.temp_payment_methods]
        }
      } finally {
        this.loadingOrderPayments = false
      }
    },
    async loadClientPlans() {
      this.loadingClientPlans = true
      try {
        const activeClientPlans = await restfulService.post('client', 'get-active-plans', null, {client_id: this.localOrder.client.id})
        this.localOrder.clientPlans = [...activeClientPlans]
      } finally {
        this.loadingClientPlans = false
      }
    },
    async loadClientBalance() {
      this.loadingClientBalance = true
      try {
        const response = await restfulService.post('client', 'financial', this.localOrder.client.id, {})
        this.localOrder.client.address_available = response.address_available
        this.localOrder.client.address = response.address
        this.localOrder.client.balance = response.balance
        this.localOrder.client.debit = response.debit
        this.localOrder.client.coupons = response.coupons
      } finally {
        this.loadingClientBalance = false
      }
    },
    getPaymentMethodId(key) {
      return this.dependencies.payment_methods.find(item => item.key === key)?.id
    },
    handleFinishOrderVisible() {
      this.isFinishOrderModalVisible = !this.isFinishOrderModalVisible
    },
    updateProduct(productUpdated) {
      const productIndex = this.localOrderProducts.findIndex(item => item.id === productUpdated.id)
      this.localOrder.products.splice(productIndex, 1, {...productUpdated})
      this.updateAmounts()
    },
    updateAmounts() {
      setTimeout(() => {
        this.localOrderProducts.map(product => {
          let finalPrice = !product.split ? (product.price * product.quantity) : product.split_price
          if (finalPrice !== this.currencyParsed(product.final_price)) {
            product.discount = 0
          }
          return product
        })
        this.checkUseBalancePaymentMethod()
      }, 100)
    },
    checkUseBalancePaymentMethod() {
      const paymentMethodBalance = this.dependencies.payment_methods.find(item => item.key === 'saldo')
      if (Array.isArray(this.paymentMethods) && paymentMethodBalance) {
        const paymentMethodBalanceIndex = this.paymentMethods.findIndex(item => item.payment_method_id === paymentMethodBalance.id)
        if (this.useBalance) {
          if (paymentMethodBalanceIndex === -1) {
            this.paymentMethods.push({
              payment_method_id: paymentMethodBalance.id,
              bank_account_id: '',
              amount: this.balanceToUse,
              disabled: true,
              status: 'paid'
            })
          }
        } else {
          if (paymentMethodBalanceIndex > -1) {
            this.paymentMethods.splice(paymentMethodBalanceIndex, 1)
          }
        }
      }
    },
    removeOrder() {
      bus.$emit('show-loader')
      restfulService.post('order', 'cancel', this.localOrder.id)
          .then(() => {
            this.$emit('removeOrder', this.localOrder.id)
            this.handleFinishOrderVisible()
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    async confirmOrder(redirect) {
      const showConfirmation = !this.paymentMethods.length
      if (showConfirmation) {
        const hasCouponPayment = this.localOrderProducts.some(item => item.payment || item.coupon_id)
        if (this.amountTotalPay === 0 && !hasCouponPayment) {
          warningDialog({
            text: 'Você não pode fechar uma comanda com valor zerado',
          })
          return
        }
        if (this.totalDebit > 0) {
          const {value: confirm} = await confirmDialog({
            title: 'A forma de pagamento não foi informada',
            text: 'A comanda será anotada e ficará com pagamento pendente',
            icon: 'warning',
          })
          if (confirm) {
            this.confirmOrderRequest(redirect)
          }
        } else if (hasCouponPayment) {
          this.confirmOrderRequest(redirect)
        }
      } else {
        const cantGenerateNfce = this.generateNfce && this.localOrderProducts
            .filter(item => item.product.type === 'product')
            .some(item => !item.product.nf_enabled)
        const cantGenerateNfse = this.generateNfse && this.localOrderProducts
            .filter(item => item.product.type === 'service')
            .some(item => !item.product.nf_enabled)
        if (cantGenerateNfce || cantGenerateNfse) {
          let title = 'Existem produtos não habilitados para emissão da NFCe'
          let text = 'A NFCe será gerada somente com os produtos habilitados e não será possível gerar outro cupom para essa comanda'
          if (cantGenerateNfse) {
            title = 'Existem serviços não habilitados para emissão da NFSe'
            text = 'A nota de serviço será gerado somente com os serviços habilitados'
          }
          const {value: confirm} = await confirmDialog({
            title,
            text,
            icon: 'warning',
          })
          if (confirm) {
            this.confirmOrderRequest(redirect)
          }
        } else {
          this.confirmOrderRequest(redirect)
        }
      }
    },
    async confirmOrderRequest(redirect) {
      this.isSubmitted = true
      const hasPosPayment = this.paymentMethods.some(item => item?.data?.used || false)
      bus.$emit('show-loader', hasPosPayment && this.posStonePayment.print_invoice
          ? 'Finalizando e aguardando a impressão da NFCe na máquina...'
          : null)
      const orderId = this.localOrder.id
      let data = {
        settled_entries: this.settledEntries,
        entries_amount: this.localOrder.client.debit,
        use_balance: this.useBalance,
        discount: this.currencyParsed(this.localOrder.discount),
        amount: this.totalAmountPaid,
        total_credit: this.totalCredit > 0 ? this.totalCredit : 0,
        total_debit: this.totalDebit > 0 ? this.totalDebit : 0,
        payment_method_id: this.localOrder.payment_method_id || null,
        bank_account_id: this.localOrder.bank_account_id || null,
        send_to_whats: this.sendToWhats.enabled,
        custom_message: this.sendToWhats.message,
        generate_nfce: this.generateNfce,
        generate_nfse: this.generateNfse,
        include_cpf_nfce: this.includeCpfNfce,
        include_cpf_nfse: this.includeCpfNfse,
        products: [...this.localOrderProducts].map(product => {
          product.discount = this.currencyParsed(product.discount)
          product.final_price = this.currencyParsed(product.final_price)
          return product
        }),
        payment_methods: [...this.paymentMethods.map(item => {
          if (!this.posStonePayment.used) {
            item.data = null
          }
          return item
        })],
        notes: this.localOrder.notes || null,
        redirect: redirect,
      }
      restfulService.put('order', 'confirm', orderId, data)
          .then(() => {
            this.onFinishOrder(redirect)
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    onFinishOrder(redirect) {
      notifyService.success({
        hideLoader: true,
        message: redirect ? 'Comanda fechada' : 'Comanda fechada, para mais detalhes verifique o contas a receber.'
      })
      if (redirect) {
        const entryFilters = {
          client: this.localOrder.client.name,
          show_opened_entries: true
        }
        this.$router.push({name: 'entry.revenue.index', params: {filtersData: entryFilters}})
        this.isSubmitted = false
      } else {
        this.$emit('removeOrder', this.localOrder.id)
        this.$emit('confirmOrder', this.localOrder.id)
        this.handleFinishOrderVisible()
        this.isSubmitted = false
        bus.$emit('hide-loader')
      }
    },
    editClient(client) {
      this.$refs.clientComponentFinishOrder.loadClientDialog(client)
    },
    onSaveClient(client) {
      this.updateOptions = true
      const balance = this.localOrder.client.balance
      const debit = this.localOrder.client.debit
      this.localOrder.client = {...cloneDeep(client)}
      this.localOrder.client.balance = balance
      this.localOrder.client.debit = debit
      setTimeout(() => {
        this.updateOptions = false
      }, 100)
    },
    closeDialog() {
      setTimeout(() => {
        this.$emit('update:showDialog', false)
        this.$emit('updateList')
      }, 100)
      if (this.posOrderInternal) {
        clearInterval(this.posOrderInternal)
        this.posOrderInternal = null;
      }
    },
    async updateOrderClient() {
      bus.$emit('show-loader')
      const data = {client_id: this.localOrder.client_id}
      const response = await restfulService.put('order', 'update-client', this.localOrder.id, data)
      this.localOrder.client = {...cloneDeep(response.client)}
      this.localOrder.label = ''
      bus.$emit('hide-loader')
    },
    selectPaymentMethod(paymentMethodId) {
      this.updateAmount = true
      this.localOrder.payment_method_id = paymentMethodId
      this.openModalPaymentMethod(paymentMethodId, this.currencyFormatter(paymentMethodId ? this.totalDebit : 0))
      setTimeout(() => {
        this.updateAmount = false
        this.localOrder.payment_method_id = ''
      }, 100)
    },
    openModalPaymentMethod(paymentMethodId, amount) {
      paymentMethodId = paymentMethodId || ''
      amount = amount || 0
      if (!this.showPaymentMethodDialog) {
        this.paymentMethodData.payment_method_id = paymentMethodId
        this.paymentMethodData.bank_account_id = ''
        this.paymentMethodData.amount = amount
        this.paymentMethodData.authorization_code = ''
        this.paymentMethodData.disabled = false
        if (this.posStonePaymentSelectedPaymentEnabled) {
          this.posStonePayment = {...defaultPosStonePayment}
          if (this.posStonePaymentDevices.length === 1) {
            this.posStonePayment.device_sn = this.posStonePaymentDevices[0].sn
          }
        }
      }
      this.showPaymentMethodDialog = !this.showPaymentMethodDialog && paymentMethodId
    },
    addPaymentMethod() {
      if (this.posStonePayment.used && this.paymentMethods.some(item => !!item.data)) {
        warningDialog({
          text: 'Só é possível 1 pagamento integrado com máquina POS por comanda',
        })
        return
      }
      const data = this.posStonePayment.used ? {
        used: this.posStonePayment.used,
        device_sn: this.posStonePayment.device_sn,
        payment_method_type: this.posStonePaymentPaymentType,
        print_invoice: this.posStonePayment.print_invoice,
        invoice_include_client: this.posStonePayment.invoice_include_client,
        order_id: this.localOrder.id,
        amount: this.currencyParsed(this.paymentMethodData.amount),
        device_order_id: null,
      } : null
      const paymentMethodData = {...this.paymentMethodData, data}
      paymentMethodData.amount = this.currencyParsed(paymentMethodData.amount)
      paymentMethodData.status = this.posStonePayment.used ? 'created' : 'paid'
      this.paymentMethods.push(paymentMethodData)
      this.saveTempPaymentMethods()
      this.openModalPaymentMethod()
    },
    removePaymentMethod(index) {
      this.paymentMethods.splice(index, 1)
      this.saveTempPaymentMethods()
    },
    paymentMethodName(id) {
      return (this.dependencies.payment_methods.find(item => item.id === id))?.description || ''
    },
    bankAccountName(id) {
      return (this.dependencies.bank_accounts.find(item => item.id === id))?.name || ''
    },
    async saveTempPaymentMethods() {
      const data = {temp_payment_methods: this.paymentMethods}
      const paymentMethods = await restfulService.put('order', 'temp-payment-methods', this.localOrder.id, data)
      this.paymentMethods = [...paymentMethods]
      this.startOrderIntervalCheck()
      bus.$emit('hide-loader')
    },
    async saveOrderData() {
      this.partialSaving = true
      const data = {
        notes: this.localOrder.notes,
        products: [...this.localOrderProducts].map(product => {
          product.discount = this.currencyParsed(product.discount)
          product.final_price = this.currencyParsed(product.final_price)
          return product
        }),
      }
      await restfulService.put('order', 'update-data', this.localOrder.id, data)
      this.partialSaving = false
    },
    cancelDeviceOrder(paymentMethod, index) {
      bus.$emit('show-loader', 'Cancelando pagamento...')
      const data = {device_order_id: paymentMethod.data.device_order_id}
      restfulService.put('order', 'cancel-pos-payment', this.localOrder.id, data)
          .then(() => {
            this.removePaymentMethod(index)
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    checkPosOrderStatus(paymentMethod) {
      this.loadingPaymentMethod = true
      const data = {device_order_id: paymentMethod.data.device_order_id}
      restfulService.post('device_order', 'check-status', null, data)
          .then(({device_order}) => {
            paymentMethod.status = device_order.status
            this.loadingPaymentMethod = false
          })
          .catch(e => {
            console.log(e)
            this.loadingPaymentMethod = false
          })
    },
    startOrderIntervalCheck() {
      if (this.paymentMethods.some(item => !!(item?.data?.device_order_id) && item.status === 'created')) {
        this.posOrderInternal = setInterval(this.posOrderInternalCheck, 1000 * 8) // Check every 8 seconds
      }
    },
    posOrderInternalCheck() {
      const paymentMethod = this.paymentMethods.find(item => !!(item?.data?.device_order_id) && item.status === 'created')
      if (!paymentMethod || !this.isFinishOrderModalVisible) {
        if (this.posOrderInternal) {
          clearInterval(this.posOrderInternal)
          this.posOrderInternal = null;
        }
      } else {
        this.checkPosOrderStatus(paymentMethod)
      }
    },
  }
}
</script>

<template>
  <div>
    <gripo-dialog :model.sync="isFinishOrderModalVisible"
                  width="98vw"
                  top="1vh"
                  :actions-close-btn="false"
                  :content-class="['finish-order-dialog']"
                  :on-close="closeDialog">
      <template v-slot:header>
            <span>
                Finalizar comanda: #{{ localOrder.number }}
              <span v-if="localOrder.label"> - {{ localOrder.label }}</span>
              <span v-if="localOrder.created_by_user && localOrder.created_by_user.name">
                <el-tag type="success" size="mini" class="ml-1"
                        effect="dark">Criada por: <strong>{{ localOrder.created_by_user.name || '' }}</strong></el-tag>
                <el-tag type="primary" size="mini" class="ml-2"
                        effect="dark">em <strong>{{ localOrder.created_at | dateTimeEnToBr }}h</strong></el-tag>
              </span>
            </span>
      </template>
      <template v-slot:content v-if="localOrder.id">
        <client-component :client-id.sync="localOrder.client_id"
                          ref="clientComponentFinishOrder"
                          :hide-search="true"
                          @selectClient="onSaveClient"/>

        <el-form ref="order"
                 :model="localOrder"
                 label-position="top">
          <el-row :gutter="15">
            <el-col :span="14">
              <h3 class="box-title text-center">Informações do Cliente</h3>
              <table class="table table-client-data">
                <tbody>
                <tr>
                  <td width="44%" align="center" class="client">
                    <div class="text">
                      <template v-if="localOrder.client.id">
                        <div>
                          <strong class="mr-2">
                            <client-name :client="localOrder.client"/>
                          </strong>
                          <el-button circle size="mini"
                                     title="Editar cadastro"
                                     @click="editClient(order.client)">
                            <i class="fas fa-pencil-alt"></i>
                          </el-button>
                        </div>
                        <div>{{ clientPhone }}</div>
                      </template>
                      <template v-else>
                        <span class="d-block">Selecione o cliente</span>
                        <client-component :client-id.sync="localOrder.client_id"
                                          ref="localOrderClient"
                                          class="d-block"
                                          @selectClient="updateOrderClient"
                                          :hide-details="true"/>
                      </template>
                    </div>
                  </td>
                  <td width="30%" align="center" class="debit">
                    <div v-if="loadingClientBalance">
                      <span><i class="fas fa-circle-notch spinning-effect -fast text-secondary"></i></span>
                      <span class="ml-1 font-weight-bold">Carregando Débitos...</span>
                    </div>
                    <template v-else>
                      <div class="text">
                        <span>Total de Débitos Anteriores:</span><br>
                        <strong>{{ localOrder.client.debit | currencyFormatter }}</strong>
                      </div>
                      <div class="use-balance" v-if="localOrder.client.debit > 0">
                        Quitar Débitos?
                        <el-switch v-model="settledEntries"
                                   :active-value="true"
                                   active-text="SIM"
                                   :inactive-value="false"
                                   inactive-text="NÃO"
                                   @change="updateAmounts">
                        </el-switch>
                      </div>
                    </template>
                  </td>
                  <td width="28%" align="center" class="use-balance">
                    <div v-if="loadingClientBalance">
                      <span><i class="fas fa-circle-notch spinning-effect -fast text-secondary"></i></span>
                      <span class="ml-1">Carregando Saldo...</span>
                    </div>
                    <template v-else>
                      <div>
                        <span>Saldo Disponível:</span><br>
                        <span class="tag mt-1">
                          <strong>{{ localOrder.client.balance | currencyFormatter }}</strong>
                        </span>
                      </div>
                      <div class="use-balance" v-if="localOrder.client.balance > 0">
                        Usar saldo?
                        <el-switch v-model="useBalance"
                                   :disabled="amountTotalPay === 0 && !useBalance"
                                   :active-value="true"
                                   active-text="SIM"
                                   :inactive-value="false"
                                   inactive-text="NÃO"
                                   @change="updateAmounts">
                        </el-switch>
                      </div>
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="table-responsive">
                <table class="table mt-3">
                  <template v-if="localProductsList.length > 0">
                    <thead>
                    <tr>
                      <th colspan="5" class="header-title">Produtos</th>
                    </tr>
                    <tr>
                      <th align="left">Produto</th>
                      <th>Qtd.</th>
                      <th align="right">Valor Unit.</th>
                      <th align="right">Desconto</th>
                      <th align="right">Valor Final</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="product in localProductsList">
                      <finish-order-product-item :product="product"
                                                 :products="localOrderProducts"
                                                 :order="localOrder"
                                                 :generate-nfce="generateNfce"
                                                 :generate-nfse="generateNfse"
                                                 :key="product.id"
                                                 @updateProduct="updateProduct"
                                                 @updateAmounts="updateAmounts"/>
                    </template>
                    </tbody>
                  </template>
                  <template v-if="localServicesList.length > 0">
                    <thead>
                    <tr>
                      <th colspan="5" class="header-title">Serviços</th>
                    </tr>
                    <tr>
                      <th align="left">Serviço</th>
                      <th>Qtd.</th>
                      <th align="right">Valor Unit.</th>
                      <th align="right">Desconto</th>
                      <th align="right">Valor Final</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="product in localServicesList">
                      <finish-order-product-item :product="product"
                                                 :products="localServicesList"
                                                 :order="localOrder"
                                                 :generate-nfce="generateNfce"
                                                 :generate-nfse="generateNfse"
                                                 :key="product.id"
                                                 @updateProduct="updateProduct"
                                                 @updateAmounts="updateAmounts"/>
                    </template>
                    </tbody>
                  </template>
                  <tfoot>
                  <tr class="row-warning">
                    <th colspan="4" align="right">Total Geral:</th>
                    <th align="right">{{ totalAmountWithoutDiscount | currencyFormatter }}</th>
                  </tr>
                  <tr v-if="useBalance" class="balance-to-use">
                    <th colspan="4" align="right">Saldo Utilizado:</th>
                    <th align="right">- {{ balanceToUse | currencyFormatter }}</th>
                  </tr>
                  <tr v-if="discountAmount > 0" class="row-warning">
                    <th colspan="4" align="right">Desconto:</th>
                    <th align="right">- {{ discountAmount | currencyFormatter }}</th>
                  </tr>
                  <tr class="row-success" v-show="totalCredit > 0">
                    <th colspan="4" align="right">Saldo a ser Gerado:</th>
                    <th align="right">+ {{ totalCredit | currencyFormatter }}</th>
                  </tr>
                  <tr class="row-danger" v-show="totalDebit > 0">
                    <th colspan="4" align="right">Total a Pagar (Em aberto):</th>
                    <th align="right">{{ totalDebit | currencyFormatter }}</th>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="mt-3">
                <h3 class="box-title text-center">Observações</h3>
                <el-form-item>
                  <el-input type="textarea"
                            :rows="3"
                            v-model="localOrder.notes"/>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="10">
              <h3 class="box-title text-center">Selecione a Forma de Pagamento</h3>
              <div class="text-center mt-2">
                <div class="main-payment-methods mb-2">
                  <div class="option pix" @click="selectPaymentMethod(getPaymentMethodId('dinheiro'))">
                    <i class="fas fa-money-bill"></i>
                    <span>Dinheiro</span>
                  </div>
                  <div class="option pix" @click="selectPaymentMethod(getPaymentMethodId('pix'))">
                    <i class="fas fa-qrcode"></i>
                    <span>PIX</span>
                  </div>
                  <div class="option debit" @click="selectPaymentMethod(getPaymentMethodId('cartao_de_debito'))">
                    <i class="fas fa-credit-card"></i>
                    <span>Débito</span>
                  </div>
                  <div class="option credit" @click="selectPaymentMethod(getPaymentMethodId('cartao_de_credito'))">
                    <i class="far fa-credit-card"></i>
                    <span>Crédito</span>
                  </div>
                </div>
                <el-form-item class="mb-0">
                  <v-select :items="paymentMethodsOptions"
                            :disabled="totalAmount === 0"
                            v-model="localOrder.payment_method_id"
                            single-line
                            outline
                            placeholder="Outras formas de pagamento..."
                            item-text="description"
                            item-value="id"
                            hide-details
                            no-data-text="Formas de Pagamento não encontradas"
                            @change="selectPaymentMethod"
                            clearable/>
                </el-form-item>
              </div>
              <div v-if="paymentMethods.length" class="el-loading-mask-light" v-loading="loadingPaymentMethod">
                <h3 class="box-title text-center">Pagamentos</h3>
                <table class="table">
                  <thead>
                  <tr>
                    <th>Forma de Pagamento</th>
                    <th>Status</th>
                    <th align="right">Valor</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(paymentMethod, index) in paymentMethods">
                    <tr>
                      <td>
                        {{ paymentMethodName(paymentMethod.payment_method_id) }}
                        <div v-if="paymentMethod.authorization_code">
                          Cód. de Aut.: {{ paymentMethod.authorization_code }}
                        </div>
                        <div v-if="paymentMethod.bank_account_id">
                          {{ bankAccountName(paymentMethod.bank_account_id) }}
                        </div>
                      </td>
                      <td>
                        <template v-if="paymentMethod.status === 'paid'">Pago</template>
                        <template v-else>
                          <span><i class="fas fa-circle-notch spinning-effect -fast text-secondary"></i></span>
                          <span class="ml-1">Aguardando Pagto...</span>
                        </template>
                      </td>
                      <td align="right">{{ paymentMethod.amount | currencyFormatter }}</td>
                      <td>
                        <el-button circle
                                   class="el-button--extra-small"
                                   type="info"
                                   title="Verificar Status do Pagamento"
                                   v-if="paymentMethod.status !== 'paid'"
                                   @click="checkPosOrderStatus(paymentMethod)">
                          <i class="fas fa-sync"></i>
                        </el-button>
                        <el-button circle
                                   class="el-button--extra-small"
                                   type="danger"
                                   title="Remover"
                                   v-if="paymentMethod.data?.device_order_id"
                                   :disabled="paymentMethod.disabled"
                                   @click="cancelDeviceOrder(paymentMethod, index)">
                          <i class="fas fa-times"></i>
                        </el-button>
                        <el-button circle
                                   class="el-button--extra-small"
                                   type="danger"
                                   title="Remover"
                                   v-if="!paymentMethod.data"
                                   :disabled="paymentMethod.disabled"
                                   @click="removePaymentMethod(index)">
                          <i class="fas fa-times"></i>
                        </el-button>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th colspan="2" align="right">Total Pago:</th>
                    <th align="right">{{ totalAmountPaid | currencyFormatter }}</th>
                    <th></th>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <el-alert type="warning" class="small-alert mt-3"
                        :closable="false"
                        v-if="hasPendingPayments"
                        show-icon>
                <div class="fs-14 pl-2">
                  Finalize o pagamento na máquina POS para continuar
                </div>
              </el-alert>
              <h3 class="box-title text-center mt-3">Mais Ações</h3>
              <table class="table" v-if="!updateOptions">
                <tfoot>
                <tr>
                  <th>
                    <finish-order-send-whatsapp :client="localOrder.client"
                                                :model.sync="sendToWhats"
                                                :is-paid="isPaid"/>
                  </th>
                </tr>
                <tr>
                  <th v-if="canStoreInvoice">
                    <table style="width: 100%; border: 0 !important;">
                      <tfoot>
                      <tr>
                        <th width="50%" align="right" v-if="hasCommercialInvoiceModule">
                          <finish-order-generate-nfse :client="localOrder.client"
                                                      :is-paid="isPaid"
                                                      :products="localOrderProducts"
                                                      :generate-nfse.sync="generateNfse"
                                                      :include-cpf.sync="includeCpfNfse"/>
                        </th>
                        <th width="50%" align="right" v-if="hasCommercialReceiptModule">
                          <finish-order-generate-nfce :client="localOrder.client"
                                                      :is-paid="isPaid"
                                                      :products="localOrderProducts"
                                                      :generate-nfce.sync="generateNfce"
                                                      :include-cpf.sync="includeCpfNfce"/>
                        </th>
                      </tr>
                      </tfoot>
                    </table>
                  </th>
                </tr>
                </tfoot>
              </table>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-slot:actions>
        <el-popconfirm
            :title="`A comanda será apagada`"
            confirm-button-text="Sim, apagar!"
            cancel-button-text="Não"
            icon=""
            @confirm="removeOrder(order)">
          <el-button type="danger"
                     class="remove-order"
                     :disabled="isSubmitted"
                     :loading="isSubmitted"
                     slot="reference">
            <i class="fas fa-times"></i>
            <span>Apagar Comanda</span>
          </el-button>
        </el-popconfirm>
        <v-spacer></v-spacer>
        <el-button type="info"
                   key="saveOrderData"
                   @click="saveOrderData"
                   :disabled="isSubmitted || partialSaving"
                   :loading="isSubmitted || partialSaving">
          <i class="fas fa-save"></i>
          <span>Salvar Alterações</span>
        </el-button>
        <el-button type="success"
                   key="confirmOrder"
                   @click="confirmOrder(false)"
                   :disabled="isSubmitted || !enableFinishOrder"
                   :loading="isSubmitted">
          <i class="fas fa-check"></i>
          <span>Finalizar Comanda</span>
        </el-button>
        <el-button type="primary"
                   key="confirmOrderRedirectEntries"
                   @click="confirmOrder(true)"
                   :disabled="isSubmitted || !enableFinishOrder"
                   :loading="isSubmitted">
          <i class="fas fa-money-check-alt"></i>
          <span>Finalizar e Ir pra Contas a Receber</span>
        </el-button>
      </template>

    </gripo-dialog>
    <gripo-dialog :model.sync="showPaymentMethodDialog"
                  width="60vw"
                  top="2vh"
                  :actions-close-btn="false">
      <template v-slot:header>
        Adicionando forma de pagamento
      </template>
      <template v-slot:content>
        <el-form :model="paymentMethodData" label-position="top">
          <el-row :gutter="20" class="payment-values">
            <el-col :span="10">
              <el-form-item label="Forma de Pagamento">
                <v-select :items="paymentMethodsFiltered"
                          v-model="paymentMethodData.payment_method_id"
                          single-line
                          outline
                          placeholder="Selecione uma Forma de Pagamento"
                          item-text="description"
                          item-value="id"
                          hide-details
                          no-data-text="Formas de Pagamento não encontradas"
                          clearable/>
              </el-form-item>
              <el-form-item label="Conta Bancária">
                <v-select :items="dependencies.bank_accounts"
                          v-model="paymentMethodData.bank_account_id"
                          single-line
                          outline
                          placeholder="Selecione uma Conta Bancária"
                          item-text="name"
                          item-value="id"
                          hide-details
                          no-data-text="Contas Bancárias não encontradas"
                          clearable/>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="Valor Pago"
                            :class="[{'form-item-input-money-mask -big': updateAmount}, {'disabled': posStoneAmountInvoiceDisabled}]">
                <input v-model.lazy="paymentMethodData.amount"
                       :disabled="posStoneAmountInvoiceDisabled"
                       v-money="moneyMask"
                       @change="updateAmounts"
                       type="tel"
                       v-if="!updateAmount"
                       class="input-money -big"/>
              </el-form-item>
              <el-alert type="warning" class="small-alert mt-3 mb-2"
                        :closable="false"
                        v-if="hasStonePayment && !orderHasClient"
                        show-icon>
                <div class="fs-14 pl-2">
                  Para habilitar o pagamento via Máquina POS é necessário identificar o cliente na comanda
                </div>
              </el-alert>
              <template v-if="hasStonePayment && posStonePaymentSelectedPaymentEnabled">
                <el-row :gutter="10">
                  <el-col :span="10">
                    <el-form-item label="Pagamento na Máquina POS">
                      <el-switch v-model="posStonePayment.used"
                                 :active-value="true"
                                 active-text="SIM"
                                 :inactive-value="false"
                                 inactive-text="NÃO"
                                 :disabled="posStonePayment.generating || !orderHasClient">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="14">
                    <el-form-item label="Máquina POS" v-if="posStonePayment.used">
                      <v-select :items="posStonePaymentDevices"
                                v-model="posStonePayment.device_sn"
                                :disabled="posStonePaymentDevices.length <= 1"
                                single-line
                                outline
                                placeholder="Selecione uma máquina"
                                item-text="name"
                                item-value="sn"
                                hide-details
                                no-data-text="Máquina não encontradas"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="10">
                    <el-form-item label="Gerar/Imprimir NFCe na Máquina"
                                  v-if="hasCommercialReceiptModule && posStonePayment.used && !posStoneAmountInvoiceDisabled">
                      <el-switch v-model="posStonePayment.print_invoice"
                                 :active-value="true"
                                 active-text="SIM"
                                 :inactive-value="false"
                                 inactive-text="NÃO"
                                 :disabled="posStonePayment.generating || posStoneAmountInvoiceDisabled">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="14" v-if="posStonePayment.print_invoice">
                    <el-form-item label="Identificar Cliente (CPF/CNPJ)">
                      <el-switch v-model="posStonePayment.invoice_include_client"
                                 :disabled="!clientCpf"
                                 :active-value="true"
                                 active-text="SIM"
                                 :inactive-value="false"
                                 inactive-text="NÃO">
                      </el-switch>
                    </el-form-item>
                    <el-alert type="warning" class="mt-2 small-alert text-center" :closable="false"
                              v-if="!clientCpf">
                      <slot name="name">
                        O cliente não possui CPF/CNPJ cadastrados para incluir na nota
                      </slot>
                    </el-alert>
                  </el-col>
                </el-row>
              </template>
            </el-col>
          </el-row>
          <template v-if="!hasStonePayment && showAuthorizationCode">
            <el-form-item label="Código de Autorização/Pagamento">
              <el-input placeholder="Código de Autorização/Pagamento"
                        v-model="paymentMethodData.authorization_code">
              </el-input>
            </el-form-item>
          </template>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="addPaymentMethod"
                   :disabled="isSubmitted || !canConfirmAddPaymentMethod"
                   :loading="isSubmitted">
          <i class="fas fa-check"></i>
          <span>Confirmar</span>
        </el-button>
      </template>
    </gripo-dialog>
  </div>
</template>

<style lang="scss">
.box-title {
  background-color: #ececec;
  padding: 2px 6px;
  margin-bottom: 0;
  font-size: 14px;
}

.finish-order-send-whatsapp {
  padding: 8px;
  font-weight: normal;

  .icon {
    font-size: 26px;
    color: #39B84A;
  }
}
</style>