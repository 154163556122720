<script>
import GripoDialog from '../../../../components/common/Dialog'
import FormSaveButton from '../../../../components/form/SaveButton'
import ClientComponent from '@components/common/ClientComponent'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import {getTenantData} from '@app/domains/system/tenant/service'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

const defaultOpeningSchedules = {
  active: false,
  show_clock: false,
  start_weekday: 1,
  start_hour: '',
  end_weekday: 5,
  end_hour: '',
  days_ahead_to_schedule: 30,
  custom_days_ahead_to_schedule: 0
}

export default {
  components: {
    GripoDialog,
    FormSaveButton,
    ClientComponent
  },
  mixins: [helpers],
  data() {
    return {
      isSubmitted: false,
      isLoadingData: true,
      isMainDialogVisible: false,
      list: [],
      form: {
        tenant_schedule: {
          auto_scheduled_fixed: false,
          payment: {
            control_players: false
          }
        },
        app_schedule: {
          enabled: false,
          restricted: {
            enabled: false,
            token_id: '',
            token_hash: '',
          },
          restricted_clients: [],
          enable_lesson_schedule: false,
          minutes_to_enable_schedule: 0,
          cancel_fixed_enabled: false,
          cancel_enabled: true,
          minutes_to_cancel_schedule: 240,
          minutes_to_cancel_schedule_fixed: 0,
          schedule_confirmation: false,
          show_scheduled_item: false,
          show_client_name: false,
          max_schedule_client: 3,
          multiple_scheduled: false,
          multiple_scheduled_max_number: 2,
          intervals_combination: [],
          intervals_combination_start_hour: false,
          opening_schedules: {...defaultOpeningSchedules},
          messages: {
            notes: '',
            created: '',
            confirmed: '',
            canceled: ''
          },
          payment: {
            enabled: false,
            minutes_to_pay_schedule: 0,
            automatic_refund_enabled: false,
            lesson_enabled: false,
            lesson_minutes_to_pay_schedule: 0,
            lesson_automatic_refund_enabled: false,
          },
          contact: {
            phone: '',
            email: '',
          },
          notify: {
            whatsapp: {
              store: true,
              cancel: true,
              payment_approved: true,
            }
          }
        },
        client_id: ''
      },
      club_slug: '',
      enableCustomAheadDays: false,
      editorConfig: {
        config: {
          height: 150
        },
        toolbar1: 'code | bold italic | numlist bullist | removeformat'
      },
      selectedClientId: null,
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    paymentAccount() {
      return (this.tenant?.has_payment_account ?? false)
    },
    clubLink() {
      let link = `${process.env.VUE_APP_APP_URL}/reservar/${this.club_slug}`
      if (this.form.app_schedule.restricted?.enabled && this.form.app_schedule?.restricted?.token_hash) {
        link += `--${this.form.app_schedule.restricted.token_hash}`
      }
      return link
    },
    disablePayRefundSwitch() {
      return this.form.app_schedule.payment.enabled === false
    }
  },
  watch: {
    'form.app_schedule.opening_schedules.days_ahead_to_schedule'(value) {
      this.enableCustomAheadDays = value === 0
    },
    isMainDialogVisible(value) {
      bus.$emit('schedule-config-dialog-change', value)
    },
    'form.app_schedule.cancel_enabled'(value) {
      if (value === false) {
        this.form.app_schedule.payment.automatic_refund_enabled = false
      }
    },
    'form.app_schedule.payment.enabled'(value) {
      if (value === false) {
        this.form.app_schedule.payment.automatic_refund_enabled = false
      }
    },
    'form.app_schedule.restricted.enabled'() {
      if (!this.isLoadingData) {
        this.handleRestrictedLink()
      }
    },
  },
  mounted() {
    this.isMainDialogVisible = true
    bus.$emit('show-loader')
    this.getTenantData()
    this.getScheduleConfig()
  },
  methods: {
    async getTenantData() {
      const result = await getTenantData()
      if (result) {
        this.club_slug = result.slug
      }
    },
    getScheduleConfig() {
      bus.$emit('show-loader')
      restfulService
          .get('tenant', 'settings')
          .then(response => {
            if (!response.app_schedule.opening_schedules || response.app_schedule.opening_schedules === null
            ) {
              response.app_schedule.opening_schedules = {...this.form.app_schedule.opening_schedules}
            }
            if (!response.app_schedule.messages || response.app_schedule.messages === null
            ) {
              response.app_schedule.messages = {...this.form.app_schedule.messages}
            }
            if (!response.app_schedule.payment || response.app_schedule.payment === null
            ) {
              response.app_schedule.payment = {...this.form.app_schedule.payment}
            }
            if (!response.tenant_schedule || response.tenant_schedule === null
            ) {
              response.tenant_schedule = {...this.form.tenant_schedule}
            }
            if (response.app_schedule.cancel_enabled === null || response.app_schedule.cancel_enabled === undefined) {
              response.app_schedule.cancel_enabled = this.form.app_schedule.cancel_enabled
            }
            if (response.app_schedule.payment.automatic_refund_enabled === null || response.app_schedule.payment.automatic_refund_enabled === undefined) {
              response.app_schedule.payment.automatic_refund_enabled = this.form.app_schedule.payment.automatic_refund_enabled
            }
            if (response.app_schedule.cancel_fixed_enabled === null || response.app_schedule.cancel_fixed_enabled === undefined) {
              response.app_schedule.cancel_fixed_enabled = this.form.app_schedule.cancel_fixed_enabled
            }
            if (response.app_schedule.minutes_to_cancel_schedule_fixed === null || response.app_schedule.minutes_to_cancel_schedule_fixed === undefined) {
              response.app_schedule.minutes_to_cancel_schedule_fixed = this.form.app_schedule.minutes_to_cancel_schedule_fixed
            }
            if (!response.app_schedule.multiple_scheduled_max_number) {
              response.app_schedule.multiple_scheduled_max_number = this.form.app_schedule.multiple_scheduled_max_number
            }
            if (!response.app_schedule.intervals_combination) {
              response.app_schedule.intervals_combination = this.form.app_schedule.intervals_combination
            }
            if (!response.app_schedule.intervals_combination_start_hour) {
              response.app_schedule.intervals_combination_start_hour = this.form.app_schedule.intervals_combination_start_hour
            }
            if (!response.app_schedule.contact) {
              response.app_schedule.contact = {...this.form.app_schedule.contact}
            }
            if (!response.app_schedule.notify) {
              response.app_schedule.notify = {...this.form.app_schedule.notify}
            }
            if (!response.app_schedule.restricted || response.app_schedule.restricted === null) {
              response.app_schedule.restricted = {...this.form.app_schedule.restricted}
            }
            if (!response.app_schedule.restricted_clients || response.app_schedule.restricted_clients === null) {
              response.app_schedule.restricted_clients = this.form.app_schedule.restricted_clients
            }
            this.form = {...this.form, ...response}
            setTimeout(() => {
              this.isLoadingData = false
              bus.$emit('hide-loader')
            }, 300)
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    },
    onSelectClient(client) {
      if (client?.id) {
        this.form.app_schedule.restricted_clients.push({
          id: client.id,
          email: client.email,
          phone: client.phone,
        })
      } else {
        this.form.app_schedule.restricted_clients.push(client)
      }
      setTimeout(() => {
        this.$refs.addClientComponent.reset()
      }, 100)
    },
    onRemoveClient(index) {
      this.form.app_schedule.restricted_clients.splice(index, 1)
    },
    prepareSaveData() {
      const data = {...this.form}
      delete data.client_id
      if (this.enableCustomAheadDays) {
        data.app_schedule.opening_schedules.days_ahead_to_schedule = this.form.app_schedule.opening_schedules.custom_days_ahead_to_schedule
      }
      const openingSchedulesActive = data?.app_schedule.opening_schedules?.active ?? false
      if (!openingSchedulesActive) {
        data.app_schedule.opening_schedules = {...defaultOpeningSchedules}
      }
      return data
    },
    onSend(close) {
      close = close || true
      bus.$emit('show-loader')
      this.isSubmitted = true
      const scheduleConfig = this.prepareSaveData()
      const data = {
        items: [
          {
            type: 'app_schedule',
            data: {...scheduleConfig.app_schedule}
          }
        ]
      }
      this.isEdit = true
      restfulService
          .post('tenant', 'settings', null, data)
          .then(() => {
            bus.$emit('reload-session-info')
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            bus.$emit('hide-loader')
            if (close) {
              this.$emit('close')
            }
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
            bus.$emit('hide-loader')
            if (close) {
              this.$emit('close')
            }
          })
    },
    handleRestrictedLink() {
      bus.$emit('show-loader')
      const data = this.prepareSaveData()
      restfulService
          .post('tenant', 'handle-schedule-link', null, data)
          .then(({token_id, token_hash}) => {
            notifyService.success({
              hideLoader: true
            })
            this.form.app_schedule.restricted.token_id = token_id
            this.form.app_schedule.restricted.token_hash = token_hash
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.form.app_schedule.restricted.enabled = false
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    copyText(ref) {
      const codeToCopy = this.$refs[ref]
      navigator.clipboard.writeText(codeToCopy.value)
      this.$notify({
        title: 'Link copiado!',
        type: 'success',
        duration: 1000,
        position: 'bottom-right'
      })
    },
  }
}
</script>

<template>
  <div>
    <gripo-dialog :model.sync="isMainDialogVisible" width="90vw" top="1vh">
      <template slot="header">Configurações do Agendamento Online</template>
      <template slot="content">
        <el-form ref="form" :model="form" label-position="top">
          <v-container grid-list-lg class="-full">
            <v-layout row wrap>
              <v-flex md4 xs12>
                <div class="schedule-info-box -content-left">
                  <div style="width: 100%;">
                    <h3>Configurações Gerais</h3>
                    <div class="highlight-card -payment mb-2">
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <el-form-item label="Agendamento Online"
                                        class="mb-3 text-center">
                            <el-switch v-model="form.app_schedule.enabled"
                                       class="mt-2"
                                       active-text="Ativo"
                                       inactive-text="Inativo"></el-switch>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <div
                              title="Copiar Link"
                              @click="copyText('registrationUrl')">
                            <el-form-item label="Link para Reservar"
                                          class="copy-url mb-2 pt-0 text-center"
                                          v-if="form.app_schedule.enabled">
                              <input type="text" ref="registrationUrl" :value="clubLink">
                              <span class="icon -button">
                                <span class="mr-2">Copiar Link</span>
                                <i class="far fa-copy"></i>
                              </span>
                            </el-form-item>
                          </div>
                        </el-col>
                      </el-row>
                      <el-form-item label="Acesso Exclusivo"
                                    class="mb-3 text-center">
                        <el-switch v-model="form.app_schedule.restricted.enabled"
                                   class="mt-2"
                                   active-text="Sim"
                                   inactive-text="Não"></el-switch>
                      </el-form-item>
                      <el-alert type="info"
                                class="pt-2 pb-2 mb-2"
                                show-icon
                                :closable="false">
                        <div class="fs-14">
                          <div>Com acesso exclusivo habilitado o clube não estará visível no https://gripo.app para
                            reservas.
                          </div>
                          <div class="font-weight-bold">As reservas só poderão ser feitas utilizando o link acima</div>
                        </div>
                      </el-alert>
                    </div>
                    <div :class="{'disabled': !form.app_schedule.enabled}">
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <el-form-item label="Ativar Confirmação de Reserva" class="mb-4">
                            <el-switch
                                class="mt-2"
                                v-model="form.app_schedule.schedule_confirmation"
                                active-text="Sim"
                                inactive-text="Não"
                            ></el-switch>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Habilitar Reserva de Aulas">
                            <el-switch v-model="form.app_schedule.enable_lesson_schedule"
                                       class="mt-2"
                                       active-text="Sim"
                                       inactive-text="Não"/>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <el-form-item label="Mostrar Horários Reservados">
                            <el-switch
                                v-model="form.app_schedule.show_scheduled_item"
                                active-text="Sim"
                                inactive-text="Não"
                            ></el-switch>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Mostrar Nome do Cliente na Reserva" class="mb-4">
                            <el-switch v-model="form.app_schedule.show_client_name"
                                       :disabled="!form.app_schedule.show_scheduled_item"
                                       active-text="Sim"
                                       inactive-text="Não"
                            ></el-switch>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <el-form-item
                              label="Nº de Minutos para Reservar antes do Horário">
                            <el-input
                                type="number"
                                :min="0"
                                :step="1"
                                v-model="form.app_schedule.minutes_to_enable_schedule"
                            />
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Nº Máximo de Reservas Diárias por Cliente"
                                        class="mb-0">
                            <el-input-number
                                v-model="form.app_schedule.max_schedule_client"
                                :min="0"
                                :step="1"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>

                  </div>
                </div>
                <div class="schedule-info-box -content-left mt-3"
                     :class="{'disabled': !form.app_schedule.enabled}">
                  <div style="width: 100%;">
                    <h3>Habilitar Cancelamento via App</h3>
                    <el-row :gutter="10">
                      <el-col :span="12">
                        <el-form-item label="Reserva Avulsa" class="mb-4">
                          <el-switch v-model="form.app_schedule.cancel_enabled"
                                     active-text="Sim"
                                     inactive-text="Não"
                          ></el-switch>
                        </el-form-item>
                        <el-form-item
                            label="Nº de Minutos para Cancelar a Reserva"
                            class="mb-4">
                          <el-input
                              type="number"
                              :min="0"
                              :step="1"
                              :disabled="!form.app_schedule.cancel_enabled"
                              v-model="form.app_schedule.minutes_to_cancel_schedule"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Reserva Fixa" class="mb-4">
                          <el-switch v-model="form.app_schedule.cancel_fixed_enabled"
                                     active-text="Sim"
                                     inactive-text="Não"
                          ></el-switch>
                        </el-form-item>
                        <el-form-item
                            label="Nº de Minutos para Cancelar a Reserva"
                            class="mb-4">
                          <el-input
                              type="number"
                              :min="0"
                              :step="1"
                              :disabled="!form.app_schedule.cancel_enabled"
                              v-model="form.app_schedule.minutes_to_cancel_schedule_fixed"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="schedule-info-box -content-left mt-3"
                     :class="{'disabled': !form.app_schedule.enabled}">
                  <div style="width: 100%;">
                    <h3 class="mb-1">Restrição de Clientes</h3>
                    <p>Utilize essa opção para bloquear clientes das reservas online</p>
                    <client-component :client-id.sync="selectedClientId"
                                      ref="addClientComponent"
                                      @selectClient="onSelectClient"
                                      placeholder="Buscar Clientes (Nome, Email, Telefone)"
                                      empty-title="Digite sua busca..."
                                      :create-with-empty-data="true"
                                      create-new-client-text=""/>
                    <div class="base-list mt-2">
                      <div class="item" v-for="(client, index) in form.app_schedule.restricted_clients">
                        <div class="flex-c" :key="index">
                          <div>
                            <template v-if="client.id">
                              <div v-if="client.email">{{ client.email }}</div>
                              <div v-if="client.phone">{{ client.phone }}</div>
                            </template>
                            <template v-else>{{ client }}</template>
                          </div>
                          <div>
                            <el-button size="mini" circle
                                       class="el-button--ultra-small"
                                       type="danger"
                                       title="Remover Cliente"
                                       @click="onRemoveClient(index)">
                              <i class="fas fa-times"></i>
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="schedule-info-box -content-left mt-3"
                     :class="{'disabled': !form.app_schedule.enabled}">
                  <div style="width: 100%;">
                    <h3>Habilitar Reservas Múltiplas</h3>
                    <el-form-item>
                      <el-switch v-model="form.app_schedule.multiple_scheduled"
                                 active-text="Sim"
                                 inactive-text="Não"
                      ></el-switch>
                    </el-form-item>
                    <template v-if="form.app_schedule.multiple_scheduled">
                      <el-form-item label="Combinações de Horário Globais">
                        <el-select v-model="form.app_schedule.intervals_combination"
                                   multiple
                                   allow-create
                                   default-first-option
                                   filterable
                                   placeholder="Combinações"
                                   class="el-select-full"/>
                      </el-form-item>
                      <el-form-item v-if="false" label="Usar Hora de Início como Referência">
                        <el-switch v-model="form.app_schedule.intervals_combination_start_hour"
                                   active-text="Sim"
                                   inactive-text="Não"
                        ></el-switch>
                      </el-form-item>
                    </template>
                  </div>
                </div>
              </v-flex>
              <v-flex md8 xs12>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <div class="schedule-info-box -content-left mb-3"
                         :class="{'disabled': !form.app_schedule.enabled}">
                      <div>
                        <h3>Dados de Contato e Mensagens</h3>
                        <el-form-item label="Telefone">
                          <el-input v-model="form.app_schedule.contact.phone"/>
                        </el-form-item>
                        <el-form-item label="E-mail">
                          <el-input v-model="form.app_schedule.contact.email"/>
                        </el-form-item>
                        <el-form-item label="Mensagem Customizada">
                          <textarea class="textarea-custom" v-model="form.app_schedule.messages.notes" rows="4"/>
                        </el-form-item>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <el-alert type="warning"
                              class="pt-3 pb-3 mb-3"
                              show-icon
                              :closable="false">
                      <div slot="title" class="fs-14">
                        Utilize essas configurações para definir quando as mensagens
                        automáticas devem ser enviadas para os atletas nas ações realizar no app!
                      </div>
                    </el-alert>
                    <div class="schedule-info-box -content-left mb-3"
                         :class="{'disabled': !form.app_schedule.enabled}">
                      <div>
                        <h3>Configuração das Mensagens do Whatsapp</h3>
                        <el-form-item label="Reserva de Horário/Aula">
                          <el-switch v-model="form.app_schedule.notify.whatsapp.store"
                                     active-text="Sim"
                                     inactive-text="Não"
                          ></el-switch>
                        </el-form-item>
                        <el-form-item label="Aprovação do Pagamento" v-if="form.app_schedule.payment.enabled">
                          <el-switch v-model="form.app_schedule.notify.whatsapp.payment_approved"
                                     active-text="Sim"
                                     inactive-text="Não"
                          ></el-switch>
                        </el-form-item>
                        <el-form-item label="Cancelamento de Horário"
                                      v-if="form.app_schedule.cancel_enabled || form.app_schedule.cancel_fixed_enabled">
                          <el-switch v-model="form.app_schedule.notify.whatsapp.cancel"
                                     active-text="Sim"
                                     inactive-text="Não"
                          ></el-switch>
                        </el-form-item>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-alert type="warning"
                          class="pt-3 pb-3 mb-3"
                          show-icon
                          :closable="false"
                          v-if="!paymentAccount">
                  <div slot="title" class="fs-16">
                    Para habilitar o pagamento online cadastre uma conta de pagamentos
                  </div>
                  <div class="fs-14">
                    Acesse o Menu: Sistema -> Meu Clube -> Configurações
                    <div>
                      ou
                      <router-link
                          class="el-tag el-tag--mini el-tag--primary"
                          :to="{name: 'tenant_settings.show', params: {tab: 'config'}}">
                        Clique aqui
                      </router-link>
                    </div>
                  </div>
                </el-alert>
                <div v-else class="schedule-info-box -content-left mb-3"
                     :class="{'disabled': !form.app_schedule.enabled}">
                  <div>
                    <h3>Pagamento Online</h3>
                    <el-row :gutter="10">
                      <el-col :span="12">
                        <el-form-item label="Pagamento Online de Reservas">
                          <el-switch v-model="form.app_schedule.payment.enabled"
                                     active-text="Sim"
                                     inactive-text="Não"
                          ></el-switch>
                        </el-form-item>
                        <el-form-item label="Estorno Automático ao Cancelar a Reserva"
                                      class="mb-4">
                          <el-switch :disabled="disablePayRefundSwitch"
                                     v-model="form.app_schedule.payment.automatic_refund_enabled"
                                     active-text="Sim"
                                     inactive-text="Não">
                          </el-switch>
                        </el-form-item>
                        <el-form-item
                            label="Nº de Minutos para Pagamento da Reserva"
                            class="mb-4">
                          <el-input
                              type="number"
                              :min="0"
                              :step="1"
                              v-model="form.app_schedule.payment.minutes_to_pay_schedule"
                          />
                          <div class="el-form-item-info">
                            Após X minutos o sistema irá cancelar a reserva caso não for paga
                          </div>
                          <div class="el-form-item-info text-left">Use 0 para não ativar essa opção</div>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" v-if="form.app_schedule.enable_lesson_schedule">
                        <el-form-item label="Pagamento Online de Aulas">
                          <el-switch v-model="form.app_schedule.payment.lesson_enabled"
                                     active-text="Sim"
                                     inactive-text="Não"
                          ></el-switch>
                        </el-form-item>
                        <el-form-item label="Estorno Automático ao Cancelar a Aula"
                                      class="mb-4">
                          <el-switch :disabled="disablePayRefundSwitch"
                                     v-model="form.app_schedule.payment.lesson_automatic_refund_enabled"
                                     active-text="Sim"
                                     inactive-text="Não">
                          </el-switch>
                        </el-form-item>
                        <el-form-item
                            label="Nº de Minutos para Pagamento da Aula"
                            class="mb-4">
                          <el-input
                              type="number"
                              :min="0"
                              :step="1"
                              v-model="form.app_schedule.payment.lesson_minutes_to_pay_schedule"
                          />
                          <div class="el-form-item-info">
                            Após X minutos o sistema irá cancelar a aula caso não for paga
                          </div>
                          <div class="el-form-item-info text-left">Use 0 para não ativar essa opção</div>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="schedule-info-box -content-left"
                     :class="{'disabled': !form.app_schedule.enabled}">
                  <div>
                    <h3>Restrições nas Reservas</h3>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Habilitar Restrições"
                                      class="is-required">
                          <el-switch
                              v-model="form.app_schedule.opening_schedules.active"
                              active-text="Sim"
                              inactive-text="Não"
                          ></el-switch>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Mostrar Relógio">
                          <el-switch
                              v-model="form.app_schedule.opening_schedules.show_clock"
                              :disabled="!form.app_schedule.opening_schedules.active"
                              active-text="Sim"
                              inactive-text="Não"
                          ></el-switch>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Dia da Semana">
                          <el-select
                              v-model="form.app_schedule.opening_schedules.start_weekday"
                              :disabled="!form.app_schedule.opening_schedules.active"
                              placeholder="Selecione um dia"
                              empty-text="Nenhum registro encontrado"
                          >
                            <el-option label="Todos" :value="null"></el-option>
                            <el-option label="Segunda" :value="1"></el-option>
                            <el-option label="Terça" :value="2"></el-option>
                            <el-option label="Quarta" :value="3"></el-option>
                            <el-option label="Quinta" :value="4"></el-option>
                            <el-option label="Sexta" :value="5"></el-option>
                            <el-option label="Sábado" :value="6"></el-option>
                            <el-option label="Domingo" :value="0"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Hora">
                          <el-input
                              placeholder="Ex.: 08:00"
                              v-mask="'##:##'"
                              :disabled="!form.app_schedule.opening_schedules.active"
                              v-model="form.app_schedule.opening_schedules.start_hour"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Nº de Dias da Janela de Reservas">
                          <el-select
                              v-model="form.app_schedule.opening_schedules.days_ahead_to_schedule"
                              placeholder="Selecione um período"
                              :disabled="!form.app_schedule.opening_schedules.active"
                              empty-text="Nenhum registro encontrado"
                          >
                            <el-option label="7 dias" :value="7"></el-option>
                            <el-option label="15 dias" :value="15"></el-option>
                            <el-option label="1 mês" :value="30"></el-option>
                            <el-option label="45 dias" :value="45"></el-option>
                            <el-option label="2 meses" :value="60"></el-option>
                            <el-option label="Customizado" :value="0"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" v-if="this.enableCustomAheadDays">
                        <el-form-item label="Informe um nº em dias">
                          <el-input
                              type="number"
                              v-model="form.app_schedule.opening_schedules.custom_days_ahead_to_schedule"
                              :min="0"
                              :step="1"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </el-form>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
        <form-save-button
            :event-click="onSend"
            :is-disabled="isSubmitted"
            :is-submitted="isSubmitted"
        ></form-save-button>
      </template>
    </gripo-dialog>
  </div>
</template>

<style lang="scss">
.schedule-config-modal {
  .v-tabs__bar {
    background-color: transparent;
  }
}

.-has-schedule-fixed {
  background: #376295;

  span {
    color: #ffffff !important;
  }
}
</style>