import Swal from 'sweetalert2'

/**
 * @param {Object} config
 * @param {string} config.title
 * @param {string} config.icon
 * @param {string} [config.text]
 * @param {string} [config.html]
 * @returns {Promise<SweetAlertResult<Awaited<any>>>}
 */
async function swalFire(config) {
    return await Swal.fire(config)
}

/**
 *
 * @param config
 * @returns {Promise<SweetAlertResult<Awaited<any>>>}
 */
export async function alertDialog(config) {
    return await swalFire(config)
}

/**
 *
 * @param config
 * @returns {Promise<SweetAlertResult<Awaited<any>>>}
 */
export async function successDialog(config) {
    config = {
        title: 'Sucesso!',
        icon: 'success',
        ...config
    }
    return await swalFire(config)
}

/**
 *
 * @param config
 * @returns {Promise<SweetAlertResult<Awaited<any>>>}
 */
export async function warningDialog(config) {
    config = {
        title: 'Atenção!',
        icon: 'warning',
        ...config
    }
    return await swalFire(config)
}

/**
 *
 * @param config
 * @returns {Promise<SweetAlertResult<Awaited<any>>>}
 */
export async function errorDialog(config) {
    config = {
        title: 'Aconteceu um erro!',
        icon: 'error',
        ...config
    }
    return await swalFire(config)
}

/**
 *
 * @param config
 * @returns {Promise<any>}
 */
export async function confirmDialog(config) {
    config = {
        title: 'Deseja realmente realizar essa ação?',
        text: 'Essa ação não pode ser revertida!',
        icon: 'error',
        confirmButtonColor: '#716ACA',
        confirmButtonText: '<i class="fas fa-check"></i> Sim!',
        showCancelButton: true,
        cancelButtonText: 'Não',
        ...config
    }
    return await Swal.fire(config)
}
