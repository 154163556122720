<script>
import GripoDialog from '@components/common/Dialog.vue'

import * as restfulService from '@app/services/restfulService'
import {newInstance, insertScript} from '@app/services/faceRecon'

import helpers from '@mixins/helpers'
import {mapGetters} from 'vuex'
import bus from '@utils/bus'

export default {
  name: 'client-facerecon',
  components: {GripoDialog},
  mixins: [helpers],
  props: {
    clientId: {
      type: Number,
      default: null,
    },
    hasUser: {
      type: Boolean,
      default: false,
    },
    hasFace: {
      type: Boolean,
      default: false,
    },
    getClient: {
      type: Function,
    }
  },
  data() {
    return {
      isSubmitted: false,
      faceRecon: null,
      isFaceReconDialogVisible: false,
      hasError: false,
      faceReconWidth: this.$vuetify.breakpoint.xs ? '80%' : '550px',
    }
  },
  computed: {
    ...mapGetters(['hasFaceReconModule']),
    faceReconDisabled() {
      return !this.hasFaceReconModule || !this.hasUser
    }
  },
  async mounted() {
    insertScript()
  },
  watch: {
    isFaceReconDialogVisible(value) {
      if (!value) {
        if (this.faceRecon) {
          this.faceRecon.destroy()
          this.faceRecon = null
        }
        this.isSubmitted = false
      }
    }
  },
  methods: {
    faceReconRun() {
      bus.$emit('show-loader')
      if (!this.isFaceReconDialogVisible) {
        this.isFaceReconDialogVisible = true
      }
      setTimeout(() => {
        if (!this.faceRecon) {
          this.faceRecon = newInstance({
            onDetection: (img) => this.faceReconComplete(img),
            element: this.$refs.facerecon,
          })
        }
        setTimeout(() => {
          this.faceRecon.run()
          bus.$emit('hide-loader')
        }, 300)
      }, 300)
    },
    async faceReconComplete(faceBase64) {
      bus.$emit('show-loader')
      this.hasError = false
      this.isSubmitted = true
      const data = {
        base64_image: faceBase64,
        client_id: this.clientId,
      }
      restfulService.post('client', 'facerecon-store', null, data)
          .then(() => {
            this.isFaceReconDialogVisible = false
            this.isSubmitted = false
            this.getClient(this.clientId)
          })
          .catch(e => {
            this.faceReconRun()
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <el-card class="el-card__save_form box-card">
      <div slot="header">
        <span class="mr-1">Reconhecimento Facial</span>
        <el-tag type="danger" size="mini" v-if="faceReconDisabled">Desabilitado</el-tag>
      </div>
      <el-alert type="error"
                show-icon
                :closable="false"
                v-if="faceReconDisabled && !hasFaceReconModule">
        <div slot="title">
          <div v-if="!hasFaceReconModule">
            Entre em contato com o suporte para saber mais informações sobre como habilitar o reconhecimento facial
          </div>
        </div>
      </el-alert>
      <el-alert type="error"
                show-icon
                :closable="false"
                v-if="false && hasFaceReconModule && !hasUser">
        <div slot="title">
          <div>
            O cliente precisa ter um usuário vinculado/cadastrado para ativar o reconhecimento facial
          </div>
        </div>
      </el-alert>
      <el-alert type="success"
                show-icon
                :closable="false"
                v-else-if="hasFace">
        <div slot="title">
          Esse cliente já possui o reconhecimento facial habilitado
        </div>
      </el-alert>
      <div class="text-center" v-else-if="!hasFace">
        <el-button type="success"
                   :disabled="isSubmitted"
                   @click="faceReconRun">
          Iniciar Reconhecimento Facial
        </el-button>
      </div>
    </el-card>
    <gripo-dialog :model.sync="isFaceReconDialogVisible"
                  :width="$vuetify.breakpoint.xs ? '80vw' : '50vw'">
      <template slot="header">
        Ative a câmera e de permissão prosseguir com a captura da face
      </template>
      <template slot="content">
        <div
            style="width: 100%; display: flex; justify-content: center; align-content: center; min-height: 350px;">
          <div ref="facerecon"
               :style="`width: ${faceReconWidth};`"/>
        </div>
      </template>
    </gripo-dialog>
  </div>
</template>
