<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import ListDeleteButton from "../../../../components/list/DeleteButton";
import StatusLabel from '../../../../components/common/StatusLabel'
import PlanIntervalLabel from './labels/PlanIntervalLabel'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import DataService from '@app/services/dataService'
import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'
import {confirmDialog} from '@utils/flash'

const searchData = {status: 'active'}

export default {
  components: {ListDeleteButton, ListMain, ListHeader, ListEditButton, StatusLabel, PlanIntervalLabel},
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      filters: {
        name: '',
        status: 'active',
      },
      dependencies: {
        plan_status: [],
      }
    }
  },
  mounted() {
    this.getDependencies()
    bus.$emit('list-init', {domain: 'plan', relations: ['active_clients'], data: {...searchData}}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'plan_status'}]).then((result) => {
        this.dependencies = {...result}
      })
    },
    onFilter() {
      const searchData = {}
      if (this.filters.name) {
        searchData.name = {
          'like': '%' + this.filters.name + '%'
        }
      }
      if (this.filters.status !== 'all') {
        searchData.status = this.filters.status
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {...searchData}
      bus.$emit('list-filter-clean', searchData, this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    activatePlan(item) {
      bus.$emit('show-loader')
      this.isSubmitted = true
      restfulService.post('plan', 'activate', item.id, {})
          .then(() => {
            notifyService.success()
            this.onFilter()
            this.isSubmitted = false
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async disabledPlan(plan) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente desativar esse plano?',
      })
      if (confirm) {
        this.isSubmitted = true
        bus.$emit('show-loader')
        restfulService.post('plan', 'disable', plan.id, {})
            .then(() => {
              notifyService.success()
              this.onFilter()
              this.isSubmitted = false
            })
            .catch(e => {
              this.isSubmitted = false
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Planos"
                route-name="plan.store"
                store-title="Criar Plano/Pacote"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         filterable
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option label="Todos" value="all"/>
                <el-option v-for="item in dependencies.plan_status"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Cor</th>
            <th>Renovação Auto.</th>
            <th>Valor</th>
            <th>Status</th>
            <th>Clientes Ativos</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>
              <div v-if="item.bg_color" class="color-label -square -small"
                   :style="`background-color: ${item.bg_color}`"></div>
              <template v-else>-</template>
            </td>
            <td>
              <plan-interval-label :interval="item.interval"
                                   :interval-type="item.interval_type"
                                   v-if="item.recurrent"/>
              <span v-else>-</span>
            </td>
            <td>{{ item.amount | currencyFormatter }}</td>
            <td>
              <status-label :status="item.status"></status-label>
            </td>
            <td>{{ item.active_clients.length }}</td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button type="default"
                           size="small"
                           v-if="item.status === 'created' || item.status === 'active'"
                           @click="disabledPlan(item)"
                           :disabled="isSubmitted"
                           title="Desativar Plano">
                  <i class="far fa-eye-slash"></i>
                </el-button>
                <el-button type="success"
                           v-if="item.status === 'disabled'"
                           title="Ativar Plano"
                           :disabled="isSubmitted"
                           @click="activatePlan(item)">
                  <i class="fas fa-check"></i>
                </el-button>
                <router-link :to="{ name: 'plan.show', params: { id: item.id } }"
                             v-if="item.status === 'active'"
                             class="el-button el-button--default btn-actions-text"
                             title="Gerenciar Plano">
                  <i class="fas fa-cog"></i>
                  <span class="ml-1">Gerenciar</span>
                </router-link>
                <ListEditButton route-name="plan.update"
                                :item="item"/>
                <ListDeleteButton :item="item" domain="plan" />
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
