export default [
    {
        icon: 'fas fa-sun',
        text: 'GESTÃO',
        index: '1',
        children: [
            {
                icon: 'fas fa-tachometer-alt',
                text: 'Dashboard',
                moduleName: 'management',
                route: {
                    name: 'dashboard.index',
                    index: 'dashboard',
                    params: {}
                }
            },
            {
                icon: 'fas fa-warehouse',
                text: 'Comandas',
                moduleName: 'management',
                route: {
                    name: 'order.daily.index',
                    index: 'order.daily',
                    params: {}
                }
            },
            {
                icon: 'far fa-calendar-alt',
                text: 'Grade de Horários',
                moduleName: 'management',
                route: {
                    name: 'schedule_daily.index',
                    index: 'schedule_daily',
                    params: {}
                }
            },
            {
                icon: 'far fa-clock',
                menuText: 'Rel. Reservas',
                text: 'Relatório de Reservas',
                moduleName: 'management',
                route: {
                    name: 'schedule.index',
                    index: 'schedule',
                    params: {}
                }
            },
            {
                icon: 'fas fa-users',
                text: 'Grupos de Atletas',
                moduleName: 'management',
                route: {
                    name: 'player_group.index',
                    index: 'player_group',
                    params: {}
                }
            },
            {
                icon: 'far fa-square',
                text: 'Quadras',
                moduleName: 'management',
                route: {
                    name: 'court.index',
                    index: 'court',
                    params: {}
                }
            },
            {
                icon: 'far fa-handshake',
                text: 'Clientes',
                moduleName: 'management',
                route: {
                    name: 'client.index',
                    index: 'client',
                    params: {}
                }
            },
            {
                icon: 'fas fa-user-tie',
                text: 'Planos e Pacotes',
                moduleName: 'management',
                route: {
                    name: 'plan.index',
                    index: 'plan',
                    params: {}
                }
            },
        ]
    },
    {
        icon: 'fas fa-chart-line',
        text: 'FINANCEIRO',
        index: '2',
        children: [
            {
                icon: 'fas fa-money-bill-alt',
                text: 'Contas a Receber',
                moduleName: 'financial',
                route: {
                    name: 'entry.revenue.index',
                    index: 'entry.revenue',
                    params: {}
                }
            },
            {
                icon: 'far fa-money-bill-alt',
                text: 'Contas a Pagar',
                moduleName: 'financial',
                route: {
                    name: 'entry.expense.index',
                    index: 'entry.expense',
                }
            },
            {
                icon: 'fas fa-file-invoice-dollar',
                text: 'Notas Fiscais',
                moduleName: 'financial',
                route: {
                    name: 'invoice.index',
                    index: 'invoice',
                }
            },
            {
                icon: 'fas fa-hand-holding-usd',
                text: 'Pagamentos Online',
                moduleName: 'financial',
                route: {
                    name: 'transaction.index',
                    index: 'transaction',
                }
            },
            {
                icon: 'fas fa-file-invoice-dollar',
                text: 'Pedidos POS',
                moduleName: 'financial',
                route: {
                    name: 'device_order.index',
                    index: 'device_order',
                }
            },
            {
                icon: 'far fa-building',
                text: 'Fornecedores',
                moduleName: 'management',
                route: {
                    name: 'supplier.index',
                    index: 'supplier',
                    params: {}
                }
            },
            {
                icon: 'fas fa-box',
                text: 'Produtos/Serviços',
                moduleName: 'financial',
                route: {
                    name: 'product.index',
                    index: 'product',
                    params: {}
                }
            },
            {
                icon: 'fas fa-th',
                text: 'Categ. dos Produtos',
                moduleName: 'financial',
                route: {
                    name: 'product_category.index',
                    index: 'product_category',
                    params: {}
                }
            },
            {
                icon: 'fab fa-connectdevelop',
                text: 'Classificação',
                moduleName: 'financial',
                route: {
                    name: 'entry_classification.index',
                    index: 'entry_classification',
                    params: {}
                }
            },
            {
                icon: 'fas fa-money-check',
                text: 'Formas de Pagamento',
                moduleName: 'financial',
                route: {
                    name: 'entry_payment_method.index',
                    index: 'entry_payment_method',
                    params: {}
                }
            },
            {
                icon: 'fas fa-university',
                text: 'Contas Bancárias',
                moduleName: 'financial',
                route: {
                    name: 'bank_account.index',
                    index: 'bank_account',
                    params: {}
                }
            },
            {
                icon: 'fas fa-cash-register',
                menuText: 'Rel. de Caixas',
                text: 'Relatório de Caixas',
                moduleName: 'financial',
                cssClass: '-highlight',
                route: {
                    name: 'entry_teller.list',
                    index: 'entry_teller',
                    params: {}
                }
            },
            {
                icon: 'fas fa-coins',
                menuText: 'Rel. de Lançamentos',
                text: 'Relatório de Lançamentos',
                moduleName: 'financial',
                cssClass: '-highlight',
                route: {
                    name: 'report.daily.index',
                    index: 'report.daily',
                    params: {}
                }
            },
            {
                icon: 'fas fa-box',
                menuText: 'Rel. de Produtos',
                text: 'Relatório de Produtos',
                moduleName: 'financial',
                cssClass: '-highlight',
                route: {
                    name: 'report.products.index',
                    index: 'report.products',
                    params: {}
                }
            },
            {
                icon: 'fas fa-layer-group',
                menuText: 'Mov. de Estoque',
                text: 'Movimentação de Estoque',
                moduleName: 'financial',
                cssClass: '-highlight',
                route: {
                    name: 'report.stock.index',
                    index: 'report.stock',
                    params: {}
                }
            },
        ]
    },
    {
        icon: 'fas fa-trophy',
        text: 'TORNEIOS',
        index: '3',
        children: [
            {
                icon: 'fas fa-file-alt',
                text: 'Circuitos',
                moduleName: 'tournament',
                route: {
                    name: 'circuit.index',
                    index: 'circuit',
                    params: {}
                }
            },
            {
                icon: 'fas fa-trophy',
                text: 'Torneios',
                moduleName: 'tournament',
                route: {
                    name: 'stage.index',
                    index: 'stage',
                    params: {}
                }
            },
        ]
    },
    {
        icon: 'fas fa-cogs',
        text: 'SISTEMA',
        index: '4',
        children: [
            {
                icon: 'fas fa-user',
                text: 'Usuários',
                moduleName: 'system',
                route: {
                    name: 'user.index',
                    index: 'user',
                    params: {}
                }
            },
            {
                icon: 'fas fa-id-card',
                text: 'Perfis de Usuário',
                moduleName: 'system',
                route: {
                    name: 'role.index',
                    index: 'role',
                    params: {}
                }
            },
            {
                icon: 'fas fa-suitcase',
                text: 'Meu Clube',
                moduleName: 'system',
                cssClass: '-highlight',
                route: {
                    name: 'tenant_settings.show',
                    index: 'tenant_settings',
                    params: {}
                }
            }
        ]
    }
]
