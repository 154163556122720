<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import ValidationMessage from '../../../../components/form/ValidationMessage'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import {required, requiredIf} from 'vuelidate/lib/validators'
import {clone, cloneDeep} from 'lodash'

import {defaultProduct, save} from '../service'
import GripoDialog from "../../../../components/common/Dialog.vue";

export default {
  components: {GripoDialog, ListHeader, FormSaveButton, FormCancelButton, ValidationMessage},
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      updatePrice: false,
      form: {
        ...cloneDeep(defaultProduct),
      },
      dependencies: {
        product_categories: [],
        nf_icms_origem: [],
        nf_icms_situacao_tributaria: [],
        product_tags: [],
        nfce_required_fields: []
      },
      validateNfFieldsData: {
        modalVisible: false,
        env: 'staging'
      },
    }
  },
  validations: {
    form: {
      category_id: {required},
      unit: {required},
      description: {required},
      purchase_price: {required},
      price: {required},
      code: {required},
      stock: {
        required: requiredIf(function () {
          return this.form.control_stock
        })
      },
      alert_minimum_stock: {
        required: requiredIf(function () {
          return this.form.control_stock
        })
      }
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasCommercialReceiptModule', 'hasCommercialInvoiceModule', 'hasProfessionalPlan', 'hasTotemModule']),
    moneyMask() {
      return currencyConfig()
    },
    nf_icms_situacao_tributaria_filtered() {
      return this.dependencies.nf_icms_situacao_tributaria.filter(item => item.simples_nacional === ((this.tenant.settings.tenant_nf?.nfce?.regime_tributario || 1) === 1))
    },
    nfseCityConfig() {
      return (this.tenant?.settings?.tenant_nf?.nfse?.city_config ?? null)
    },
    nfseCityConfigCodigoCnaeObrigatorioNfse() {
      return this.nfseCityConfig?.codigo_cnae_obrigatorio_nfse ?? false
    },
    nfseCityConfigItemListaServicoObrigatorioNfse() {
      return this.nfseCityConfig?.item_lista_servico_obrigatorio_nfse ?? true
    },
    nfseCityConfigCodigoTributarioMunicipioObrigatorioNfse() {
      return this.nfseCityConfig?.codigo_tributario_municipio_obrigatorio_nfse ?? false
    },
  },
  watch: {
    'form.nf_fields.icms_situacao_tributaria': function (value) {
      this.dependencies.nfce_required_fields.splice(0)
      if (this.form.type === 'product' && this.hasCommercialReceiptModule && value !== '' && value !== null) {
        this.getNfceRequiredFields()
      }
    }
  },
  async mounted() {
    if (!this.hasProfessionalPlan) {
      this.form.type = 'service'
      this.form.control_stock = false
    }
    this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      await this.loadProduct(id)
    } else {
      this.isLoadingData = false
    }
    bus.$emit('hide-loader')
  },
  methods: {
    getDependencies() {
      DataService.get([
        {domain: 'product_category'},
        {domain: 'nf_icms_situacao_tributaria'},
        {domain: 'nf_icms_origem'},
        {domain: 'product_tags'}
      ]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    loadProduct(id) {
      return new Promise((resolve, reject) => {
        this.isEdit = true
        restfulService.get('product', null, id, null, ['tags'])
            .then(response => {
              response.purchase_price = this.currencyFormatter(response.purchase_price)
              response.price = this.currencyFormatter(response.price)
              this.updatePrice = true
              if (response.tags.length) {
                response.tags = response.tags.map(item => item.tag)
              }
              let nfFields = {...this.form.nf_fields}
              if (response.nf_fields !== null) {
                nfFields = {...this.form.nf_fields, ...response.nf_fields}
              }
              if (response.nf_validation_data === null) {
                response.nf_validation_data = {...cloneDeep(this.form.nf_validation_data)}
              } else {
                response.nf_validation_data = {...cloneDeep(this.form.nf_validation_data), ...response.nf_validation_data}
              }
              this.form = {...this.form, ...response}
              this.form.nf_fields = {...nfFields}
              setTimeout(() => {
                this.updatePrice = false
                this.isLoadingData = false
                resolve()
              }, 100)
            })
            .catch(e => {
              console.log(e)
              this.isLoadingData = false
              reject(e)
            })
      })
    },
    onSend(controlLoader) {
      return new Promise((resolve, reject) => {
        if (controlLoader) {
          bus.$emit('show-loader')
        }
        this.isSubmitted = true
        const data = clone(this.form)
        save(data)
            .then(async (response) => {
              if (controlLoader) {
                notifyService.success()
              }
              await this.loadProduct(response.id)
              if (controlLoader) {
                bus.$emit('hide-loader')
              }
              resolve()
            })
            .catch(e => {
              reject(e)
              if (controlLoader) {
                bus.$emit('hide-loader')
              }
              console.log(e)
            })
            .finally(() => {
              this.isSubmitted = false
              if (controlLoader) {
                bus.$emit('hide-loader')
              }
              resolve()
            })
      })
    },
    getNfceRequiredFields() {
      bus.$emit('show-loader', 'Buscando campos obrigatórios')
      const data = {icms_situacao_tributaria: this.form.nf_fields.icms_situacao_tributaria}
      restfulService.post('product', 'nfce-required-fields', null, data)
          .then((response) => {
            this.dependencies.nfce_required_fields = [...response]
            this.dependencies.nfce_required_fields.map(item => {
              if (this.form.nf_fields[item] === undefined) {
                this.$set(this.form.nf_fields, item.field, null)
              }
            })
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    openValidateNfFields() {
      this.validateNfFieldsData.modalVisible = true
      this.validateNfFieldsData.env = 'staging'
    },
    async onValidateNfFields() {
      bus.$emit('show-loader', 'Validando produto...')
      await this.onSend(false)
      restfulService.post('product', 'validate-nf-fields', this.form.id, {
        env: this.validateNfFieldsData.env
      })
          .then((response) => {
            this.validateNfFieldsData.modalVisible = false
            this.form.nf_enabled = response.authorized
            if (response.authorized) {
              notifyService.success({title: response.title})
            } else {
              this.form.nf_validation_data.errors = response.errors
              notifyService.warning({title: response.title})
            }
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Produtos/Serviços"/>
    <el-form ref="form"
             :model="form"
             label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Dados do Produto/Serviço</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="12" v-if="hasProfessionalPlan">
                <el-form-item label="Tipo"
                              class="is-required">
                  <el-select v-model="form.type"
                             class="el-select-full">
                    <el-option label="Produto" value="product"/>
                    <el-option label="Serviço" value="service"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Categoria"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.category_id.$error }">
                  <el-select v-model="form.category_id"
                             filterable
                             clearable
                             placeholder="Selecione uma categoria"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full"
                             @input="$v.form.category_id.$touch()">
                    <el-option
                        v-for="item in dependencies.product_categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                  <validation-message v-if="$v.form.category_id.$error"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Tipo de Unidade"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.unit.$error }">
                  <el-select v-model="form.unit"
                             placeholder="Selecione um tipo"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full"
                             @input="$v.form.unit.$touch()">
                    <el-option label="Unidade" value="UN"/>
                    <el-option label="Quilos" value="KG"/>
                    <el-option label="Litros" value="L"/>
                  </el-select>
                  <validation-message v-if="$v.form.unit.$error"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Código Interno"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.code.$error }">
                  <el-input v-model="form.code"
                            @input="$v.form.code.$touch()"/>
                  <validation-message v-if="$v.form.code.$error"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Código de Barras">
                  <el-input v-model="form.barcode"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="Tags">
                  <el-select
                      v-model="form.tags"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      placeholder="Digite as tags"
                      empty-text="Nenhuma tag encontrada"
                      class="el-select-full">
                    <el-option
                        v-for="item in dependencies.product_tags"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="Ordem de Exibição">
                  <el-input type="number"
                            :min="0"
                            :step="1"
                            v-model="form.order"/>
                  <el-alert type="warning"
                            class="mt-2 pt-1 pr-1 pb-2 pl-1"
                            :closable="false">
                    <div>Campo usado na ordenação dos produtos ao criar uma comanda</div>
                  </el-alert>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Descrição"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.description.$error }">
              <el-input v-model="form.description"
                        type="textarea"
                        :rows="6"
                        @input="$v.form.description.$touch()"/>
              <validation-message v-if="$v.form.description.$error"/>
            </el-form-item>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Ativo?">
                  <el-switch v-model="form.active"
                             active-color="#13ce66"
                             :active-value="true"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             :inactive-value="false"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="hasProfessionalPlan">
                <el-form-item label="Aplicar Desconto de Planos e Pacotes?">
                  <el-switch v-model="form.use_plan_discount"
                             active-color="#13ce66"
                             :active-value="true"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             :inactive-value="false"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12" v-if="hasProfessionalPlan">
                <el-form-item label="Habilitar para Cozinha?">
                  <el-switch v-model="form.kitchen_enabled"
                             active-color="#13ce66"
                             :active-value="true"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             :inactive-value="false"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="hasTotemModule">
                <el-form-item label="Habilitar para Totem?">
                  <el-switch v-model="form.totem_enabled"
                             active-color="#13ce66"
                             :active-value="true"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             :inactive-value="false"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>{{ hasProfessionalPlan ? 'Preços e Estoque' : 'Preços' }}</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Preço de Compra"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.purchase_price.$error }">
                  <el-input v-model.lazy="form.purchase_price"
                            v-money="moneyMask"
                            v-if="!updatePrice"
                            @input="$v.form.purchase_price.$touch()"/>
                  <validation-message v-if="$v.form.purchase_price.$error"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Preço de Venda"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.price.$error }">
                  <el-input v-model.lazy="form.price"
                            v-money="moneyMask"
                            v-if="!updatePrice"
                            @input="$v.form.price.$touch()"/>
                  <validation-message v-if="$v.form.price.$error"/>
                </el-form-item>
              </el-col>
            </el-row>
            <template v-if="hasProfessionalPlan">
              <el-form-item label="Controlar Estoque?">
                <el-switch v-model="form.control_stock"
                           active-color="#13ce66"
                           :active-value="true"
                           active-text="SIM"
                           inactive-color="#ff4949"
                           :inactive-value="false"
                           inactive-text="NÃO">
                </el-switch>
              </el-form-item>
              <el-row :gutter="20" v-if="form.control_stock">
                <el-col :span="12">
                  <el-form-item label="Alerta de Estoque Mínimo"
                                class="is-required"
                                :class="{ 'el-form-item--error': $v.form.alert_minimum_stock.$error }">
                    <el-input-number v-model="form.alert_minimum_stock"
                                     :precision="form.unit === 'UN' ? 0 : 3"
                                     :step="form.unit === 'UN' ? 1 : 0.001"
                                     :min="0.001"
                                     @input="$v.form.alert_minimum_stock.$touch()"/>
                    <validation-message v-if="$v.form.alert_minimum_stock.$error"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="!isEdit">
                  <el-form-item label="Estoque"
                                class="is-required"
                                :class="{ 'el-form-item--error': $v.form.stock.$error }">
                    <el-input-number v-model="form.stock"
                                     :precision="form.unit === 'UN' ? 0 : 3"
                                     :step="form.unit === 'UN' ? 1 : 0.001"
                                     :min="0.001"
                                     @input="$v.form.stock.$touch()"/>
                    <validation-message v-if="$v.form.stock.$error"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </el-card>
          <el-card class="el-card__save_form box-card"
                   v-if="form.type === 'product' && hasCommercialReceiptModule">
            <div slot="header">
              <span>Nota de Produto (NFCe)</span>
              <el-tag type="success" size="mini" effect="dark" v-if="form.nf_enabled">Habilitado p/ NF</el-tag>
              <div v-else>
                <el-tag type="info" size="mini" effect="dark" class="mr-2">
                  Pendente
                </el-tag>
                <el-button size="mini" type="warning" @click="openValidateNfFields">
                  <i class="fas fa-check"></i>
                  <span class="ml-1">Validar Informações</span>
                </el-button>
              </div>
            </div>
            <el-alert type="error"
                      class="mb-4"
                      v-if="!form.nf_enabled && !!form.nf_validation_data?.errors"
                      show-icon
                      :closable="false">
              <div class="fs-14">{{ form.nf_validation_data.errors }}</div>
            </el-alert>
            <el-row :gutter="20">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Código NCM" class="is-required">
                    <el-input v-model="form.nf_fields['codigo_ncm']"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Código Fiscal da Operação (CFOP)" class="is-required">
                    <el-input v-model="form.nf_fields['cfop']"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="ICMS Origem" class="is-required">
                    <el-select v-model="form.nf_fields['icms_origem']"
                               clearable
                               filterable
                               placeholder="Selecione um ICMS Origem"
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full">
                      <el-option v-for="item in dependencies.nf_icms_origem"
                                 :key="item.id"
                                 :label="`(${item.id}) ${item.name}`"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="ICMS Situação Tributária (CST)" class="is-required">
                    <el-select v-model="form.nf_fields['icms_situacao_tributaria']"
                               clearable
                               filterable
                               placeholder="Selecione um Situação Tributária"
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full">
                      <el-option v-for="item in nf_icms_situacao_tributaria_filtered"
                                 :key="item.id"
                                 :label="`(${item.id}) ${item.name}`"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" v-if="['60', '500'].includes(form.nf_fields['icms_situacao_tributaria'])">
                <el-col :span="12">&nbsp;</el-col>
                <el-col :span="12">
                  <el-form-item label="Código Especificador da Substituição Tributária" class="is-required">
                    <el-input v-model="form.nf_fields['cest']"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <v-layout class="gap-20" wrap>
                <v-flex>
                  <el-form-item label="PIS Situação Tributária">
                    <el-input v-model="form.nf_fields['pis_situacao_tributaria']"/>
                  </el-form-item>
                </v-flex>
                <v-flex>
                  <el-form-item label="Cofins Situação Tributária">
                    <el-input v-model="form.nf_fields['cofins_situacao_tributaria']"/>
                  </el-form-item>
                </v-flex>
                <v-flex v-for="field in dependencies.nfce_required_fields" :key="field.field">
                  <el-form-item :label="field.name" class="is-required">
                    <el-input v-model="form.nf_fields[field.field]"/>
                  </el-form-item>
                </v-flex>
              </v-layout>
            </el-row>
            <el-alert type="warning"
                      :closable="false">
              <div class="fs-14">
                <div><strong>Campos Obrigatórios para Habilitar a Emissão de NFCe</strong></div>
                <ul>
                  <li>Código NCM</li>
                  <li>Código Fiscal da Operação (CFOP)</li>
                  <li>ICMS Origem</li>
                  <li>ICMS Situação Tributária (CST)</li>
                  <li v-for="field in dependencies.nfce_required_fields" :key="field.field">{{ field.name }}</li>
                </ul>
              </div>
            </el-alert>
          </el-card>
          <el-card class="el-card__save_form box-card"
                   v-if="form.type === 'service' && hasCommercialInvoiceModule">
            <div slot="header">
              <span>Nota de Serviço (NFSe)</span>
              <el-tag type="success" size="mini" effect="dark" v-if="form.nf_enabled">Habilitado p/ NF</el-tag>
            </div>
            <el-row :gutter="20">
              <el-row :gutter="20">
                <el-col :span="8"
                        v-if="nfseCityConfigCodigoCnaeObrigatorioNfse">
                  <el-form-item label="Código Cnae">
                    <el-input v-model="form.nf_fields['codigo_cnae']"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8"
                        v-if="nfseCityConfigItemListaServicoObrigatorioNfse">
                  <el-form-item label="Item da Lista de Serviço">
                    <el-input v-model="form.nf_fields['item_lista_servico']"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8"
                        v-if="nfseCityConfigCodigoTributarioMunicipioObrigatorioNfse">
                  <el-form-item label="Código Tributário do Município">
                    <el-input v-model="form.nf_fields['codigo_tributario_municipio']"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="ISS Retido">
                    <el-switch v-model="form.nf_fields['iss_retido']"
                               active-color="#13ce66"
                               :active-value="true"
                               active-text="SIM"
                               inactive-color="#ff4949"
                               :inactive-value="false"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Aliquota (%)">
                    <el-input v-model="form.nf_fields['aliquota']"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <el-alert type="warning"
                      :closable="false">
              <div class="fs-14">
                <div><strong>Campos Obrigatórios</strong></div>
                <ul>
                  <li v-if="nfseCityConfigCodigoCnaeObrigatorioNfse">Código Cnae</li>
                  <li v-if="nfseCityConfigItemListaServicoObrigatorioNfse">
                    Item da Lista de Serviço
                  </li>
                  <li v-if="nfseCityConfigCodigoTributarioMunicipioObrigatorioNfse">
                    Código Tributário do Município
                  </li>
                  <li>ISS Retido</li>
                  <li>Aliquota</li>
                </ul>
              </div>
            </el-alert>
          </el-card>
        </el-col>
      </el-row>
      <el-card class="el-card__btn_form">
        <el-form-item class="mb-0">
          <form-save-button :event-click="() => onSend(true)"
                            :is-disabled="isSubmitted || $v.form.$invalid"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="product.index"/>
        </el-form-item>
      </el-card>
    </el-form>
    <gripo-dialog :model.sync="validateNfFieldsData.modalVisible"
                  width="40vw">
      <template slot="header">Validação dos dados</template>
      <template slot="content">
        <el-form ref="form"
                 :model="form"
                 label-position="top">
          <div class="fs-16">
            <h3 class="text-center">Informações Importantes</h3>
            <p>
              Esse processo de validação é realizado de forma automática pelo sistema.<br>
              Será emitida uma nota fiscal de teste utilizando os dados do produto para que essas informações
              sejam validadas no Sefaz.
            </p>
            <div class="text-center">
              <h4>Emitir nota teste em ambiente de:</h4>
              <el-form-item>
                <el-radio-group v-model="validateNfFieldsData.env">
                  <el-radio-button label="staging">Homologação</el-radio-button>
                  <el-radio-button label="production">Produção</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
            <el-alert type="error"
                      class="mt-3 mb-0"
                      show-icon
                      v-if="validateNfFieldsData.env === 'production'"
                      :closable="false">
              <div slot="title" class="fs-18 font-weight-bold mb-1 text-center">
                Atenção! Essa ação irá emitir uma nota fiscal real.
              </div>
              <div slot="title" class="fs-16">
                A nota emitida nessa validação será automaticamente cancelada pelo sistema
                após o retorno da validação.
              </div>
            </el-alert>
            <ul v-if="false">
              <li v-if="hasCommercialReceiptModule">
                <strong>NFCe</strong> - O processo de validação é síncrono, isso significa que o retorno é imediato ao
                iniciar o processo
                de validação
              </li>
              <li v-if="hasCommercialInvoiceModule">
                <strong>NFSe</strong> - O processo de validação é assíncrono, isso significa que o retorno pode demorar
                até 10 minutos em
                alguns casos para que a prefeitura retorne com a validação
              </li>
            </ul>
          </div>
        </el-form>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="onValidateNfFields"
                   :disabled="isSubmitted || !form.id">
          Iniciar Validação
        </el-button>
      </template>
    </gripo-dialog>
  </div>
</template>
