import store from '../../vuex'
import * as restfulService from './restfulService'
import {set} from '@utils/storage'

function _login(data) {
    return new Promise((resolve, reject) => {
        restfulService.post('auth', 'login', null, data)
            .then(async (response) => {
                await set('blocked', response.blocked)
                if (response.clubs) {
                    resolve(response)
                } else {
                    store.dispatch('login', response).then(() => {
                        resolve(response)
                    })
                }
            })
            .catch(e => {
                console.log(e)
                reject()
            })
    })
}

function _logout() {
    return new Promise((resolve) => {
        store.dispatch('logout').then(() => resolve())
    })
}

function _recoveryAccess(data) {
    return new Promise((resolve, reject) => {
        restfulService.post('auth', 'recovery-access', null, data)
            .then(response => {
                resolve(response)
            })
            .catch(e => {
                console.log(e)
                reject()
            })
    })
}

function _updatePassword(data) {
    return new Promise((resolve, reject) => {
        restfulService.post('auth', 'update-password', null, data)
            .then(response => {
                resolve(response)
            })
            .catch(e => {
                console.log(e)
                reject()
            })
    })
}

function _register(data) {
    return new Promise((resolve, reject) => {
        restfulService.post('auth', 'register', null, data)
            .then(response => {
                store.dispatch('login', response).then(() => {
                    resolve(response)
                })
            })
            .catch(e => {
                console.log(e)
                reject()
            })
    })
}

function _verify(data) {
    return new Promise((resolve, reject) => {
        restfulService.post('auth', 'verify', null, data)
            .then(response => {
                store.dispatch('login', response).then(() => {
                    resolve(response)
                })
            })
            .catch(e => {
                console.log(e)
                reject()
            })
    })
}

export const login = _login
export const logout = _logout
export const recoveryAccess = _recoveryAccess
export const updatePassword = _updatePassword
export const register = _register
export const verify = _verify
