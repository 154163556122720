import DailyEntries from './components/DailyEntries.vue'
import TellerList from '../entry_teller/components/List.vue'
import Products from './components/Products.vue'
import Stock from './components/Stock.vue'

export default [
    {path: '/report/entry_teller', component: TellerList, name: 'entry_teller.list', meta: {permission: 'list.entry_teller'}},
    {path: '/report/entries', component: DailyEntries, name: 'report.daily.index', meta: {permission: 'report.entry'}},
    {
        path: '/report/products',
        component: Products,
        name: 'report.products.index',
        meta: {permission: 'report.product'}
    },
    {path: '/report/stock', component: Stock, name: 'report.stock.index', meta: {permission: 'report.stock'}}
]
