export const hasBasicPlan = ({tenant}) => {
    return (tenant?.modules?.basic || false)
}

export const hasProfessionalPlan = ({tenant}) => {
    return tenant.status === 'trial_time' || (tenant?.modules?.professional || false)
}

export const hasCommercialReceiptModule = ({tenant}) => {
    return hasProfessionalPlan({tenant})
        && (tenant.settings?.tenant_nf?.nfce?.enabled && tenant?.settings?.tenant_nf?.nfce?.status === 'active')
}

export const hasCommercialInvoiceModule = ({tenant}) => {
    return hasBasicPlan({tenant}) || hasProfessionalPlan({tenant})
        && (tenant.settings?.tenant_nf?.nfse?.enabled && tenant?.settings?.tenant_nf?.nfse?.status === 'active')
}

export const hasFaceReconModule = ({tenant}) => {
    return (tenant?.modules?.faceRecon || false)
}

export const hasTotemModule = ({tenant}) => {
    return (tenant?.modules?.totem || false)
}

export const hasStoneConnectModule = ({tenant}) => {
    return true || (tenant?.modules?.stoneConnect || false)
}

export const hasStonePayment = ({tenant}) => {
    return hasProfessionalPlan({tenant})
        && (tenant.settings?.tenant_stone_payment?.enabled || false)
}
