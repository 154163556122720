<script>
import GripoDialog from '../../../../../../components/common/Dialog'
import PlayerImpediments from './PlayerImpediments'

import helpers from '@mixins/helpers'

export default {
  name: 'pair-impediments',
  props: {
    stageId: {
      type: Number,
      required: true
    },
    dates: {
      type: Array,
      required: true
    },
    pair: {
      type: Object,
      required: true
    },
    stageSingle: {
      type: Boolean,
      required: false
    }
  },
  components: {GripoDialog, PlayerImpediments},
  mixins: [helpers],
  data() {
    return {
      impediments: true,
      dialogVisible: false,
      showImpediments: false
    }
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
      setTimeout(() => {
        this.showImpediments = true
      }, 200)
    },
    closeDialog() {
      this.dialogVisible = false
      this.showImpediments = false
    },
    save() {
      this.dialogVisible = false
    }
  }
}

</script>


<template>

  <div class="pair-impediments">

    <v-badge color="green darken-2 v-badge--mini" overlap :value="pair.impediments > 0" title="Impedimentos Encontrados">
      <template v-slot:badge>&nbsp;</template>
      <el-button @click="openDialog"
                 type="warning"
                 size="mini"
                 title="Impedimentos"
                 icon="el-icon-date">
      </el-button>
    </v-badge>

    <gripo-dialog :model.sync="dialogVisible"
                  width="85vw"
                  top="3vh">
      <template slot="header">Impedimentos</template>
      <template slot="content">
        <el-tabs type="card" v-if="showImpediments">
          <el-tab-pane :label="pair.player1.player.name">
            <player-impediments :stage-id="stageId"
                                :dates="dates"
                                :pair-id="pair.id"
                                :player-id="pair.player1_id"
                                :player-name="pair.player1.player.name"></player-impediments>
          </el-tab-pane>
          <el-tab-pane :label="pair.player2.player.name" v-if="!stageSingle">
            <player-impediments :stage-id="stageId"
                                :dates="dates"
                                :pair-id="pair.id"
                                :player-id="pair.player2_id"
                                :player-name="pair.player2.player.name"></player-impediments>
          </el-tab-pane>
        </el-tabs>
      </template>
    </gripo-dialog>
  </div>
</template>

<style lang="scss">
.pair-impediments {
  display: inline-block;
}
</style>
