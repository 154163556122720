<script>
import DataService from '@app/services/dataService'

export default {
  name: 'plan-type',
  props: {
    type: {
      type: String,
      default: 'custom'
    }
  },
  data() {
    return {
      localType: '',
      typeList: []
    }
  },
  computed: {
    typeLabel() {
      return this.typeList.find(item => item.id === this.localType)?.name ?? ''
    }
  },
  mounted() {
    if (this.type) {
      this.localType = this.type
      DataService.get([{domain: 'default_plans'}]).then((result) => {
        this.typeList = [...result.default_plans]
      })
    }
  }
}
</script>

<template>
  <el-tag size="mini" class="el-tag--status" type="info">
    {{ typeLabel }}
  </el-tag>
</template>
