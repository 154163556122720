<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import ListDeleteButton from '../../../../components/list/DeleteButton'
import UserType from './UserType'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'
import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'
import GripoDialog from '@components/common/Dialog.vue'
import {confirmDialog} from '@utils/flash'

export default {
  components: {GripoDialog, ListMain, ListHeader, ListEditButton, ListDeleteButton, UserType},
  data() {
    return {
      isLoadingData: true,
      filters: {
        type: 'all',
        status: 'active',
      },
      dependencies: {},
      tokenModal: {
        show: false,
        name: '',
        code: '',
      }
    }
  },
  mounted() {
    this.startWebsocket()
    const searchData = this.prepareSearchData()
    bus.$emit('list-init', {domain: 'user', data: searchData, relations: ['totem_token']}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  beforeDestroy() {
    this.closeWebsocket()
  },
  computed: {
    ...mapGetters(['listStore', 'hasTotemModule', 'tenant']),
    wstTotemUpdate() {
      const key = 'totemUpdate'
      return {
        channel: `${key}.${this.tenant?.id || ''}`,
        listen: `.${key}`
      }
    }
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.wstTotemUpdate.channel)
          .listen(this.wstTotemUpdate.listen, ({action}) => {
        if (action !== 'create') {
          this.tokenModal.show = false
        }
        this.onFilter()
      })
    },
    closeWebsocket() {
      window.Echo.leaveChannel(this.wstTotemUpdate.channel)
    },
    prepareSearchData() {
      const searchData = {}
      if (this.filters.name) {
        searchData.name = this.filters.name
      }
      if (this.filters.status !== 'all') {
        searchData.status = this.filters.status
      }
      if (this.filters.type !== 'all') {
        searchData.type = this.filters.type
      }
      return searchData
    },
    onFilter() {
      const data = this.prepareSearchData()
      bus.$emit('list-filter', data)
    },
    onFilterClean() {
      this.filters = {
        type: 'all',
        status: 'active',
      }
      const defaultSearchData = this.prepareSearchData()
      bus.$emit('list-filter-clean', defaultSearchData, this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    totemStatus(item) {
      if (item.totem_token) {
        const status = item.totem_token.status
        return status === 'used'
            ? 'active'
            : (
                status === 'expired'
                    ? 'inactive'
                    : 'created'
            )
      }
      return 'created'
    },
    async createTotem(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente ativar esse Totem?',
        confirmButtonText: 'Sim, ativar!',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        restfulService.put('user', 'update-totem', item.id, {action: 'create'})
            .then((response) => {
              this.onFilter()
              notifyService.success({hideLoader: true})
              this.tokenModal.name = response.name
              this.tokenModal.code = response.code
              this.tokenModal.show = true
              this.isSubmitted = false
              bus.$emit('hide-loader')
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async deactivateTotem(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente desativar esse Totem?',
        text: 'Todas sessões desse totem serão finalizadas',
        confirmButtonText: 'Sim, desativar!',
      })
      if (confirm) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        restfulService.put('user', 'update-totem', item.id, {action: 'deactivate'})
            .then(() => {
              this.onFilter()
              notifyService.success({hideLoader: true})
              this.isSubmitted = false
              bus.$emit('hide-loader')
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async handleInvite(item, action) {
      const {value: confirm} = await confirmDialog({
        icon: action === 'resend' ? 'warning' : 'error',
        title: `Deseja realmente ${action === 'resend' ? 'reenviar' : 'cancelar'} o convite?`,
        text: '',
        confirmButtonText: 'Sim, continuar!',
      })
      if (confirm) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = {
          action,
          token_id: item.token_id
        }
        restfulService.post('user', 'invite-action', null, data)
            .then((response) => {
              this.onFilter()
              notifyService.success({hideLoader: true, message: response.message})
              this.isSubmitted = false
              bus.$emit('hide-loader')
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Usuários" route-name="user.store"/>

    <ListMain>

      <div slot="filters">
        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         filterable
                         placeholder="Selecione um status"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Ativo" value="active"/>
                <el-option label="Inativo" value="inactive"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Tipo de Usuário" v-if="hasTotemModule">
              <el-select v-model="filters.type"
                         filterable
                         placeholder="Selecione um status"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Usuário" value="user"/>
                <el-option label="Totem" value="totem"/>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="text-center" v-if="hasTotemModule">Tipo</th>
            <th>Nome</th>
            <th>Login</th>
            <th>Email</th>
            <th>Telefone</th>
            <th class="text-center">Status</th>
            <th>Perfis</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td class="text-center" v-if="hasTotemModule">
              <user-type :type="item.type"/>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.login }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
            <td class="text-center">
              <template v-if="item.type === 'user'">
                <el-tag size="small" class="el-tag--status" type="success" v-if="item.status === 'active'">
                  Ativo
                </el-tag>
                <el-tag size="small" class="el-tag--status" type="info" v-else-if="item.status === 'invited'">
                  Convidado
                </el-tag>
                <el-tag size="small" class="el-tag--status" type="danger" v-else>
                  Inativo
                </el-tag>
              </template>
              <template v-else>
                <el-tag size="small" class="el-tag--status" type="success" v-if="totemStatus(item) === 'active'">
                  Ativo
                </el-tag>
                <el-tag size="small" class="el-tag--status" type="danger" v-else-if="totemStatus(item) === 'inactive'">
                  Inativo
                </el-tag>
                <el-tag size="small" class="el-tag--status" type="info" v-else>
                  Criado
                </el-tag>
              </template>
            </td>
            <td>{{ Array.isArray(item?.roles) ? item.roles.map(item => item.name).join(', ') : '' }}</td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <template v-if="item.type === 'totem'">
                  <el-button type="success"
                             v-if="totemStatus(item) !== 'active'"
                             @click="createTotem(item)"
                             title="Ativar Totem">
                    <i class="fas fa-play"></i>
                  </el-button>
                  <el-button type="warning"
                             v-if="totemStatus(item) === 'active'"
                             @click="deactivateTotem(item)"
                             title="Desativar Totem">
                    <i class="fas fa-stop"></i>
                  </el-button>
                </template>
                <template v-if="item.status !== 'invited'">
                  <ListEditButton route-name="user.update" :item="item"/>
                  <ListDeleteButton :item="item" domain="user"/>
                </template>
                <template v-else>
                  <el-button type="warning"
                             @click="handleInvite(item, 'resend')"
                             title="Reenviar Convite">
                    <i class="fas fa-paper-plane"></i>
                  </el-button>
                  <el-button type="danger"
                             @click="handleInvite(item, 'delete')"
                             title="Cancelar Convite">
                    <i class="fas fa-trash"></i>
                  </el-button>
                </template>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
    <gripo-dialog :model.sync="tokenModal.show"
                  width="60vw"
                  :persistent="false">
      <template v-slot:header>Código de Ativação</template>
      <template v-slot:content>
        <p class="text-center">Utilize o código abaixo para ativar o totem: {{ tokenModal.name }}</p>
        <div class="totem-code">{{ tokenModal.code }}</div>
        <p class="text-center fs-16 mt-3">
          Esse código irá expirar em 2 minutos, após esse
          tempo será necessário ativar o totem novamente
        </p>
      </template>
    </gripo-dialog>
  </div>
</template>
