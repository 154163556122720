<script>
import ListMain from '@components/list/Main.vue'
import UserTypeLabel from './UserTypeLabel'
import StatusLabel from './StatusLabel'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import moment from 'moment'
import {confirmDialog} from '@utils/flash'

const defaultFilter = {
  dates: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
  owner_type: 'all',
  channel: 'whatsapp',
  status: ['queued', 'sent', 'delivered', 'read', 'failed', 'no_credits'],
  charged: 'all'
}
const defaultOrder = [{column: 'created_at', direction: 'desc'}]

export default {
  name: 'message-pack-list',
  components: {ListMain, UserTypeLabel, StatusLabel},
  props: {
    credits: {
      type: Number,
      default: 0,
    },
    pendingMessages: {
      type: Number,
      default: 0,
    }
  },
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      filters: {...defaultFilter},
      selectedList: [],
      dependencies: {
        tenants: []
      }
    }
  },
  async mounted() {
    this.startWebsocket()
    const data = this.prepareSearchData()
    bus.$emit('list-init', {
      skipSearchOnInit: true,
      domain: 'notification/custom-search',
      searchRoute: false,
      data
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore']),
    isAllChecked() {
      return this.listStore.listData.length > 0 && this.selectedList.length === this.listStore.listData.length
    },
    wsKeyTenantEntryPaymentUpdateChannel() {
      const key = 'tenantEntryPaymentUpdate'
      return {
        channel: `${key}.${this.tenant?.id || ''}`,
        listen: `.${key}`
      }
    },
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.wsKeyTenantEntryPaymentUpdateChannel.channel)
          .listen(this.wsKeyTenantEntryPaymentUpdateChannel.listen, ({message}) => {
            this.onFilter()
            notifyService.success({message, duration: 8000})
          })
    },
    prepareSearchData() {
      let searchData = {orderBy: [...defaultOrder]}
      if (this.filters.status.length) {
        searchData.status = this.filters.status
      }
      if (this.filters.dates && this.filters.dates.length === 2) {
        if (this.filters.dates[0].length > 0 && this.filters.dates[1].length > 0) {
          searchData.sent_at = [this.filters.dates[0] + ' 00:00:00', this.filters.dates[1] + ' 23:59:59']
        }
      }
      if (this.filters.owner_type !== 'all') {
        searchData.owner_type = this.filters.owner_type
      }
      if (this.filters.target) {
        searchData.target = this.filters.target
      }
      if (this.filters.message) {
        searchData.message = this.filters.message
      }
      if (this.filters.charged !== 'all') {
        searchData.charged = this.filters.charged === 'yes'
      }
      return searchData
    },
    onFilter() {
      const data = this.prepareSearchData()
      bus.$emit('list-filter', data, () => {
        this.resetSelectedList()
      })
    },
    onFilterClean() {
      this.filters = {...defaultFilter}
      const defaultSearchData = this.prepareSearchData()
      bus.$emit('list-filter-clean', this.filters, defaultSearchData)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    resetSelectedList() {
      this.listStore.listData.forEach(entry => {
        entry.checked = false
      })
      this.selectedList.splice(0)
    },
    checkItem(listItem) {
      if (this.selectedList.some(item => item === listItem.id)) {
        this.selectedList = this.selectedList.filter(item => item !== listItem.id)
        listItem.checked = false
      } else {
        this.selectedList.push(listItem.id)
        listItem.checked = true
      }
    },
    checkAll() {
      if (this.isAllChecked) {
        this.resetSelectedList()
      } else {
        this.selectedList = [...this.listStore.listData.map(item => item.id)]
        this.listStore.listData.forEach(entry => {
          entry.checked = true
        })
      }
    },
    async selectedAction(command) {
      const [label, action] = command.split('|')
      const {value: confirm} = await confirmDialog({
        title: `Deseja realmente ${label} as mensagens selecionadas?`,
        icon: 'warning',
      })
      if (confirm) {
        this.isSubmitted = true
        bus.$emit('show-loader')
        restfulService.post('notification', 'execute', null, {action, selected: this.selectedList})
            .then(() => {
              this.isSubmitted = false
              this.onFilter()
            })
            .catch(e => {
              this.isSubmitted = false
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    }
  },
  beforeMount() {
    this.resetSelectedList()
    this.listStore.listData = []
  }
}
</script>

<template>
  <div>

    <ListMain title="Mensagens" :show-total="false">

      <div slot="filters">

        <div class="flex-c -ai-fs gap-25">

          <el-card class="el-card__search_list box-card">
            <div slot="header">
              <span>Filtrar Mensagens</span>
              <div>
                <el-button type="primary" @click="onFilter" size="mini">Pesquisar</el-button>
                <el-button type="default" @click="onFilterClean" size="mini">Limpar</el-button>
              </div>
            </div>
            <el-form :inline="true" :model="filters">
              <el-form-item label="Status" style="min-width: 300px;">
                <el-select v-model="filters.status"
                           clearable
                           multiple
                           placeholder="Filtrar por Status"
                           class="el-select-full"
                           empty-text="Nenhum registro encontrado">
                  <el-option label="Em fila" value="queued"/>
                  <el-option label="Enviada" value="sent"/>
                  <el-option label="Entregue" value="delivered"/>
                  <el-option label="Lida" value="read"/>
                  <el-option label="Falha ao enviar" value="failed"/>
                  <el-option label="Sem créditos" value="no_credits"/>
                  <el-option label="Arquivada" value="archived"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Data de Envio">
                <el-date-picker
                    v-model="filters.dates"
                    type="daterange"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    placeholder="Filtrar por data de envio">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Tipo de Usuário">
                <el-select v-model="filters.owner_type"
                           filterable
                           clearable
                           placeholder="Filtrar por Tipo de Usuário"
                           empty-text="Nenhum registro encontrado">
                  <el-option label="Não filtrar" value="all"/>
                  <el-option label="App" value="Domain\Admin\System\User\User"/>
                  <el-option label="Gestão" value="Domain\Admin\System\TenantUser\TenantUser"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Telefone">
                <el-input v-model="filters.target"
                          placeholder="Filtrar por telefone"
                          @keydown.enter.native.prevent="onFilter"/>
              </el-form-item>
              <el-form-item label="Texto da Mensagem">
                <el-input v-model="filters.message"
                          placeholder="Filtrar pela mensagem"
                          @keydown.enter.native.prevent="onFilter"/>
              </el-form-item>
              <el-form-item label="Crédito Usado">
                <el-select v-model="filters.charged"
                           filterable
                           clearable
                           placeholder="Filtrar por Crédito Usado"
                           empty-text="Nenhum registro encontrado">
                  <el-option label="Não filtrar" value="all"/>
                  <el-option label="Sim" value="yes"/>
                  <el-option label="Não" value="no"/>
                </el-select>
              </el-form-item>
            </el-form>
          </el-card>
          <div style="min-width: 200px;">
            <div class="list-card">
              <div class="text-center">
                <strong>Créditos Disponíveis:</strong>
                <div :class="['value', {'text-success font-weight-bold': credits > 0}]">{{ credits }}</div>
              </div>
            </div>
            <div class="list-card mt-4">
              <div class="text-center">
                <strong>Mensagens em fila/com erro:</strong>
                <div :class="['value', {'text-danger font-weight-bold': pendingMessages > 0}]">{{
                    pendingMessages
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div slot="headerTitle">
        <span>Mensagens</span>
        <div class="entries-selected d-inline-block">
          <el-dropdown :disabled="selectedList.length === 0 || isSubmitted"
                       @command="selectedAction">
            <el-button :type="selectedList.length === 0 ? 'default' : 'primary'" size="small">
              <span>Com selecionadas:</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Reenviar|resend">
                <i class="fas fa-sync"></i>
                <span class="ml-2">Reenviar</span>
              </el-dropdown-item>
              <el-dropdown-item command="Arquivar|archive">
                <i class="fas fa-archive"></i>
                <span class="ml-2">Arquivar</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-check">
              <el-checkbox @change="checkAll" :value="isAllChecked"/>
            </th>
            <th>Data/Hora Criação</th>
            <th class="text-center">Status</th>
            <th>Data/Hora Envio</th>
            <th class="text-center">Tipo de Usuário</th>
            <th>Telefone</th>
            <th>Mensagem</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in listStore.listData">
            <th class="list-table-check">
              <el-checkbox @change="checkItem(item)" v-model="item.checked" :disabled="!item.id"/>
            </th>
            <td>
              {{ item.created_at | dateTimeEnToBr }}h
            </td>
            <td class="text-center">
              <status-label :status="item.status"/>
              <div class="fs-12" v-if="['no_credits', 'failed'].includes(item.status) && item.error_message">
                {{ item.error_message }}
              </div>
            </td>
            <td>
              <span v-if="item.sent_at">{{ item.sent_at | dateTimeEnToBr }}h</span>
            </td>
            <td class="text-center">
              <user-type-label :owner-type="item.owner_type"/>
            </td>
            <td>{{ item.target }}</td>
            <td>{{ item.message }}</td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
