<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required, requiredIf, email} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
import {get} from 'lodash'

export default {
  mixins: [helpers],
  components: {ListHeader, FormSaveButton, FormCancelButton},
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      enableUpdatePassword: false,
      showPassword: false,
      phonePrefix: '+55',
      form: {
        country_id: '',
        type: 'user',
        name: '',
        login: '',
        email: '',
        phone: '',
        cpf: '',
        password: '',
        status: 'active',
        stages: {},
      },
      dependencies: {
        roles: [],
        countries: [],
        stages: [],
      },
      selectedRoles: [],
      selectedStages: [],
      allStagesAvailable: true,
    }
  },
  validations: {
    form: {
      country_id: {required},
      name: {
        required: requiredIf(function (data) {
          return data.type === 'user' && this.isEdit
        })
      },
      email: {email}
    },
    selectedRoles: {
      required: requiredIf(function () {
        return this.form.type === 'user'
      })
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasTotemModule']),
    tournamentsRoleSelected() {
      const tournamentRole = this.dependencies.roles.find(item => item.key === 'torneios')
      return this.selectedRoles.includes(tournamentRole?.id)
    },
    dependenciesRoles() {
      const coachKey = 'professores'
      return this.dependencies.roles.filter((item) => !(!this.isEdit && item.key === coachKey)).map(item => {
        return {
          ...item,
          disabled: item.key === coachKey && this.selectedRoles.length === 1
        }
      })
    },
    phoneMask() {
      const country = this.dependencies.countries.find(item => item.id === this.form.country_id)
      return this.countryPhoneMask(country?.code)
    }
  },
  async mounted() {
    await this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('user', null, id, null, ['roles'])
          .then(response => {
            this.form = {...this.form, ...response}
            this.selectedRoles = response.roles.map((item) => {
              return item.id
            })
            this.allStagesAvailable = get(response, `stages.${this.tenant.id}.all`, true)
            this.selectedStages = get(response, `stages.${this.tenant.id}.list`, [])
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
    if (!this.form.country_id) {
      const country = this.dependencies.countries.find(item => item.code === 'BRA')
      this.form.country_id = country.id
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'role'}, {domain: 'country'}]).then(async (result) => {
          this.dependencies = {...this.dependencies, ...result}
          this.dependencies.stages = [...(await restfulService.post('stage', 'all', null, {}))]
          resolve()
        })
      })
    },
    changeCountry(id) {
      const country = this.dependencies.countries.find(item => item.id === id)
      this.phonePrefix = '+' + country.phone_prefix
    },
    onSend() {
      this.isSubmitted = true
      let data = {...this.form, ...{stages: {}}}
      if (data.phone) {
        data.phone = this.onlyNumbers(data.phone)
      }
      if (data.type === 'totem') {
        if (!data.login) {
          data.login = 'totem-' + this.tenant.id + '-' + new Date().getTime()
        }
        if (!data.password) {
          data.password = 'totem-password'
        }
      }
      data.roles = [...this.selectedRoles]
      data.stages[this.tenant.id] = {
        all: this.allStagesAvailable,
        list: !this.allStagesAvailable ? [...this.selectedStages] : []
      }
      restfulService.save('user', null, null, data)
          .then(({message}) => {
            notifyService.success({hideLoader: true, message: message})
            this.isSubmitted = false
            this.$router.push({name: 'user.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Usuários"/>

    <el-card class="el-card__save_form box-card">
      <div slot="header">
        <span>Dados do Usuário</span>
      </div>
      <el-form ref="form"
               :model="form"
               label-position="top"
               @submit.prevent="onSend">
        <el-row :gutter="20">
          <el-col :span="8" v-if="hasTotemModule">
            <el-form-item label="Tipo de Usuário">
              <el-radio-group v-model="form.type">
                <el-radio-button label="user">Usuário</el-radio-button>
                <el-radio-button label="totem">Totem</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="16" v-if="!isEdit && form.type === 'totem'">
            <el-form-item label="Nome" class="is-required">
              <el-input v-model="form.name"
                        @input="$v.form.name.$touch()"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" v-if="form.type === 'user' && !isEdit">
            <el-form-item label="Email">
              <el-input v-model="form.email"
                        @input="$v.form.email.$touch()"/>
            </el-form-item>
          </el-col>
          <el-col :span="isEdit ? 24 : 12" v-if="form.type === 'user'">
            <el-form-item label="País"
                          class="is-required"
                          style="margin-bottom: 0;"
                          :class="{ 'el-form-item--error': $v.form.country_id.$error }">
              <v-select v-model="form.country_id"
                        :items="dependencies.countries"
                        item-value="id"
                        item-text="name"
                        outline
                        single-line
                        :error-messages="validationMessageField($v.form.country_id)"
                        @input="$v.form.country_id.$touch()"
                        @blur="$v.form.country_id.$touch()"
                        @change="changeCountry">
                <template slot="item" slot-scope="data">
                  <v-list-tile-avatar>
                    <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ data.item.name }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </template>
                <template v-slot:selection="{ item }">
                  <v-list-tile-avatar>
                    <img :src="`/static/flags/${item.flag_icon}`" alt="">
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ item.name }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </v-select>
              <div class="el-form-item__error" v-if="$v.form.country_id.$error">
                Campo obrigatório
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="isEdit">
          <el-col :span="8">
            <el-form-item label="Nome" class="is-required">
              <el-input v-model="form.name"
                        @input="$v.form.name.$touch()"/>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.type === 'user' && form.login">
            <el-form-item label="Login">
              <el-input v-model="form.login"
                        :disabled="!!form.login"
                        autocomplete="off"
                        @input="$v.form.login.$touch()"/>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.type === 'user'">
            <el-form-item label="Email">
              <el-input v-model="form.email"
                        @input="$v.form.email.$touch()"/>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.type === 'user'">
            <div class="flex-c -jc-fs -ai-fs gap-20">
              <el-form-item label="Alterar Senha">
                <el-switch v-model="enableUpdatePassword"
                           active-text="Sim"
                           inactive-text="Não">
                </el-switch>
              </el-form-item>
              <el-form-item label="Senha" class="el-select-full">
                <el-input v-model="form.password"
                          :disabled="!enableUpdatePassword"
                          :type="showPassword ? 'text' : 'password'"
                          autocomplete="off">
                  <div class="el-input--suffix-box" slot="suffix"
                       @click="enableUpdatePassword ? showPassword = !showPassword : null">
                    <span v-if="showPassword" key="passwordVisibility"><i class="fas fa-eye"></i></span>
                    <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
                  </div>
                </el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8" v-if="form.type === 'user'">

            <el-form-item label="Status do Usuário" v-if="form.type === 'user' && isEdit">
              <el-switch v-model="form.status"
                         active-value="active"
                         inactive-value="inactive"
                         active-text="Ativo"
                         inactive-text="Inativo">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Perfis do Usuário" class="is-required" v-if="form.type === 'user'">
          <el-transfer
              v-model="selectedRoles"
              :data="dependenciesRoles"
              :titles="['Disponíveis', 'Selecionados']"
              :props="{
                        key: 'id',
                        label: 'name',
                        disabled: 'disabled'
                      }">
          </el-transfer>
        </el-form-item>
        <template v-if="form.type === 'user' && tournamentsRoleSelected">
          <div class="flex-c gap-10 -jc-fs mt-2 mb-2">
            <h3 class="m-0 mb-1">Torneios Habilitados</h3>
            <el-switch v-model="allStagesAvailable"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="Todos"
                       inactive-text=""/>
          </div>
          <el-form-item label="Torneios" class="is-required" v-if="!allStagesAvailable">
            <el-transfer
                v-model="selectedStages"
                :data="dependencies.stages"
                :titles="['Disponíveis', 'Selecionados']"
                :props="{
                        key: 'id',
                        label: 'name',
                        disabled: 'disabled'
                      }">
            </el-transfer>
          </el-form-item>
        </template>
        <el-form-item class="mt-4">
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.form.$invalid || $v.selectedRoles.$invalid"
                            :is-submitted="isSubmitted"/>
          <form-cancel-button route-name="user.index"/>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
