<script>
import GripoDialog from '@components/common/Dialog'
import EntryStatusLabel from './labels/EntryStatusLabel'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import QRCode from 'qrcode-svg'
import moment from 'moment'
import {confirmDialog} from '@utils/flash'

export default {
  components: {GripoDialog, EntryStatusLabel},
  name: 'tenant-financial',
  props: ['entriesOverdueCount'],
  data() {
    return {
      isSubmitted: false,
      entries: {
        filters: {
          dates: [moment().subtract(1, 'years').startOf('month').format('YYYY-MM-DD'), moment().add(1, 'years').endOf('month').format('YYYY-MM-DD')],
          status: 'created',
          limit: 10,
          offset: 0
        },
        data: []
      },
      payment: {
        amount: 0,
        boleto: {
          showModal: false,
          base64Pdf: '',
          urlPdf: '',
          generateTries: 0
        },
        pix: {
          showModal: false,
          base64Image: '',
          qrcode: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    wsKeyTenantEntryPaymentUpdateChannel() {
      const event = 'tenantEntryPaymentUpdate'
      return {name: `${event}.${this.tenant.id}`, listen: `.${event}`}
    },
  },
  async mounted() {
    this.startWebsocket()
    this.loadEntries()
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.wsKeyTenantEntryPaymentUpdateChannel.name)
          .listen(this.wsKeyTenantEntryPaymentUpdateChannel.listen, ({message}) => {
            this.loadEntries()
            notifyService.success({message, duration: 8000})
          })
    },
    loadEntries() {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        const data = {...this.entries.filters}
        restfulService.post('tenant', 'entries', null, data)
            .then(async (response) => {
              this.entries.data = [...response]
              this.$emit('update:entriesOverdueCount', this.entries.data.filter(item => item.overdue).length)
              bus.$emit('hide-loader')
              resolve()
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
              reject()
            })
      })
    },
    async tenantPayment(entryPortion, paymentMethod) {
      this.isSubmitted = true
      bus.$emit('show-loader', `Aguarde...<br>Estamos gerando seu ${paymentMethod}`)
      const data = {
        entry_portion_id: entryPortion.id,
        entry_id: entryPortion.entry_id,
        due_date: entryPortion.due_date,
        payment_method: paymentMethod
      }
      this.payment.amount = entryPortion.amount
      try {
        const response = await restfulService.post('tenant', 'entry-payment', null, data)
        if (paymentMethod === 'boleto') {
          this.payment.boleto.generateTries += 1
          if (response.base64_pdf || response.url_pdf) {
            this.payment.boleto.generateTries = 0
            this.payment.boleto.base64Pdf = response.base64_pdf
            this.payment.boleto.urlPdf = response.url_pdf
            this.payment.boleto.showModal = true
            this.isSubmitted = false
            bus.$emit('hide-loader')
          } else if (this.payment.boleto.generateTries <= 5) {
            setTimeout(async () => {
              this.tenantPayment(entryPortion, paymentMethod)
            }, 2000)
          } else {
            this.payment.boleto.generateTries = 0
            this.$notify.warning({
              title: 'Ocorreu um problema ao gerar seu boleto!',
              message: 'Tente novamente mais tarde.',
              duration: 3000,
            })
            this.isSubmitted = false
            bus.$emit('hide-loader')
          }
        } else {
          this.payment.pix.base64Image = response.base64_pdf
          this.payment.pix.qrcode = response.pix_qr_code
          this.payment.pix.showModal = true
          setTimeout(() => {
            const svg = (new QRCode({
              content: response.pix_qr_code,
              padding: 0,
              width: 256,
              height: 256,
              color: '#000000',
              background: '#ffffff',
              ecl: 'M',
            })).svg()
            this.$refs['pixContainer'].innerHTML = svg
            this.isSubmitted = false
            bus.$emit('hide-loader')
          }, 200)
        }
      } catch (e) {
        this.isSubmitted = false
        bus.$emit('hide-loader')
        console.log(e)
      }
    },
    resetPayment(paymentMethod) {
      this.payment.amount = 0
      if (paymentMethod === 'boleto') {
        this.payment.boleto.base64Pdf = ''
      } else {
        this.payment.pix.base64Image = ''
        this.payment.pix.qrcode = ''
      }
    },
    resetPaymentBoleto() {
      this.resetPayment('boleto')
    },
    resetPaymentPix() {
      this.resetPayment('pix')
    },
    copyText(ref) {
      const codeToCopy = this.$refs[ref]
      navigator.clipboard.writeText(codeToCopy.value)
      this.$notify.success({
        title: 'Sucesso!',
        message: 'Código QR Code copiado!',
        duration: 3000,
      })
    },
    async generateInvoice(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente gerar a nota fiscal?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.post('tenant', 'generate-invoice', null, {entry_portion_id: item.id})
            .then(() => {
              this.loadEntries()
              notifyService.success({message: 'A geração da nota fiscal foi adicionada na fila para ser gerada'})
            })
            .catch(e => {
              console.log(e)
            })
            .finally(() => {
              bus.$emit('hide-loader')
            })
      }
    },
    showInvoice(item) {
      window.open(item.invoice.pdf_file, '_blank')
    }
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-card">
    <div slot="header">
      <span>Faturas Pendentes de Pagamento</span>
    </div>
    <el-form :inline="true" :model="entries.filters">
      <el-form-item label="Período">
        <el-date-picker
            v-model="entries.filters.dates"
            @change="loadEntries"
            type="daterange"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Filtrar por vencimento">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Status">
        <el-select v-model="entries.filters.status"
                   @change="loadEntries"
                   placeholder="Filtrar por Status"
                   empty-text="Nenhum registro encontrado">
          <el-option label="Em Aberto" value="created"/>
          <el-option label="Pago" value="settled"/>
        </el-select>
      </el-form-item>
    </el-form>
    <table class="custom-table-list responsive-data-table" v-if="entries.data.length">
      <thead>
      <tr>
        <th>Tipo</th>
        <th>Descrição</th>
        <th class="text-center">Vencimento</th>
        <th align="right">Valor</th>
        <th class="text-center">Status</th>
        <th class="btn-actions">Ações</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in entries.data" :class="{'overdue': item.overdue}">
        <template v-if="!$vuetify.breakpoint.xs">
          <td>{{ item.type }}</td>
          <td>{{ item.description }}</td>
          <td class="text-center">
            <span :class="{'overdue-label': item.overdue}">
              {{ item.due_date | dateEnToBr }}
            </span>
          </td>
          <td align="right">{{ item.amount | currencyFormatter }}</td>
          <td class="text-center">
            <entry-status-label :status="item.status"
                                :size="item.status === 'settled' ? 'mini': 'small'"/>
            <div v-if="item.status === 'settled'" class="mt-1"><strong>Pago em:</strong>
              {{ item.pay_date | dateTimeEnToBr }}h
            </div>
          </td>
          <td class="list-table-nowrap">
            <template v-if="item.status === 'created'">
              <el-button type="success"
                         size="small"
                         @click="tenantPayment(item, 'pix')"
                         :disabled="isSubmitted"
                         v-if="item.payment_available && !item.transaction_id"
                         title="Gerar PIX">
                <i class="fas fa-qrcode"></i>
                <span>PIX</span>
              </el-button>
              <el-button type="warning"
                         size="small"
                         @click="tenantPayment(item, 'boleto')"
                         :disabled="isSubmitted"
                         v-if="item.payment_available && item.classification_key !== 'pacote_mensagens'"
                         title="Gerar Boleto">
                <i class="fas fa-dollar-sign"></i>
                <span>Boleto</span>
              </el-button>
            </template>
            <template v-else>
              <el-button type="warning"
                         size="small"
                         key="generateInvoice"
                         v-if="!item.invoice"
                         @click="generateInvoice(item)"
                         title="Gerar NFSe">
                <i class="fas fa-file-invoice"></i>
                <span>Gerar NFSe</span>
              </el-button>
              <el-button type="primary"
                         size="small"
                         key="showInvoice"
                         v-if="item.invoice"
                         @click="showInvoice(item)"
                         title="Abrir NFSe">
                <i class="fas fa-file-invoice"></i>
                <span>Abrir NFSe</span>
              </el-button>
            </template>
          </td>
        </template>
        <template v-else>
          <td>
            <ul class="flex-content">
              <li class="flex-item" data-label="Tipo">{{ item.type }}</li>
              <li class="flex-item" data-label="Descrição">{{ item.description }}</li>
              <li class="flex-item" data-label="Vencimento">{{ item.due_date | dateEnToBr }}</li>
              <li class="flex-item" data-label="Status">
                <entry-status-label :status="item.status"/>
                <div v-if="item.status === 'settled'" class="mt-1"><strong>Pago em:</strong>
                  {{ item.pay_date | dateTimeEnToBr }}h
                </div>
              </li>
              <li class="flex-item" data-label="Valor">
                {{ item.amount | currencyFormatter }}
              </li>
              <li class="flex-item" data-label="Ações">
                <template v-if="item.status === 'created'">
                  <el-button type="success"
                             size="small"
                             @click="tenantPayment(item, 'pix')"
                             :disabled="isSubmitted"
                             v-if="item.payment_available && !item.transaction_id"
                             title="Gerar PIX">
                    <i class="fas fa-qrcode"></i>
                    <span>PIX</span>
                  </el-button>
                  <el-button type="warning"
                             size="small"
                             @click="tenantPayment(item, 'boleto')"
                             :disabled="isSubmitted"
                             v-if="item.payment_available && item.classification_key !== 'pacote_mensagens'"
                             title="Gerar Boleto">
                    <i class="fas fa-dollar-sign"></i>
                    <span>Boleto</span>
                  </el-button>
                </template>
                <template v-else>
                  <el-button type="warning"
                             size="small"
                             key="generateInvoice"
                             v-if="!item.invoice"
                             @click="generateInvoice(item)"
                             title="Gerar NFSe">
                    <i class="fas fa-file-invoice"></i>
                    <span>Gerar NFSe</span>
                  </el-button>
                  <el-button type="primary"
                             size="small"
                             key="showInvoice"
                             v-if="item.invoice"
                             @click="showInvoice(item)"
                             title="Abrir NFSe">
                    <i class="fas fa-file-invoice"></i>
                    <span>Abrir NFSe</span>
                  </el-button>
                </template>
              </li>
            </ul>
          </td>
        </template>
      </tr>
      </tbody>
    </table>
    <div v-else class="text-center">
      Nenhuma fatura foi encontrada!
    </div>
    <gripo-dialog :model.sync="payment.boleto.showModal"
                  width="80vw"
                  :actions-close-btn="false"
                  :persistent="false"
                  content-card-class="modal-pdf"
                  :on-close="resetPaymentBoleto">
      <template v-slot:header>Pagar via Boleto</template>
      <template v-slot:content>
        <div class="text-center mb-2">
          <a class="el-button el-button--primary" target="_blank" :href="payment.boleto.urlPdf">
            <i class="fas fa-dollar-sign"></i>
            <span>Baixar Boleto</span>
          </a>
        </div>
        <iframe :src="payment.boleto.base64Pdf"></iframe>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="payment.pix.showModal"
                  max-width="450"
                  :actions-close-btn="false"
                  :persistent="false"
                  :on-close="resetPaymentPix">
      <template v-slot:header>Pagar via PIX</template>
      <template v-slot:content>
        <h2 class="text-center mb-5">
          Valor: {{ payment.amount | currencyFormatter }}
        </h2>
        <div ref="pixContainer" class="text-center">
          Gerando QR Code...
        </div>
        <div class="mt-4">
          <h4>Código PIX</h4>
          <p>{{ payment.pix.qrcode }}</p>
        </div>
        <input type="text" :value="payment.pix.qrcode" ref="pixCopyCodeText"
               style="opacity: 0;position: absolute;">
        <v-btn color="blue" block class="white--text" @click="copyText('pixCopyCodeText')">
          Copiar Código PIX
        </v-btn>
      </template>
    </gripo-dialog>
  </el-card>
</template>
