<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import ValidationMessage from '../../../../components/form/ValidationMessage'
import FormContacts from '../../../../components/form/Contacts'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton, ValidationMessage, FormContacts},
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      form: {
        name: '',
        legal_name: '',
        type: 'natural',
        birth_date: '',
        cpf_cnpj: '',
        state_registration: '',
        notes: ''
      },
      dependencies: {}
    }
  },
  validations: {
    form: {
      name: {required}
    }
  },
  mounted() {
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('supplier', null, id, null, ['contacts'])
          .then(response => {
            this.form = {...response}
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      data.cpf_cnpj = this.onlyNumbers(data.cpf_cnpj)
      restfulService.save('supplier', null, null, data)
          .then(response => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.isEdit = true
            this.$router.push({name: 'supplier.update', params: {id: response.id}})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Fornecedores"/>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="el-card__save_form box-card">
          <div slot="header">
            <span>Dados do Fornecedor</span>
          </div>
          <el-form ref="form"
                   :model="form"
                   label-position="top">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Tipo de Pessoa"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.name.$error }">
                  <el-radio-group v-model="form.type" size="small">
                    <el-radio-button label="natural">Física</el-radio-button>
                    <el-radio-button label="legal">Jurídica</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item :label="form.type === 'natural' ? 'CPF' : 'CNPJ'">
                  <el-input v-model="form.cpf_cnpj"
                            v-mask="form.type === 'natural' ? '###.###.###-##' : '##.###.###/####-##'"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item :label="form.type === 'natural' ? 'Nome' : 'Nome Fantasia'"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.name.$error }">
                  <el-input v-model="form.name"
                            @input="$v.form.name.$touch()"/>
                  <validation-message v-if="$v.form.name.$error"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="form.type === 'natural' ? 'Data Nascimento' : 'Data de Abertura'">
                  <el-date-picker
                      v-model="form.birth_date"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      v-mask="'##/##/####'"
                      :placeholder="form.type === 'natural' ? 'Data Nascimento' : 'Data de Abertura'">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="form.type === 'legal'">
              <el-col :span="12">
                <el-form-item label="Razão Social">
                  <el-input v-model="form.legal_name"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Inscrição Estadual/Municipal">
                  <el-input v-model="form.legal_name"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Observações">
              <el-input type="textarea"
                        :rows="4"
                        v-model="form.notes"/>
            </el-form-item>
            <el-form-item>
              <form-save-button :event-click="onSend"
                                :is-disabled="isSubmitted || $v.$invalid"
                                :is-submitted="isSubmitted">
              </form-save-button>
              <form-cancel-button route-name="supplier.index"/>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="12" :class="{'disabled': !isEdit}">
        <form-contacts :list="form.contacts"
                       :ownerId="form.id"
                       ownerType="supplier">
        </form-contacts>
      </el-col>
    </el-row>
  </div>
</template>
