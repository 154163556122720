<script>
import * as restfulService from '../../../../../../services/restfulService'
import * as notifyService from '../../../../../../services/notifyService'

import helpers from '@mixins/helpers'

export default {
  name: 'player-impediments',
  props: {
    stageId: {
      type: Number,
      required: true
    },
    dates: {
      type: Array,
      required: true
    },
    pairId: {
      type: Number,
      required: true
    },
    playerId: {
      type: Number,
      required: true
    },
    playerName: {
      type: String,
      required: true
    }
  },
  mixins: [helpers],
  data() {
    return {
      listData: [],
      tabs: [],
      currentTabSelected: 0,
      tabsIndexManager: 0,
      dialogVisible: false,
      formLabelWidth: '120px',
      isSubmitted: false,
      dependencies: []
    }
  },
  computed: {
    colSize() {
      return this.dates.length === 3 ? 8 : 6
    }
  },
  mounted() {
    if (this.playerId) {
      this.loadImpediments()
    }
  },
  methods: {
    async loadImpediments() {
      this.isSubmitted = false
      this.clearForm()
      this.setDatesAndHours()
      this.getImpediments()
    },
    clearForm() {
      this.tabs.splice(0)
      this.currentTabSelected = '0'
      this.tabsIndexManager = 0
    },
    loopHours(start, finish) {
      let list = []
      for (let i = start; i < finish; i++) {
        list.push({
          hour: ('00' + i).slice(-2) + ':00:00',
          hourFormat: ('00' + i).slice(-2) + ':00'
        })
        list.push({
          hour: ('00' + i).slice(-2) + ':30:00',
          hourFormat: ('00' + i).slice(-2) + ':30'
        })
      }
      return list
    },
    initDates(element) {
      const tab = {
        date: element.date,
        periods: [],
        tabIndex: this.tabsIndexManager.toString()
      }
      tab.periods.push(this.initPeriod('Manha'))
      tab.periods[0].hours = this.loopHours(7, 12)
      tab.periods.push(this.initPeriod('Tarde'))
      tab.periods[1].hours = this.loopHours(12, 18)
      tab.periods.push(this.initPeriod('Noite'))
      tab.periods[2].hours = this.loopHours(18, 24)
      this.tabsIndexManager++
      return tab
    },
    initPeriod(label) {
      return {
        hours: [],
        label: label,
        checkAll: false,
        isIndeterminate: false,
        impedimentHours: []
      }
    },
    save() {
      this.isSubmitted = true
      let data = {
        stage_id: this.stageId,
        pair_id: this.pairId,
        player_id: this.playerId,
        impediments: []
      }
      this.tabs.forEach((tab) => {
        tab.periods.forEach((period) => {
          period.impedimentHours.forEach(hour => {
            data.impediments.push({
              date: tab.date,
              hour: hour
            })
          })
        })
      })
      restfulService.post('stage_player_impediment', 'store', null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    },
    getImpediments() {
      let data = {
        stage_id: this.stageId,
        player_id: this.playerId
      }
      restfulService.search('stage_player_impediment', null, data, ['*'], null, 1, 100)
          .then(response1 => {
            let data = response1.data
            this.tabs.forEach(tab => {
              if (data.some(element => tab.date === element.date)) {
                tab.periods.forEach(period => {
                  let hours = []
                  period.hours.forEach(element => {
                    hours.push(element.hour)
                  })
                  let filteredData = data.filter(impediment => {
                    return hours.indexOf(impediment.hour) > -1 &&
                        impediment.date === tab.date
                  })
                  filteredData.forEach(element => {
                    period.impedimentHours.push(element.hour)
                    this.checkHour(true, period)
                  })
                })
              }
            })
          })
          .catch(e => {
            this.isLoadingData = false
            console.log(e)
          })
    },
    checkAll(val, period) {
      period.impedimentHours = []
      if (val) {
        period.hours.forEach((element) => {
          period.impedimentHours.push(element.hour)
        })
      }
      period.isIndeterminate = false
    },
    checkHour(val, period) {
      period.checkAll = !val ? val : period.checkAll
      if (period.impedimentHours.length === period.hours.length) {
        period.checkAll = true
        period.isIndeterminate = false
      }
      period.isIndeterminate = period.impedimentHours.length < period.hours.length &&
          period.impedimentHours.length > 0
    },
    setDatesAndHours() {
      this.dates.forEach((element) => {
        this.tabs.push(this.initDates(element))
      })
    }
  }
}

</script>

<template>
  <div>
    <el-row :gutter="10" justify="space-around">
      <el-col v-for="(item, index) in tabs" :sm="colSize" :xs="24" :key="index">
        <el-card class="box-card impediments-card">
          <div slot="header">{{ item.date | dateEnToBr }}</div>
          <el-row :gutter="10" justify="space-around">
            <el-col v-for="(period, periodIndex) in item.periods" :span="8" :key="periodIndex">
              <div>
                <el-checkbox class="select_all_impediments"
                             :indeterminate="period.isIndeterminate"
                             v-model="period.checkAll"
                             @change="checkAll(period.checkAll, period)">
                  <span>{{ period.label }}</span>
                </el-checkbox>
              </div>
              <el-checkbox-group v-model="period.impedimentHours" size="small">
                <div v-for="hour in period.hours" class="checkbox-center">
                  <el-checkbox-button :label="hour.hour"
                                      :key="hour.hour"
                                      @change="checkHour($event, period )"
                  >{{ hour.hourFormat }}
                  </el-checkbox-button>
                  <br/>
                </div>
              </el-checkbox-group>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <div class="mt-3 text-center">
      <el-button type="primary"
                 @click="save()"
                 :disabled="isSubmitted"
                 :loading="isSubmitted">
        {{ isSubmitted ? 'Salvando' : 'Salvar' }} Impedimentos de {{ playerName }}
      </el-button>
    </div>
  </div>
</template>
