<script>
export default {
  name: 'client-plan-status-label',
  props: {
    status: {default: '', type: String},
    showStatusText: {default: false, type: Boolean},
  },
  methods: {
    text(label) {
      return (this.showStatusText ? 'Status: ' : '') + label
    }
  }
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag size="small" class="el-tag--status" type="success" v-if="status === 'active'">
      {{ text('Ativo') }}
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="warning" v-if="status === 'expired'">
      {{ text('Expirado') }}
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="danger" v-if="status === 'canceled'">
      {{ text('Cancelado') }}
    </el-tag>
  </div>
</template>
