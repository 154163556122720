<script>
import Courts from './manage/Courts'
import Pairs from './manage/pairs/Pairs'
import Groups from './manage/groups/Groups'
import Matches from './manage/matchs/Matches'
import PlayersClass from './manage/player_class/PlayersClass'
import Registrations from './manage/Registrations'
import StageStatus from './StageStatus'
import GripoDialog from '../../../../components/common/Dialog'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'
import {confirmDialog} from '@utils/flash'
import {cloneDeep} from 'lodash'

export default {
  components: {
    Registrations,
    Courts,
    Pairs,
    Groups,
    Matches,
    PlayersClass,
    StageStatus,
    GripoDialog
  },
  data() {
    return {
      isLoadingData: true,
      webSocketStarted: false,
      configDialogVisible: false,
      form: {
        stage: {
          id: '',
          name_with_circuit: '',
          pairs: [],
          players_class: [],
          city: {
            name: ''
          },
          settings: {
            space_matches_between_matches: 2,
            sort_matches: 'common',
            groups_config: {
              four_pairs_eliminated_first_or_last: true,
              show_pair_registration_ranking: false
            },
            max_pair_per_group: 4,
            bracket_system: 'common',
          }
        }
      },
      stageId: null,
      dependencies: {
        cities: [],
        circuits: []
      }
    }
  },
  computed: {
    ...mapGetters(['roles', 'tenant']),
    hasStage: function () {
      return !!this.form.stage.id
    },
    stageFullName: function () {
      if (this.stageId) {
        return 'Torneio: ' + this.form.stage.name_with_circuit + ' / ' + this.form.stage.city.name
      }
      return ''
    },
    websocketKeyStageUpdate() {
      return 'stageUpdate'
    },
    websocketKeyStageUpdateName() {
      return `${this.websocketKeyStageUpdate}.${this.tenant.id}.${this.stageId}`
    },
    websocketKeyStageUpdateListen() {
      return `.${this.websocketKeyStageUpdate}`
    },
    showDiscardButton() {
      return this.form.stage.id &&
          ['active', 'inactive'].includes(this.form.stage.status)
    },
    hasPayment() {
      return this.form.stage.settings?.registration_with_payment || false
    },
    hasPlayersClassWithoutAmounts() {
      return this.hasPayment
          && this.form.stage.registration_opened
          && this.form.stage.players_class.some(item => item.amounts_not_configured)
    },
    maxPairPerGroup() {
      return parseInt(this.form.stage.settings.max_pair_per_group)
    },
  },
  watch: {
    'form.stage.settings.bracket_system': function (value) {
      if (value === 'cobrapa') {
        this.form.stage.settings.max_pair_per_group = 3
      }
    }
  },
  mounted() {
    this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      this.getStage(id)
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'city'}, {domain: 'circuit'}]).then((result) => {
        this.dependencies = {...result}
      })
    },
    getStage(stageId) {
      this.isEdit = true
      restfulService.get('manage/stage', null, stageId)
          .then(response => {
            this.stageId = response.stage.id
            if (!this.webSocketStarted) {
              this.startWebsocket()
            }
            if (response.stage.settings?.groups_config === null) {
              response.stage.settings.groups_config = {...cloneDeep(this.form.stage.settings.groups_config)}
            } else {
              response.stage.settings.groups_config = {...cloneDeep(this.form.stage.settings.groups_config), ...response.stage.settings.groups_config}
            }
            this.form = {...response}
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    },
    copyText(ref) {
      const codeToCopy = this.$refs[ref]
      navigator.clipboard.writeText(codeToCopy.value)
      this.$notify({
        title: 'Link copiado!',
        type: 'success',
        duration: 1000,
        position: 'bottom-right'
      })
    },
    startWebsocket() {
      this.webSocketStarted = true
      window.Echo.channel(this.websocketKeyStageUpdateName)
          .listen(this.websocketKeyStageUpdateListen, () => {
            this.getStage(this.stageId)
          })
    },
    async discardStage() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente descartar o torneio?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('stage', 'discard', this.form.stage.id)
            .then((response) => {
              if (response.message) {
                notifyService.success({message: 'Torneio descartado com sucesso.'})
                this.$router.push({name: 'stage.index'})
              }
            })
            .catch((e) => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    onSaveConfig() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {...this.form.stage}
      restfulService.put('manage/stage', 'save-config', data.id, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.configDialogVisible = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <el-card class="stage-card-box el-card__search_list box-card">
      <div slot="header" class="stage-card-box-header">
        <div class="flex-c">
          <stage-status class="mr-2" :status="form.stage.status"/>
          <div class="stage-title">
            {{ stageFullName }}
          </div>
          <div class="buttons">
            <el-button v-if="showDiscardButton" type="danger" size="small" @click="discardStage()">
              <i class="fas fa-trash"></i>
              <span>Descartar</span>
            </el-button>
            <router-link :to="{ name: 'stage.update', params: { id: stageId } }"
                         v-if="stageId"
                         class="el-button el-button--info el-button--small"
                         title="Editar Dados">
              <i class="fas fa-pencil-alt"></i>
              <span>Editar</span>
            </router-link>
            <el-button type="default" size="small" @click="configDialogVisible = true">
              <i class="fas fa-cog"></i>
            </el-button>
          </div>
        </div>
      </div>
      <v-alert v-if="form.stage.has_stage_without_payment" class="empty-content-alert mb-3"
               :value="true"
               type="error"
               outline>
        <slot name="content">
          <strong>Esse torneio não pode ser publicado!</strong>
          <div>Existem torneios anteriores com pagamento pendente, clique no botão abaixo para ir para na área
            pagamentos.
          </div>
          <router-link :to="{ name: 'tenant_settings.show', params: {tab: 'payment'} }"
                       class="el-button el-button--small el-button--success mt-2">
            Meu Clube -> Financeiro
          </router-link>
        </slot>
      </v-alert>
      <v-alert v-if="form.stage.id && (form.stage.players_class.length === 0 || hasPlayersClassWithoutAmounts)"
               class="empty-content-alert mb-3"
               :value="true"
               type="warning"
               outline>
        <slot name="content">
          <div v-if="form.stage.players_class.length === 0">
            <strong>Não esqueça de configurar as categorias do torneio no Gerenciamento de Categorias</strong>
          </div>
          <div v-if="hasPlayersClassWithoutAmounts">
            <strong>Existem categorias que não possuem os valores de inscrição configurados</strong>
          </div>
        </slot>
      </v-alert>
      <v-alert v-if="form.stage.external_circuit_reference" class="empty-content-alert mb-3"
               :value="true"
               type="info"
               outline>
        <slot name="content">Torneio vinculada ao circuito externo:
          <strong>{{ form.stage.external_circuit_reference_name }}</strong>
          ({{ form.stage.external_circuit_reference }})
        </slot>
      </v-alert>
      <div class="stage-cards">
        <PlayersClass v-if="stageId"
                      :stage-id="stageId"/>
        <Courts v-if="stageId"
                :stage-id="stageId"/>
        <Registrations v-if="stageId"
                       :stage-id="stageId"/>
        <Pairs v-if="stageId"
               :stage="form.stage"
               :stage-id="stageId"
               :total="form.stage.pairs.total"
               :total-two-player-class="form.stage.pairs_two_players_class.total"
               :total-pairs-with-parceiro="form.stage.pair_with_parceiro.total"/>
        <Groups v-if="stageId"
                :stage="form.stage"
                :stage-id="stageId"
                :groups="form.stage.groups.total"
                :matches="form.stage.matches.total"
                :max-pair-per-group="maxPairPerGroup"/>
        <Matches v-if="stageId"
                 :stage="form.stage"
                 :stage-id="stageId"
                 :matches="form.stage.matches.total"/>
      </div>
    </el-card>
    <el-card v-if="stageId" class="stage-card-box el-card__search_list box-card mb-4">
      <div class="copy-url" title="Copiar Link" @click="copyText('registrationUrl')">
        <strong>Inscrição:</strong>
        <input type="text" ref="registrationUrl" :value="form.stage.registration_url">
        <span class="icon">
            <i class="far fa-copy"></i>
        </span>
        <span>{{ form.stage.registration_url }}</span>
      </div>
      <div class="copy-url" title="Copiar Link" @click="copyText('registrationsUrl')">
        <strong>Inscritos:</strong>
        <input type="text" ref="registrationsUrl" :value="form.stage.registrations_url">
        <span class="icon">
            <i class="far fa-copy"></i>
        </span>
        <span>{{ form.stage.registrations_url }}</span>
      </div>
      <div class="copy-url" title="Copiar Link" @click="copyText('groupsUrl')">
        <strong>Chaves:</strong>
        <input type="text" ref="groupsUrl" :value="form.stage.groups_url">
        <span class="icon">
            <i class="far fa-copy"></i>
        </span>
        <span>{{ form.stage.groups_url }}</span>
      </div>
      <div class="copy-url" title="Copiar Link" @click="copyText('matchesUrl')">
        <strong>Jogos:</strong>
        <input type="text" ref="matchesUrl" :value="form.stage.matches_url">
        <span class="icon">
            <i class="far fa-copy"></i>
        </span>
        <span>{{ form.stage.matches_url }}</span>
      </div>
    </el-card>
    <gripo-dialog :model.sync="configDialogVisible"
                  width="85vw"
                  top="3vh">
      <template v-slot:header>Configurações Gerais</template>
      <template v-slot:content>
        <el-form label-position="top">
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="Sistema de Chaveamento">
                <div class="mt-2">
                  <el-radio-group v-model="form.stage.settings.bracket_system">
                    <el-radio-button label="common">Padrão</el-radio-button>
                    <el-radio-button label="cobrapa">Cobrapa</el-radio-button>
                    <el-radio-button label="super16">Super16</el-radio-button>
                  </el-radio-group>
                </div>
                <div class="el-form-item-help mt-2">
                  <div>Padrão: <a
                      href="https://drive.google.com/drive/folders/1s5ShPfDwU2DDy7FS1KK8ZReG81SKdVAf?usp=sharing"
                      target="_blank">Visualizar Estrutura do Chaveamento</a></div>
                  <div>Cobrapa: <a
                      href="https://docs.google.com/spreadsheets/d/e/2PACX-1vR_Sv8iN3Eo7qe-tsVVyEAnvkq_wJCaCs3PbULIqDI6xB7A2bqM4J6C2oGoS3BPfI44Dloo4Q0YTWCI/pubhtml"
                      target="_blank">Visualizar Estrutura do Chaveamento</a></div>
                  <div>Super16: Em breve</div>
                </div>
              </el-form-item>
              <el-form-item label="Exibir Ranking na Lista de Inscritos do App">
                <el-switch v-model="form.stage.settings.groups_config.show_pair_registration_ranking"
                           active-text="Sim"
                           :active-value="true"
                           inactive-text="Não"
                           :inactive-value="false">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Nº Máximo de Duplas nas Chaves">
                <el-radio-group v-model="form.stage.settings.max_pair_per_group"
                                :disabled="form.stage.settings.bracket_system !== 'common'">
                  <el-radio-button :label="3">3</el-radio-button>
                  <el-radio-button :label="4">4</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Formato do Sorteio de Jogos">
                <el-radio-group v-model="form.stage.settings.sort_matches">
                  <el-radio-button label="common">Padrão</el-radio-button>
                  <el-radio-button label="group">Por Grupos</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <div class="el-form-item-description" v-if="form.stage.settings.sort_matches === 'common'">
                Sorteio de forma aleatória somente priorizando atletas com mais restrições (impediments/duas categorias)
              </div>
              <div class="el-form-item-description" v-if="form.stage.settings.sort_matches === 'group'">
                Sorteio feito em ordem pelos grupos (A, B, C, ...)
              </div>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Intervalo Padrão Entre os Jogos">
                <div class="el-form-item-description">
                  Ao gerar os jogos o sistema irá usar essa configuração para
                  definir o intervalo mínimo entre os jogos dos atletas
                </div>
                <el-input type="number"
                          :min="0"
                          :step="1"
                          v-model="form.stage.settings.space_matches_between_matches"/>
              </el-form-item>
              <el-form-item label="Ignorar dupla em chaves com 4 duplas">
                <div class="el-form-item-description">
                  Caso uma dupla perca ou ganhe todas partidas, os jogos contra essa dupla são ignorados da
                  classificação final entre as outras 3 duplas
                </div>
                <el-switch v-model="form.stage.settings.groups_config.four_pairs_eliminated_first_or_last"
                           active-text="Sim"
                           :active-value="true"
                           inactive-text="Não"
                           :inactive-value="false">
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="success" @click="onSaveConfig()">
          <i class="fas fa-check"></i>
          <span>Salvar</span>
        </el-button>
      </template>
    </gripo-dialog>
  </div>
</template>

<style lang="scss">

.stage-card-box {
  margin-top: 25px;

  > .el-card__body {
    padding: 20px 20px 0 20px !important;
  }

  .stage-card-box-header {
    > .flex-c {
      gap: 10px;

      .stage-title {
        line-height: 24px;
      }

      .buttons {
        margin-left: auto;
        text-align: right;
        flex: 1 1 400px;

        .el-button {
          margin: 0 5px !important;
        }
      }
    }
  }
}

.stage-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .stage-card {
    border-radius: 14px;
    width: 32%;
    margin-bottom: 20px;

    .card-content {
      position: relative;
      overflow: hidden;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .card-icon {
        opacity: .2;
        color: #FFF;
        font-size: 160px;
        position: absolute;
        z-index: 8;
        right: -20px;
        top: -20px;
        transform: rotate(20deg);
      }

      .card-information {
        padding: 30px 20px;
        position: relative;
        z-index: 10;
        min-height: 150px;
        width: 100%;

        .card-title {
          color: #FFF;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 18px;
          padding-bottom: 6px;
          text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
          text-align: center;
        }

        .card-subtitle {
          color: #FFF;
          text-transform: uppercase;
          font-size: 13px;
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
          text-align: center;
        }

        .card-buttons {
          margin-top: 25px;

          .box {
            display: flex;
            gap: 6px;
            flex-wrap: wrap;
            justify-content: center;

            .full {
              width: 100%;

              .el-button {
                width: 100%;
                display: block;
              }
            }

            .col {
              width: calc(50% - 3px);

              .el-button {
                width: 100%;
                display: block;
              }

              .el-dropdown {
                width: 100%;
                display: block;

                .el-button {
                  width: 100%;
                  display: block;
                }
              }
            }
          }

          .el-button {
            margin: 0;
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    &.card-players-class {
      background: #a18bff;
      background: linear-gradient(to left, #8076fe 0%, #a18bff 100%);
    }

    &.card-courts {
      background: #7EC8F4;
      background: linear-gradient(to left, #4B91E6 0%, #7EC8F4 100%);
    }

    &.card-dates {
      background: #b1957f;
      background: linear-gradient(to left, #856957 0%, #b1957f 100%);
    }

    &.card-impediments {
      background: #b1957f;
      background: linear-gradient(to left, #856957 0%, #b1957f 100%);
    }

    &.card-pairs {
      background: #FDA489;
      background: linear-gradient(to left, #FE8D6C 0%, #FDA489 100%);
    }

    &.card-groups {
      background: rgb(209, 137, 142);
      background: linear-gradient(to left, rgb(170, 111, 115) 0%, rgb(209, 137, 142) 100%);
    }

    &.card-matches {
      background: #8eda81;
      background: linear-gradient(to left, #6aa15e 0%, #8eda81 100%);
    }

    &.card-pdf {
      background: #98d6da;
      background: linear-gradient(to left, #6fb7da 0%, #98d6da 100%);
    }
  }
}

@media all and (max-width: 768px) {
  .stage-card-box {
    .stage-card-box-header {
      flex-direction: column;

      .stage-title {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
  .stage-cards {
    .stage-card {
      width: 49%;
    }
  }
}

@media all and (max-width: 540px) {
  .stage-card-box {
    margin-top: 10px;

    .stage-card-box-header {
      > .flex-c {
        .buttons {
          display: inline-flex;
          flex-direction: column;
          gap: 3px;
          flex: 1 1 80px;
          text-align: center;

          .el-button {
            padding: 6px !important;
          }
        }
      }
    }
  }
  .stage-cards {
    .stage-card {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }
}
</style>
