<script>
import FormSaveButton from '@components/form/SaveButton'
import ValidationMessage from '@components/form/ValidationMessage'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import {required} from 'vuelidate/lib/validators'

import {cloneDeep} from 'lodash'

export default {
  components: {FormSaveButton, ValidationMessage},
  name: 'tenant-config-certificate',
  props: ['certificate'],
  data() {
    return {
      isSubmitted: false,
      showPassword: false,
      localCertificate: {
        exists: false,
        created_at: '',
        expire_datetime: '',
        expired: false,
        file: '',
        base64: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    hasBasicPlan() {
      return (this.tenant?.modules?.basic ?? false)
    },
    hasProfessionalPlan() {
      return (this.tenant?.modules?.professional ?? false)
    },
    hasExpiredCertificate() {
      return this.localCertificate
          && (this.localCertificate.status === 'expired' || this.localCertificate.expired === true)
    },
    hasActiveCertificate() {
      return this.localCertificate
          && this.localCertificate.status === 'active'
          && !this.localCertificate.expired
    },
  },
  validations: {
    localCertificate: {
      base64: {required},
      password: {required},
    }
  },
  watch: {
    certificate: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.localCertificate = {...this.localCertificate, ...cloneDeep(value)}
      }
    }
  },
  methods: {
    onSelectFile(e) {
      const selectedImage = e.target.files[0]
      if (selectedImage) {
        if (selectedImage.type !== 'application/x-pkcs12') {
          this.$notify.error({
            title: 'Formato de arquivo inválido',
            message: 'Selecione o arquivo do certificado com extensão .pfx ou .p12',
            offset: 150
          })
          return false
        }
        this.localCertificate.base64 = selectedImage.name
        this.onLoadFile(selectedImage)
      } else {
        this.localCertificate.base64 = ''
      }
    },
    onLoadFile(fileObject) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.localCertificate.base64 = e.target.result
      }
      reader.readAsDataURL(fileObject)
    },
    onSaveCertificate() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const {base64, password} = this.localCertificate
      restfulService.post('tenant', 'save-certificate', null, {base64, password})
          .then(() => {
            notifyService.success()
            this.$v.$reset()
            bus.$emit('hide-loader')
            this.isSubmitted = false
            this.$emit('reloadData')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
  }
}
</script>

<template>
  <div class="pt-3 pr-3 pb-3 pl-3">
    <h3>
      <span>Certificado Digital - A1</span>
      <el-tag type="success" size="mini" effect="dark" class="ml-2" v-if="hasActiveCertificate">
        Ativo
      </el-tag>
      <el-tag type="danger" size="mini" effect="dark" class="ml-2"
              v-if="!hasActiveCertificate && hasExpiredCertificate">
        Certificado Expirado
      </el-tag>
    </h3>
    <template v-if="!hasActiveCertificate">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Selecione o novo Certificado"
                        class="is-required"
                        :class="{ 'el-form-item--error': $v.localCertificate.base64.$error }">
            <input @change="onSelectFile"
                   class="custom-input"
                   type="file"
                   accept=".pfx"
                   @input="$v.localCertificate.base64.$touch()">
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Senha"
                        class="is-required"
                        :class="{ 'el-form-item--error': $v.localCertificate.password.$error }">
            <el-input v-model="localCertificate.password"
                      @input="$v.localCertificate.password.$touch()"
                      :type="showPassword ? 'text' : 'password'">
              <div class="el-input--suffix-box" slot="suffix" @click="showPassword = !showPassword">
                <span v-if="showPassword" key="passwordVisibility"><i class="fas fa-eye"></i></span>
                <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
              </div>
            </el-input>
            <validation-message v-if="!$v.localCertificate.password.$error"/>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="text-center mb-4">
        <form-save-button :event-click="onSaveCertificate"
                          size="small"
                          text="Enviar Novo Certificado"
                          submitting-text="Enviando Certificado"
                          :is-disabled="isSubmitted || $v.localCertificate.$invalid"
                          :is-submitted="isSubmitted"/>
      </div>
    </template>
    <div v-if="hasActiveCertificate || hasExpiredCertificate" class="text-center pb-2">
      Enviado em: <strong>{{ localCertificate.created_at | dateTimeEnToBr }}h</strong>
      <div class="mt-2">
        <el-tag type="danger" size="mini" class="mr-2">{{ hasExpiredCertificate ? 'Expirado' : 'Expira' }}
          em:
        </el-tag>
        <strong>{{ localCertificate.expire_datetime | dateTimeEnToBr }}h</strong>
      </div>
    </div>
    <el-alert type="error"
              class="mt-2 mb-2"
              v-if="hasExpiredCertificate"
              show-icon
              :closable="false">
      <div slot="title" class="fs-16">
        Seu certificado está expirado e a emissão de notas fiscais está pausada.<br>
        Envie um novo certificado para ativar novamente a emissão das notas fiscais.
      </div>
    </el-alert>
  </div>
</template>
